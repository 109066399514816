import React from 'react';
import * as SharedStyle from '../../shared-style';
import styled from 'styled-components';
import {
  TEXT_COLOR_NEUTRAL_3,
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR
} from '../../constants';

const StyledSelect = styled.select`
  display: block;
  width: 120px;
  float: right;
  padding: 15px 10px 12px 0px;
  color: ${TEXT_COLOR_NEUTRAL_3};
  border: 2px solid;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  text-align: right;
  outline: none;
  border-radius: 5px;
  :hover {
    border-color: ${SECONDARY_PURPLE_COLOR};
  }
  :focus {
    border-color: ${SECONDARY_PURPLE_COLOR};
  }
`;

export default function FormSelect({ children, style, ...rest }) {
  return (
    <StyledSelect type="text" style={style} {...rest}>
      {children}
    </StyledSelect>
  );
}
