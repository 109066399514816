import * as Three from 'three';
String.prototype.isLeftPlaceholder = function () {
  return this.match(/_L$|_L_\d$/) != null;
};

String.prototype.isRightPlaceholder = function () {
  return this.match(/_R$|_R_\d$/) != null;
};

export function objectsMap(object, func) {
  let mappedObject = {};
  for (let key in object) {
    mappedObject[key] = func(key, mappedObject[key]);
  }
  return mappedObject;
}

export function objectsCompare(x, y) {
  if (x === y) return true;
  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  if (x.constructor !== y.constructor) return false;

  for (let p in x) {
    if (!x.hasOwnProperty(p)) continue;
    if (!y.hasOwnProperty(p)) return false;
    if (x[p] === y[p]) continue;
    if (typeof x[p] !== 'object') return false;
    if (!objectsCompare(x[p], y[p])) return false;
  }

  for (let p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  }

  return true;
}
export function getAllMeshes(objects) {
  const meshes = [];
  objects.forEach(object => {
    if (!object) return;
    object.traverse(o => {
      if (o.isMesh && o.geometry) {
        meshes.push(o);
      }
    });
  });
  return meshes;
}

export function vectorIntersectWithMesh(origin, target) {
  const vectorDir = new Three.Vector3(origin.x, origin.y - 1000, origin.z);
  const ray = new Three.Raycaster();
  ray.set(origin, vectorDir);
  const intersects = ray.intersectObject(target, true);
  return intersects.length > 0;
}
