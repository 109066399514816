import React from 'react';
import DownloadSummaryTemp from '../downloadSummaryTemp';
import * as constants from '../../../../../constants';
import Viewer2DDownLoad from './viewer2DDownLoad';

const Show2DView = ({ props }) => {
  return (
    <DownloadSummaryTemp>
      <div style={{ marginBottom: '32px' }}>
        <div
          style={{
            marginTop: '20px',
            color: '#000F33',
            fontSize: '27px',
            fontFamily: 'Open Sans',
            fontWeight: '700',
            lineHeight: '45.9px',
            wordWrap: 'break-word'
          }}
        >
          {constants.TWO_D_FLOOR_PLAN}
        </div>
        <div
          style={{
            marginTop: 7,
            color: '#434D63',
            fontSize: '12px',
            fontFamily: 'Open Sans',
            fontWeight: '600',
            lineHeight: '15px',
            wordWrap: 'break-word'
          }}
        >
          {constants.TECHNICAL_VIEW}
        </div>
      </div>
      <div style={{ height: 412 }}>
        <div style={{ transform: `scale(0.5, 0.5)`, width: 6, height: 2 }}>
          <Viewer2DDownLoad
            state={props.state}
            catalog={props.catalog}
            width={682}
            height={560}
          />
        </div>
      </div>
    </DownloadSummaryTemp>
  );
};

export default Show2DView;
