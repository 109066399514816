import React from 'react';

// import { FaSearch, FaPlus } from 'react-icons/fa';

// import * as S from '../styles';
import { FloorCategory } from './floor-category';
import { FloorChooseProduct } from './choose-floor';

import { DoubleToolbarButtonWithPopup } from '../';

const FloorplanToolbarButton = ({
  props,
  action,
  drawAction,
  dataSource,
  subData,
  trigger,
  categoryCollapse,
  productCollapse,
  collapseAction,
  mode,
  closeAction,
  isOpen
}) => {
  let content = (
    <FloorCategory
      props={props}
      action={action}
      dataSource={dataSource.categories}
    ></FloorCategory>
  );
  let content1 = (
    <FloorChooseProduct
      drawAction={drawAction}
      closeAction={closeAction}
      props={props}
      dataSource={subData}
      isExpand={!productCollapse}
      mode={mode}
    ></FloorChooseProduct>
  );
  return (
    <DoubleToolbarButtonWithPopup
      closeAction={closeAction}
      mode={mode}
      order={0}
      isDouble={subData.length || subData === -1 || subData === -2}
      collapse1={categoryCollapse}
      collapse2={productCollapse}
      collapseAction={collapseAction}
      trigger={trigger}
      children1={content1}
      isOpen={isOpen}
    >
      {content}
    </DoubleToolbarButtonWithPopup>
  );
};

export default FloorplanToolbarButton;
