import React, { useState } from 'react';
import ToolbarPanel from '../../../../sidebar/toolbar-panel';
import * as S from '../../styles';

import DoorStyle from '../../product/doorstyle';
import PrimaryDoorStyle from '../../product/primary';
export const StyleCategory = ({
  step,
  action,
  dataSource,
  stepSource,
  isExpand,
  currentTexture,
  recentStyle,
  selectFromRecent
}) => {
  const [selOption, setSelOption] = useState(-1);
  const [selSubOption, setSelSbuOption] = useState(-1);

  let miHeight = 0;
  let nonHeight = 495 - miHeight + 'px';
  let height = window.innerHeight * 0.6 - miHeight + 'px';
  var result = <div></div>;

  if (step === 0) {
    // const recentDiv = [];
    // recentStyle.map((el, key) => {
    //   recentDiv.push(
    //     <DoorStyle
    //       key={key}
    //       url={el.textureData.thumbnail}
    //       name={el.textureData.name}
    //       onClick={() => {selectFromRecent(key)}}
    //     >
    //     </DoorStyle>
    //     );
    // })
    // // const categoryDiv = [];
    // stepSource.forEach((el, key) => {
    //   categoryDiv.push(
    //     <ToolbarPanel
    //       onClose={() => {action(key)}}
    //       onOpen={() => {action(key)}}
    //       isUrl={true}
    //       key={key.toString()}
    //       name={el.name}
    //       url={el.thumbnail}>
    //   </ToolbarPanel>
    //   );
    // })
    result = (
      // <ToolbarPanel
      //   opened
      //   onClose={() => {}}
      //   onOpen={() => {}}
      //   isUrl={false}
      //   name={"Primary Door Style"}
      //   url={null}>
      <PrimaryDoorStyle
        url={currentTexture !== null ? currentTexture.thumbnail : null}
        title={currentTexture !== null ? currentTexture.name : null}
        onClick={() => {
          action(-1);
        }}
      />
      // </ToolbarPanel>
    );
    {
      /* {step === 1
      ? (
        <div>
          {categoryDiv}
        </div>
        )
      : ( <div></div>)} */
    }
    {
      /* <ToolbarPanel
        opened
        onClose={() => {}}
        onOpen={() => {}}
        isUrl={false}
        name={"Recently Used"}
        url={null}>
        <div style={{  width: 200, padding: 5, display:'flex', width: '100%',overflowX: "auto"} } >
          {recentDiv}
        </div>
      </ToolbarPanel> */
    }
  } else {
    result = [];
    stepSource.forEach((el, key) => {
      const toolbarChildren = [];
      el.items.forEach((element, idx) => {
        toolbarChildren.push(
          <DoorStyle
            key={idx}
            idx={idx}
            subOptionIdx={key}
            selSubOption={selSubOption}
            url={element.thumbnail}
            name={element.name}
            selOption={selOption}
            onClick={() => {
              action(el.name + '/' + element.name);
              setSelOption(idx);
              setSelSbuOption(key);
            }}
          ></DoorStyle>
        );
      });
      result.push(
        <S.ItemWrapper key={key.toString()}>
          <ToolbarPanel
            // onClose={() => {action(el.name)}}
            // onOpen={() => {action(el.name)}}
            onClose={() => {}}
            onOpen={() => {}}
            isUrl={true}
            key={key.toString()}
            idx={key}
            selSubOption={selSubOption}
            name={el.name}
            url={el.thumbnail}
          >
            {toolbarChildren}
          </ToolbarPanel>
        </S.ItemWrapper>
      );
    });
  }
  return (
    <S.CategoryContentWrapper
      style={{ height: isExpand ? height : nonHeight, maxWidth: 185 }}
    >
      {result}
    </S.CategoryContentWrapper>
  );
};
