import React, { useState } from 'react';

import * as S from './styles';
import * as MS from '../styles';

export default function DoorStyleProduct({
  url,
  onClick,
  name,
  doorId,
  isExpand,
  selected,
  currentTexture
}) {
  name = name.charAt(0).toUpperCase() + name.substr(1);

  return (
    <S.PrimayDoorStyleLineWrapper style={{ padding: '5px 15px 5px' }}>
      <S.Control onClick={onClick}>
        <div
          style={{
            width: '100%',
            display: 'grid',
            justifyItems: isExpand === 1 ? 'center' : 'left'
          }}
        >
          <S.LineImage>
            <img width={isExpand === 1 ? 196 : 85} name={name} src={url} />
          </S.LineImage>
          <h4 style={{ marginTop: '4px', marginBottom: '4px' }}>
            <span style={{ fontSize: isExpand === 1 ? 18 : 10 }}>{name}</span>
          </h4>
          <S.ControlIcon key={name}>
            <img
              width={isExpand === 1 ? 100 : 72}
              name={name}
              src={'/assets/img/svg/toolbar/apply_button.svg'}
            />
            <MS.ButtonTitle style={{ top: '37%', fontSize: 10.5 }}>
              {selected(doorId) ? 'APPLIED' : 'USE'}
            </MS.ButtonTitle>
          </S.ControlIcon>
        </div>
      </S.Control>
    </S.PrimayDoorStyleLineWrapper>
  );
}
