import { Hole } from '../class/export';
import { history } from '../utils/export';
import {
  SELECT_TOOL_DRAWING_HOLE,
  SELECT_TOOL_DRAWING_HOLE_3D,
  UPDATE_DRAWING_HOLE,
  UPDATE_DRAWING_HOLE_3D,
  END_DRAWING_HOLE,
  END_DRAWING_HOLE_3D,
  BEGIN_DRAGGING_HOLE,
  BEGIN_DRAGGING_HOLE_3D,
  UPDATE_DRAGGING_HOLE,
  END_DRAGGING_HOLE,
  END_DRAGGING_HOLE_3D,
  SELECT_HOLE,
  END_CREATING_HOLE,
  UPDATE_POPUP_OPEN,
  UPDATE_DRAGGING_HOLE_CHANGED,
  UPDATE_DRAGGING_HOLE_RULER_CHANGED
} from '../constants';

export default function (state, action) {
  switch (action.type) {
    case UPDATE_DRAGGING_HOLE_RULER_CHANGED:
      return Hole.updateDraggingHoleRulerChanged(
        state,
        action.width,
        action.layerID,
        action.holeID
      ).updatedState;

    case UPDATE_DRAGGING_HOLE_CHANGED:
      return Hole.updateDraggingHoleChanged(
        state,
        action.x,
        action.y,
        action.layerID,
        action.holeID
      ).updatedState;

    case END_CREATING_HOLE:
      return Hole.endCreatingHole(state).updatedState;

    case UPDATE_POPUP_OPEN:
      return Hole.updatePopupOpen(state, action.value).updatedState;

    case SELECT_TOOL_DRAWING_HOLE:
      state = state.merge({
        sceneHistory: history.historyPush(state.sceneHistory, state.scene)
      });
      return Hole.selectToolDrawingHole(state, action.sceneComponentType)
        .updatedState;

    case SELECT_TOOL_DRAWING_HOLE_3D:
      return Hole.selectToolDrawingHole3D(state, action.sceneComponentType)
        .updatedState;

    case UPDATE_DRAWING_HOLE:
      return Hole.updateDrawingHole(state, action.layerID, action.x, action.y)
        .updatedState;

    case UPDATE_DRAWING_HOLE_3D:
      return Hole.updateDrawingHole3D(state, action.layerID, action.x, action.y)
        .updatedState;

    case END_DRAWING_HOLE:
      state = state.merge({
        sceneHistory: history.historyPush(state.sceneHistory, state.scene)
      });
      return Hole.endDrawingHole(state, action.layerID, action.x, action.y)
        .updatedState;

    case END_DRAWING_HOLE_3D:
      state = state.merge({
        sceneHistory: history.historyPush(state.sceneHistory, state.scene)
      });
      return Hole.endDrawingHole3D(state, action.layerID, action.x, action.y)
        .updatedState;

    case BEGIN_DRAGGING_HOLE:
      state = state.merge({
        sceneHistory: history.historyPush(state.sceneHistory, state.scene)
      });
      return Hole.beginDraggingHole(
        state,
        action.layerID,
        action.holeID,
        action.x,
        action.y
      ).updatedState;

    case BEGIN_DRAGGING_HOLE_3D:
      state = state.merge({
        sceneHistory: history.historyPush(state.sceneHistory, state.scene)
      });
      return Hole.beginDraggingHole3D(
        state,
        action.layerID,
        action.holeID,
        action.x,
        action.y
      ).updatedState;

    case UPDATE_DRAGGING_HOLE:
      return Hole.updateDraggingHole(state, action.x, action.y).updatedState;

    case END_DRAGGING_HOLE:
      state = state.merge({
        sceneHistory: history.historyPush(state.sceneHistory, state.scene)
      });
      return Hole.endDraggingHole(state, action.x, action.y).updatedState;

    case END_DRAGGING_HOLE_3D:
      state = state.merge({
        sceneHistory: history.historyPush(state.sceneHistory, state.scene)
      });
      return Hole.endDraggingHole3D(state, action.x, action.y).updatedState;

    case SELECT_HOLE:
      return Hole.select(state, action.layerID, action.holeID).updatedState;

    default:
      return state;
  }
}
