import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Toggle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 36px;
  margin-left: 20px;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  :hover {
    box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2),
      0px 6px 10px 0px rgba(156, 154, 154, 0.39),
      0px 1px 18px 0px rgba(156, 154, 154, 0.12);
    border-radius: 18px;
  }
`;

const ToggleIcon = styled.div`
  ${'' /* transition-duration: .3s; */}
  position: absolute;
  top: 0px;
  img {
    font-size: 1.4rem;
    box-sizing: border-box;
    border-radius: 50%;
  }
`;

export default class ToggleMeasureButton extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    let { props } = this;
    let activeStyle = props.showMeasure === 0 ? { left: 0 } : { right: 0 };
    return (
      <Toggle onClick={props.onClick} style={{ ...props.style }}>
        {props.backgroundImage}
        {[
          props.icon !== undefined ? (
            <ToggleIcon style={{ ...activeStyle }} key={props.icon}>
              {props.icon}
            </ToggleIcon>
          ) : null
        ]}
      </Toggle>
    );
  }
}

ToggleMeasureButton.propTypes = {
  title: PropTypes.string,
  hover: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};
