import React from 'react';
import moment from 'moment';

const DownloadSummaryTemp = ({ children }) => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.header_log}>
          <div
            style={{
              height: '40px',
              width: '40px',
              backgroundColor: 'blueviolet'
            }}
          >
            <img src="/assets/rtastore_logo.png" width="100%" height="100%" />
          </div>
          <span style={{ padding: '0 10px' }}>
            {sessionStorage.getItem('projectTitle')}
          </span>
        </div>
        <span>
          Created for:
          <span
            style={{
              color: '#434D63',
              fontWeight: '700',
              marginLeft: 5
            }}
          >
            {sessionStorage.getItem('firstName') +
              ' ' +
              sessionStorage.getItem('lastName')}
          </span>
        </span>
        <span>
          Date:{' '}
          <span style={{ color: '#434D63', fontWeight: '700', marginLeft: 5 }}>
            {moment(new Date()).format('DD-MM-YYYY')}
          </span>
        </span>
        <span>
          ID:{' '}
          <span
            style={{
              color: '#434D63',
              fontWeight: '700',
              marginLeft: 5
            }}
          >
            #{sessionStorage.getItem('projectId')}
          </span>
        </span>
      </div>
      {children}
      <div
        style={{
          textAlign: 'justify',
          margin: '0 0 40px 0',
          fontSize: '10px',
          color: '#565F77'
        }}
      >
        <span
          style={{
            // fontSize: '8px',
            fontFamily: 'Open Sans',
            fontWeight: '700',
            lineHeight: '13px',
            wordWrap: 'break-word'
          }}
        >
          Disclaimer:
        </span>{' '}
        <span
          style={{
            // fontSize: '8px',
            fontFamily: 'Open Sans',
            fontWeight: '400',
            lineHeight: '13px',
            wordWrap: 'break-word'
          }}
        >
          DIY Design Space not take responsibility for the accuracy of
          measurements or furniture arrangements. The prices displayed here
          apply to items in stock at the time of the offer's issuance. All
          delivery, assembly, and installation services required are billed are
          billed separately and are not included in the price. While we strive
          to ensure the accuracy of the information in this program, we
          apologize for any errors that may occur.
        </span>
      </div>
      <div style={styles.footer}>
        <span>© DIY.DS v1.0/2024</span>
        <span>DIY Design Space® 2024 All rights reserved</span>
      </div>
    </div>
  );
};

const styles = {
  container: {
    // width: '454px',
    // height: '784px',
    // minHeight:'100%',
    backgroundColor: '#fff',
    padding: '30px 30px',
    marginBottom: '10px',
    borderRadius: '5px',
    fontFamily: 'Open Sans'
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0',
    borderBottom: '1px solid #EAEDFF',
    color: '#565F77',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '13px',
    wordWrap: 'break-word',
    fontFamily: 'Open Sans'
  },
  header_log: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  footer: {
    padding: '15px 0',
    borderTop: '1px solid #EAEDFF',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Inter',
    color: '#565F77',
    fontSize: '9px',
    lineHeight: '11.2px',
    wordWrap: 'break-word'
  }
};

export default DownloadSummaryTemp;
