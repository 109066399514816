import {
  DEFAULT_FONT_FAMILY,
  TEXT_COLOR_NEUTRAL_1,
  TEXT_COLOR_NEUTRAL_2,
  SECONDARY_PURPLE_COLOR,
  BG_COLOR_1,
  TEXT_COLOR_NEUTRAL_3,
  SHADE_LIGHT_PURPLE_COLOR,
  SHADE_DARK_PURPLE_COLOR
} from '../../../constants';
import styled from 'styled-components';

export const DialogWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
`;

export const Dialog = styled.div`
  position: relative;
  margin: auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  user-select: none;
  text-align: center;
  border-radius: 10px;
  z-index: 10;
  flex-flow: column;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2),
    0px 6px 10px 0px rgba(156, 154, 154, 0.39),
    0px 1px 18px 0px rgba(156, 154, 154, 0.12);
`;

export const DialogTitle = styled.span`
  color: ${TEXT_COLOR_NEUTRAL_1};
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
`;

export const DialogAction = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DialogContent = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 10px 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  width: 100%;
`;

export const DialogContents = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 10px 0px;
  font-size: 14px;
`;

export const StyledButton = styled.button`
  cursor: pointer;
  position: relative;
  border: 3px solid ${SECONDARY_PURPLE_COLOR};
  border-radius: 5px;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  color: ${BG_COLOR_1};
  background-color: ${SECONDARY_PURPLE_COLOR};
  outline: 0;
  :hover {
    background-color: ${SHADE_LIGHT_PURPLE_COLOR};
    border-color: ${SHADE_LIGHT_PURPLE_COLOR};
  }
  :active {
    background-color: ${SHADE_DARK_PURPLE_COLOR};
    border-color: ${SHADE_DARK_PURPLE_COLOR};
  }
`;

export const ButtonTitle = styled.span`
  color: ${BG_COLOR_1};
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
`;

export const DialogContentSelection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
`;

export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0px;
`;

export const StyledInput = styled.input`
  width: 100%;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  height: 44px;
  text-align: left;
  padding: 10px;
  color: ${TEXT_COLOR_NEUTRAL_2};
  border: 1px solid;
  border-radius: 5px;
  outline: 0;
  :focus {
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
  :hover {
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
`;

export const StyledInputHeadLabel = styled.span`
  font-size: 12px;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  position: absolute;
  left: 12px;
  top: -9px;
  background-color: ${BG_COLOR_1};
  padding: 0px 7px;
  color: ${SECONDARY_PURPLE_COLOR};
`;

export const PlusImage = styled.img`
  position: absolute;
  height: 25px;
  width: 25px;
  transform: rotate(45deg);
  right: 10px;
  cursor: pointer;
  background-color: ${TEXT_COLOR_NEUTRAL_3};
  -webkit-mask-image: url(${props => props.maskImage});
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
`;
