import React from 'react';
import { TEXT_COLOR_NEUTRAL_0 } from '../../../../../../constants';

export default function Ruler3DDownload({ layer, line, viewScale }) {
  const diagonalPointValue = 5 * viewScale;

  const STYLE = {
    stroke: TEXT_COLOR_NEUTRAL_0,
    strokeWidth: viewScale
  };

  const STYLE_TEXT = {
    textAnchor: 'middle',
    fontSize: 32 * viewScale,
    // fontWeight: "bold",
    pointerEvents: 'none',
    fill: TEXT_COLOR_NEUTRAL_0,

    //http://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting-using-css
    WebkitTouchCallout: 'none' /* iOS Safari */,
    WebkitUserSelect: 'none' /* Chrome/Safari/Opera */,
    MozUserSelect: 'none' /* Firefox */,
    MsUserSelect: 'none' /* Internet Explorer/Edge */,
    userSelect: 'none'
  };

  const getDistance = (v0, v1) => {
    return Math.sqrt(
      (v0.x - v1.x) * (v0.x - v1.x) + (v0.y - v1.y) * (v0.y - v1.y)
    );
  };

  const { v0, v1, space } = line;
  const text = line.text.endsWith('.0')
    ? line.text.slice(0, line.text.length - 2)
    : line.text;
  let length = getDistance(v0, v1);

  let textLength = (text.length + layer.unit.length) * 18 * viewScale;
  if (v0.y === v1.y) {
    if (v0.x < v1.x) {
      return (
        <g>
          <line x1={v0.x} y1={v0.y} x2={v0.x} y2={v0.y - space} style={STYLE} />
          <g transform={`translate(${v0.x}, ${v0.y - space})`}>
            <line
              x1={-diagonalPointValue}
              y1={-diagonalPointValue}
              x2={diagonalPointValue}
              y2={diagonalPointValue}
              style={STYLE}
            />
            <line
              x1={0}
              y1={0}
              x2={textLength > length ? 0 : (length - textLength) / 2}
              y2={0}
              style={STYLE}
            />
            <text x={length / 2} y="3" style={STYLE_TEXT}>
              {text}
              {layer.unit === 'in' ? '"' : layer.unit}
            </text>
            <line
              x1={length}
              y1={0}
              x2={textLength > length ? length : (length + textLength) / 2}
              y2={0}
              style={STYLE}
            />
            <line
              x1={length - diagonalPointValue}
              y1={-diagonalPointValue}
              x2={length + diagonalPointValue}
              y2={diagonalPointValue}
              style={STYLE}
            />
          </g>
          <line x1={v1.x} y1={v1.y} x2={v1.x} y2={v1.y - space} style={STYLE} />
        </g>
      );
    } else {
      return (
        <g>
          <line x1={v0.x} y1={v0.y} x2={v0.x} y2={v0.y + space} style={STYLE} />
          <g transform={`translate(${v0.x}, ${v0.y + space})`}>
            <line
              x1={-diagonalPointValue}
              y1={-diagonalPointValue}
              x2={diagonalPointValue}
              y2={diagonalPointValue}
              style={STYLE}
            />
            <line
              x1={0}
              y1={0}
              x2={textLength > length ? 0 : -(length - textLength) / 2}
              y2={0}
              style={STYLE}
            />
            <text x={-length / 2} y="3" style={STYLE_TEXT}>
              {text}
              {layer.unit === 'in' ? '"' : layer.unit}
            </text>
            <line
              x1={-length}
              y1={0}
              x2={textLength > length ? -length : -(length + textLength) / 2}
              y2={0}
              style={STYLE}
            />
            <line
              x1={-length - diagonalPointValue}
              y1={-diagonalPointValue}
              x2={-length + diagonalPointValue}
              y2={diagonalPointValue}
              style={STYLE}
            />
          </g>
          <line x1={v1.x} y1={v1.y} x2={v1.x} y2={v1.y + space} style={STYLE} />
        </g>
      );
    }
  } else if ((v0.x = v1.x)) {
    if (v0.y < v1.y) {
      return (
        <g>
          <line x1={v0.x} y1={v0.y} x2={v0.x + space} y2={v0.y} style={STYLE} />
          <g transform={`translate(${v0.x + space}, ${v0.y})`}>
            <line
              x1={-diagonalPointValue}
              y1={-diagonalPointValue}
              x2={diagonalPointValue}
              y2={diagonalPointValue}
              style={STYLE}
            />
            <line
              x1={0}
              y1={0}
              x2={0}
              y2={textLength > length ? 0 : (length - textLength) / 2}
              style={STYLE}
            />
            <text
              x={length / 2}
              y={0}
              style={{ ...STYLE_TEXT, transform: 'rotate(90deg)' }}
            >
              {text}
              {layer.unit === 'in' ? '"' : layer.unit}
            </text>
            <line
              x1={0}
              y1={length}
              x2={0}
              y2={textLength > length ? length : (length + textLength) / 2}
              style={STYLE}
            />
            <line
              x1={-diagonalPointValue}
              y1={length - diagonalPointValue}
              x2={diagonalPointValue}
              y2={length + diagonalPointValue}
              style={STYLE}
            />
          </g>
          <line x1={v1.x} y1={v1.y} x2={v1.x + space} y2={v1.y} style={STYLE} />
        </g>
      );
    } else {
      return (
        <g>
          <line x1={v0.x} y1={v0.y} x2={v0.x - space} y2={v0.y} style={STYLE} />
          <g transform={`translate(${v0.x - space}, ${v0.y})`}>
            <line
              x1={-diagonalPointValue}
              y1={-diagonalPointValue}
              x2={diagonalPointValue}
              y2={diagonalPointValue}
              style={STYLE}
            />
            <line
              x1={0}
              y1={0}
              x2={0}
              y2={textLength > length ? 0 : -(length - textLength) / 2}
              style={STYLE}
            />
            <text
              x={-length / 2}
              y={0}
              style={{ ...STYLE_TEXT, transform: 'rotate(90deg)' }}
            >
              {text}
              {layer.unit === 'in' ? '"' : layer.unit}
            </text>
            <line
              x1={0}
              y1={-length}
              x2={0}
              y2={textLength > length ? -length : -(length + textLength) / 2}
              style={STYLE}
            />
            <line
              x1={-diagonalPointValue}
              y1={-length - diagonalPointValue}
              x2={diagonalPointValue}
              y2={-length + diagonalPointValue}
              style={STYLE}
            />
          </g>
          <line x1={v1.x} y1={v1.y} x2={v1.x - space} y2={v1.y} style={STYLE} />
        </g>
      );
    }
  } else return null;
}
