import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import convert from 'convert-units';

export default function GridStreak({ width, height, grid }) {
  let step = grid.properties.get('step');
  let colors;
  if (grid.properties.has('color')) {
    colors = new List([grid.properties.get('color')]);
  } else {
    colors = grid.properties.get('colors');
  }

  let rendered = [];
  let i = 0;
  for (let y = 0; y <= height; y += step) {
    for (let x = 0; x <= width; x += step) {
      let color = colors.get(i % colors.size);
      i++;
      rendered.push(<circle key={i} cx={x} cy={y} r={1.5} fill={color} />);
    }
  }

  return <g>{rendered}</g>;
}

GridStreak.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  grid: PropTypes.object.isRequired
};
