import React from 'react';
import ReviewItem from '../product/reviewItem';
import { returnReplaceableDeepSearchType } from '../../../viewer2d/utils';

import * as S from './styles';
import * as MS from '../styles';

import { ToolbarButtonWithPopup } from '../';

class ReviewQuoteToolbarButton extends React.Component {
  constructor(props) {
    super(props);
  }

  makeSKUForMagento(element) {
    let _element = this.props.catalog.elements[element.type];

    if (!_element) {
      _element =
        this.props.catalog.elements[
          returnReplaceableDeepSearchType(element.type)
        ];
    }
    let doorColor = element.doorStyle;
    if (element.doorStyle.size) doorColor = element.doorStyle.toJS();
    let elementSKU = '';
    elementSKU = _element.obj.sku_number;
    let tempSKU = _element.obj.skuArray.find(
      el => el.door_color_id === doorColor.id
    );
    if (tempSKU !== undefined) elementSKU = tempSKU.sku;
    //Check special case. ex: BBC36-R, BBLC39-42-36-L
    if (elementSKU.endsWith('-R') || elementSKU.endsWith('-L'))
      elementSKU = elementSKU.slice(0, -2);
    if (doorColor.sku) elementSKU += `-${doorColor.sku}`;
    if (doorColor.isAssembled) elementSKU += `-Assembled`;
    return elementSKU;
  }

  render() {
    let {
      handleSaveDesign,
      handleSubmitForQuote,
      handleDesignerAssist,
      dataSource,
      products,
      catalog,
      categoryData,
      trigger,
      collapse,
      collapseAction,
      isOpened,
      mode,
      onClose
    } = this.props;
    let usedObjects = {},
      allPrices = 0,
      allCount = 0;
    let objectsArray = dataSource;
    let uniqueData = {};
    const shouldShowContent = sessionStorage.getItem('deepSearchTypes');
    uniqueData = objectsArray.filter(async element => {
      if (element.itemID === undefined) return element;
      let elementSKU = this.makeSKUForMagento(element);

      if (elementSKU.startsWith('#')) return false; //Validate elementSKU
      allCount++;

      if (!usedObjects[elementSKU]) {
        usedObjects[elementSKU] = {
          ...usedObjects[elementSKU],
          count: 1,
          regularPrice: 0,
          disCountPrice: 0,
          sku_r: ''
        };
        if (products[elementSKU]) {
          usedObjects[elementSKU].regularPrice +=
            products[elementSKU].regular_price;
          usedObjects[elementSKU].disCountPrice +=
            products[elementSKU].discounted_price;
          usedObjects[elementSKU].sku_r = products[elementSKU].sku_r;
          allPrices += products[elementSKU].discounted_price;
        }
        return element;
      } else {
        if (products[elementSKU]) {
          usedObjects[elementSKU].regularPrice +=
            products[elementSKU].regular_price;
          usedObjects[elementSKU].disCountPrice +=
            products[elementSKU].discounted_price;
          allPrices += products[elementSKU].discounted_price;
        }
        usedObjects[elementSKU].count++;
      }
    });

    sessionStorage.setItem('usedObjects', JSON.stringify(usedObjects));

    let content = !shouldShowContent ? (
      ''
    ) : (
      <S.Content>
        {uniqueData.count()
          ? uniqueData.entrySeq().map(([itemID, item]) => {
              let itemSKU = this.makeSKUForMagento(item);
              return (
                <ReviewItem
                  key={itemID}
                  catalog={catalog}
                  categoryData={categoryData}
                  itemSKU={itemSKU}
                  item={item}
                  products={products}
                  count={
                    usedObjects[itemSKU] !== undefined
                      ? usedObjects[itemSKU].count
                      : 1
                  }
                />
              );
            })
          : null}
        <div
          style={{
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <span>
            <strong>Grand Total</strong>
          </span>
          <span style={{ paddingLeft: '120px' }}>{allCount}</span>
          <span>{`$${parseFloat(allPrices.toFixed(2))}`}</span>
        </div>
        <S.Control onClick={() => handleSaveDesign(true)}>
          <S.ControlIcon>
            <img
              width={'150px'}
              name={'Save Project'}
              src={'/assets/img/svg/toolbar/save_project_button.svg'}
            />
            <MS.ButtonTitle style={{ top: '43%', fontSize: 12 }}>
              Save Design
            </MS.ButtonTitle>
          </S.ControlIcon>
        </S.Control>
        <S.Control style={{ justifyContent: 'space-between' }}>
          <S.ControlIcon onClick={() => handleSubmitForQuote(true)}>
            <img
              width={'150px'}
              name={'Email Quote'}
              src={'/assets/img/svg/toolbar/email_quote_button.svg'}
            />
            <MS.ButtonTitle style={{ top: '43%', fontSize: 12 }}>
              Submit for Quote
            </MS.ButtonTitle>
          </S.ControlIcon>
          <S.ControlIcon onClick={() => handleDesignerAssist(true)}>
            <img
              width={'150px'}
              name={'Consult a Designer'}
              src={'/assets/img/svg/toolbar/consult_a_designer_button.svg'}
            />
            <MS.ButtonTitle style={{ top: '43%', fontSize: 12 }}>
              Designer Assistance
            </MS.ButtonTitle>
          </S.ControlIcon>
        </S.Control>
      </S.Content>
    );
    return (
      <div>
        <ToolbarButtonWithPopup
          mode={mode}
          order={5}
          collapse={collapse}
          collapseAction={collapseAction}
          trigger={trigger}
          isOpened={isOpened}
          closeAction={onClose}
        >
          {content}
        </ToolbarButtonWithPopup>
      </div>
    );
  }
}

export default ReviewQuoteToolbarButton;
