import styled from 'styled-components';
import * as SharedStyle from '../../../../../shared-style';

export const Control = styled.div`
  display: flex;
  justify-content: center;
`;

export const ControlIcon = styled.div`
  margin-top: 9px;
  cursor: pointer;
  position: relative;
  :hover {
    img {
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.39),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }
  }
  :active {
    img {
      box-shadow: none !important;
    }
  }
`;

export const LineWrapper = styled.div`
  color: #fff;
  display: flex;
  cursor: pointer;
  align-items: center;
  span {
    @media screen and (min-width: 1024) {
      font-size: 11px;
    }
    @media screen and (max-width: 1024) {
      font-size: 11px;
    }
    @media screen and (min-width: 1366) {
      font-size: 13px;
    }
    @media screen and (max-width: 1366) {
      font-size: 13px;
    }
    @media screen and (min-width: 1440) {
      font-size: 16px;
    }
    @media screen and (max-width: 1440) {
      font-size: 16px;
    }
  }
  :hover {
    span {
      color: ${SharedStyle.SECONDARY_COLOR.main};
    }
  }
`;

export const Div = styled.div`
  position: fixed;
  top: calc(50% - 150px);
  left: calc(50% - 200px);
  z-index: 200;
  background: #2b2b2ddf;
  align-items: space-around;
  justify-content: center;
  color: white;
  width: 350px;
  height: 140px;
`;

export const ButtonTitle1 = styled.span`
  position: absolute;
  padding-left: 20px;
  margin-top: 3px;
  font-size: 12px;
  cursor: pointer;
`;

export const ButtonTitle2 = styled.span`
  position: absolute;
  padding-left: 20px;
  margin-top: 3px;
  font-size: 12px;
  cursor: pointer;
`;
