import React from 'react';
import * as Three from 'three';

const STYLE_STR0 = {
  fill: 'rgb(185, 185, 185)',
  stroke: '#494949',
  strokeWidth: '1',
  strokeMiterlimit: '2.61313'
};
const STYLE_STR0_S = {
  fill: 'rgb(185, 185, 185)',
  stroke: '#0096fd',
  strokeWidth: '1',
  strokeMiterlimit: '2.61313'
};
const STYLE_FILL2 = { fill: '#1183B7' };
const STYLE_FNT0 = {
  fill: 'white',
  fontWeight: 'normal',
  fontSize: '13px',
  fontFamily: 'Proxima Nova Rg'
};

export default {
  name: 'FrameLess Doorway',
  prototype: 'holes',

  info: {
    title: 'FrameLess Doorway',
    tag: ['doorway'],
    description: 'FrameLess Doorway',
    image: '/assets/img/svg/door/Frameless_dorway.svg',
    url: ''
  },

  properties: {
    width: {
      label: 'Width',
      type: 'length-measure',
      defaultValue: {
        length: 91.44
      }
    },
    height: {
      label: 'Height',
      type: 'length-measure',
      defaultValue: {
        length: 215
      }
    },
    altitude: {
      label: 'Altitude',
      type: 'length-measure',
      defaultValue: {
        length: 0
      }
    },
    thickness: {
      label: 'Thickness',
      type: 'length-measure',
      defaultValue: {
        length: 6
      }
    },
    flip_orizzontal: {
      label: 'Flip Door',
      type: 'checkbox',
      defaultValue: false,
      values: {
        none: false,
        yes: true
      }
    }
  },

  render2D: function (element, layer, scene) {
    let flip = element.properties.get('flip_orizzontal');
    let lineWidth = 6;
    let holeWidth = element.properties.get('width').get('length');
    let rectStyle = element.selected ? STYLE_STR0_S : STYLE_STR0;
    let length = element.properties.get('width').get('length');
    if (flip == false) {
      return (
        <g transform={`translate(${-length / 2}, 0)`}>
          <rect
            style={rectStyle}
            x="0"
            y={-lineWidth / 2}
            width={holeWidth}
            height={lineWidth}
          />
          {/* <rect style={STYLE_FILL2} x={holeWidth - 16} y="-8.5" width="17" height="17" rx="1.27" ry="1.27"/> */}
          {/* <text x={holeWidth - 15} y="4.5" transform={`scale(1,-1)`} style={STYLE_FNT0}>FS</text> */}
        </g>
      );
    } else {
      return (
        <g transform={`translate(${-length / 2}, 0)`}>
          <rect
            style={rectStyle}
            x="0"
            y={-lineWidth / 2}
            width={holeWidth}
            height={lineWidth}
          />
          {/* <rect style={STYLE_FILL2} x={holeWidth - 16} y="-8.5" width="17" height="17" rx="1.27" ry="1.27"/> */}
          {/* <text x={holeWidth - 15} y="4.5"  style={STYLE_FNT0}>FS</text> */}
        </g>
      );
    }
  },

  render3D: function (element, layer, scene) {
    return Promise.resolve(new Three.Object3D());
  }
};
