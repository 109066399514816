import React from 'react';
import * as S from './styles';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import ReviewItem from '../product/reviewItem';
import {
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_2,
  TEXT_COLOR_NEUTRAL_5,
  BG_COLOR_1,
  API_SERVER_URL,
  MODE_BACK_ELEVATION_VIEW,
  MODE_FRONT_ELEVATION_VIEW,
  MODE_LEFT_ELEVATION_VIEW,
  MODE_RIGHT_ELEVATION_VIEW
} from '../../../../constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '../../main/styles';
import moment from 'moment';
import ShowCabinetInfo from './showCabinetInfo';
import Show2DView from './show2D/show2DView';
import Show3DView from './show3D/show3DView';
import ShowElevationView from './showElevation/showElevationView';
import ShowWarranty from './showWarranty';
import axios from 'axios';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    border-radius: 5px;
    max-width: unset;
    width: 42%;
    height: 68%;
    position: relative;
  }
  //::-webkit-scrollbar {
  //  width: 2px;
  //  border-radius: 2px;
  //  margin-left: 3px;
  //}
  //::-webkit-scrollbar-track-piece {
  //    background-color: #ff0000;
  //    border-radius: 1px;
  //}
`;

const DesignViewer = styled.div`
  width: 55%;
  border-style: solid;
  border-width: 10px 10px 10px 20px;
  border-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  padding-right: 10px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 2px;
    position: absolute;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(220, 220, 220);
    border-radius: 5px;
    transition: all 0.3s;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(193, 202, 228);
    border-radius: 5px;
    transition: all 0.3s;
  }
`;

const StyledMuiCheckbox = withStyles({
  root: {
    color: TEXT_COLOR_NEUTRAL_2,
    '&$checked': {
      color: SECONDARY_PURPLE_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

class DownloadSummaryPopup extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props)
    // let layer = props.layers.get(props.selectedLayer);
    // let elements = {
    //   lines: layer.lines,
    //   holes: layer.holes,
    //   items: layer.items,
    //   molding: layer.molding
    // };
    // let ceilingHeight = props.state.scene.layers.get(props.selectedLayer).ceilHeight;
    // // let measurementUnit = unitData.filter(unit => unit._unit === props.state.scene.layers.get(props.selectedLayer).unit)[0];

    this.state = {
      // elements : elements,
      // measurementUnit : measurementUnit,
      email: sessionStorage.getItem('email') || '',
      emailfocusOn: false,
      phone: sessionStorage.getItem('phone') || '',
      phonefocusOn: false,
      firstName: sessionStorage.getItem('firstName') || '',
      firstNamefocusOn: false,
      lastName: sessionStorage.getItem('lastName') || '',
      lastNamefocusOn: false,
      projectTitlefocusOn: false,
      projectTitle: sessionStorage.getItem('projectTitle'),
      check_3d: false,
      check_2d: false,
      check_list: false,
      image: '',
      isLoading: false,
      monkeyDocId: null
    };
  }

  _closePanel() {
    this.props.setDownloadPopupVisible(false);
  }

  render() {
    const { visible } = this.props;
    const {
      firstName,
      firstNameFocusOn,
      lastName,
      lastNameFocusOn,
      email,
      emailFocusOn,
      phone,
      phoneFocusOn,
      projectTitle,
      projectTitleFocusOn,
      check_3d,
      check_2d,
      check_list,
      image,
      isLoading,
      monkeyDocId
    } = this.state;

    const pdfBrowserDownload = (fileName, pdfUrl) => {
      setTimeout(() => {
        let fileOutputLink = document.createElement('a');

        fileOutputLink.setAttribute('download', fileName);
        fileOutputLink.setAttribute('target', '_blank');
        fileOutputLink.href = pdfUrl;
        fileOutputLink.style.display = 'none';
        document.body.appendChild(fileOutputLink);
        setTimeout(() => {
          fileOutputLink.click();
        }, 100);
        document.body.removeChild(fileOutputLink);
        this.setState({
          isModalVisible: false,
          imageUri: '',
          image: ''
        });

        document.removeEventListener('keydown', this.onKeyDown, false);
        document.removeEventListener(
          'mouseup',
          this.onMouseUp_On_New_Save_SQ_DA,
          false
        );
      }, 750);
    };

    const downloadSummary = async () => {
      // for imgCapture
      let img3D = 'false';
      let img2DPlain = 'false',
        imgFront = 'false',
        imgBack = 'false',
        imgLeft = 'false',
        imgRight = 'false';

      // for header
      const projectTitle = projectTitle;
      const creator = (firstName + ' ' + lastName).trim();
      const createDate = moment(new Date()).format('DD-MM-YYYY');
      const projectID = sessionStorage.getItem('projectId');
      // for project save
      const projectData = JSON.stringify(this.props.state.get('scene').toJS());
      // for cabinet list
      const cabinetList = window.uniqueData;
      // for print checkboxes
      const print_check = { check_3d, check_2d, check_list };
      this.setState({ isLoading: true });

      const wait_for_prepare_data = v => {
        if (!v) {
          setTimeout(downloadSummary, 1000);
          return true;
        }
        return false;
      };

      if (check_3d) {
        if (wait_for_prepare_data(window.pdf3DPlain)) return;
        img3D = window.pdf3DPlain;
      }
      if (check_2d) {
        if (wait_for_prepare_data(window.pdf2DPlain)) return;
        img2DPlain = window.pdf2DPlain;

        if (wait_for_prepare_data(window.__elevationImg)) return;
        imgFront = window.__elevationImg.MODE_FRONT_ELEVATION_VIEW;
        imgBack = window.__elevationImg.MODE_BACK_ELEVATION_VIEW;
        imgLeft = window.__elevationImg.MODE_LEFT_ELEVATION_VIEW;
        imgRight = window.__elevationImg.MODE_RIGHT_ELEVATION_VIEW;
      }

      axios
        .post(`${API_SERVER_URL}/api/project/downloadPDF`, {
          monkeyDocId: monkeyDocId,
          img3D: img3D,
          img2DElevation: [img2DPlain, imgFront, imgBack, imgLeft, imgRight],
          projectTitle: projectTitle,
          creator: creator,
          createDate: createDate,
          projectID: projectID,
          projectData: projectData,
          cabinetList: cabinetList,
          whatToPrint: print_check
        })
        .then(async res => {
          const respData = res.data;
          if (respData.success) {
            this.setState({
              isLoading: false,
              monkeyDocId: respData.monkeyDocId
            });
            pdfBrowserDownload(respData.filename, respData.download_url);
          } else {
            this.setState({ isLoading: false });
          }
        });
    };

    return (
      <div>
        <StyledDialog
          id="showInfo"
          open={visible}
          onClose={() => this._closePanel()}
        >
          {isLoading && (
            <div
              style={{
                position: 'absolute',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                opacity: '0.8',
                background: 'gray',
                zIndex: '10',
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              <img
                style={{ animation: 'spin 2s linear infinite' }}
                src={'/assets/img/loading_large.gif'}
                alt="img"
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              width: '100%',
              userSelect: 'none ',
              height: '100%'
            }}
          >
            <div
              style={{
                width: '45%',
                display: 'flex',
                padding: '40px 40px 40px 40px',
                flexDirection: 'column'
              }}
            >
              <S.DialogTitle>Download Summary</S.DialogTitle>
              <S.DialogContent style={{ margin: '10px 0' }}>
                Your are almost there, customize your summary based on your
                requirements.
              </S.DialogContent>
              <div
                style={{
                  height: '80%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'scroll'
                }}
              >
                {sessionStorage.getItem('email') === null && (
                  <div style={{}}>
                    <S.StyledInputWrapper>
                      <S.StyledInput
                        value={firstName}
                        onChange={e =>
                          this.setState({ firstName: e.target.value })
                        }
                        onFocus={e => this.setState({ firstNameFocusOn: true })}
                        onBlur={e => this.setState({ firstNameFocusOn: false })}
                        placeholder={
                          firstNameFocusOn ? 'Enter First Name' : 'First Name'
                        }
                        required
                      />
                      {(firstNameFocusOn || !!firstName.length) && (
                        <S.StyledInputHeadLabel
                          style={{
                            color: !firstNameFocusOn && TEXT_COLOR_NEUTRAL_2
                          }}
                        >
                          First Name
                        </S.StyledInputHeadLabel>
                      )}
                      {!!firstName.length && (
                        <S.PlusImage
                          maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                          onClick={() => this.setState({ firstName: '' })}
                        />
                      )}
                    </S.StyledInputWrapper>
                    <S.StyledInputWrapper>
                      <S.StyledInput
                        value={lastName}
                        onChange={e =>
                          this.setState({ lastName: e.target.value })
                        }
                        onFocus={e => this.setState({ lastNameFocusOn: true })}
                        onBlur={e => this.setState({ lastNameFocusOn: false })}
                        placeholder={
                          lastNameFocusOn ? 'Enter Last Name' : 'Last Name'
                        }
                        required
                      />
                      {(lastNameFocusOn || !!lastName.length) && (
                        <S.StyledInputHeadLabel
                          style={{
                            color: !lastNameFocusOn && TEXT_COLOR_NEUTRAL_2
                          }}
                        >
                          Last Name
                        </S.StyledInputHeadLabel>
                      )}
                      {!!lastName.length && (
                        <S.PlusImage
                          maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                          onClick={() => this.setState({ lastName: '' })}
                        />
                      )}
                    </S.StyledInputWrapper>
                    <S.StyledInputWrapper>
                      <S.StyledInput
                        value={email}
                        onChange={e => this.setState({ email: e.target.value })}
                        onFocus={e => this.setState({ emailFocusOn: true })}
                        onBlur={e => this.setState({ emailFocusOn: false })}
                        placeholder={emailFocusOn ? 'Enter Email' : 'Email'}
                        required
                      />
                      {(emailFocusOn || !!email.length) && (
                        <S.StyledInputHeadLabel
                          style={{
                            color: !emailFocusOn && TEXT_COLOR_NEUTRAL_2
                          }}
                        >
                          Email
                        </S.StyledInputHeadLabel>
                      )}
                      {!!email.length && (
                        <S.PlusImage
                          maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                          onClick={() => this.setState({ email: '' })}
                        />
                      )}
                    </S.StyledInputWrapper>
                    <S.StyledInputWrapper>
                      <S.StyledInput
                        value={phone}
                        onChange={e => this.setState({ phone: e.target.value })}
                        onFocus={e => this.setState({ phoneFocusOn: true })}
                        onBlur={e => this.setState({ phoneFocusOn: false })}
                        placeholder={
                          phoneFocusOn ? 'Enter Phone Number' : 'Phone Number'
                        }
                        required
                      />
                      {(phoneFocusOn || !!phone.length) && (
                        <S.StyledInputHeadLabel
                          style={{
                            color: !phoneFocusOn && TEXT_COLOR_NEUTRAL_2
                          }}
                        >
                          Phone Number
                        </S.StyledInputHeadLabel>
                      )}
                      {!!phone.length && (
                        <S.PlusImage
                          maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                          onClick={() => this.setState({ phone: '' })}
                        />
                      )}
                    </S.StyledInputWrapper>
                    <S.StyledInputWrapper>
                      <S.StyledInput
                        value={projectTitle}
                        onChange={e =>
                          this.setState({ projectTitle: e.target.value })
                        }
                        onFocus={e =>
                          this.setState({ projectTitleFocusOn: true })
                        }
                        onBlur={e =>
                          this.setState({ projectTitleFocusOn: false })
                        }
                        placeholder={
                          projectTitleFocusOn
                            ? 'Enter Project Name'
                            : 'Project Name'
                        }
                        required
                      />
                      {(projectTitleFocusOn || !!projectTitle.length) && (
                        <S.StyledInputHeadLabel
                          style={{
                            color: !projectTitleFocusOn && TEXT_COLOR_NEUTRAL_2
                          }}
                        >
                          Project Name
                        </S.StyledInputHeadLabel>
                      )}
                      {!!projectTitle.length && (
                        <S.PlusImage
                          maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                          onClick={() => this.setState({ projectTitle: '' })}
                        />
                      )}
                    </S.StyledInputWrapper>
                  </div>
                )}
                <S.DialogContent style={{ margin: '4px 0px' }}>
                  What you want to print:
                </S.DialogContent>
                <FormControlLabel
                  style={{ margin: '6px 0px' }}
                  control={
                    <StyledMuiCheckbox
                      style={{ padding: 'unset' }}
                      checked={check_3d}
                      onChange={() => this.setState({ check_3d: !check_3d })}
                      value={check_3d}
                    />
                  }
                  label={
                    <S.DialogContents>
                      3D render of the kitchen
                    </S.DialogContents>
                  }
                />
                <FormControlLabel
                  style={{ margin: '6px 0px' }}
                  control={
                    <StyledMuiCheckbox
                      style={{ padding: 'unset' }}
                      checked={check_2d}
                      onChange={() => this.setState({ check_2d: !check_2d })}
                      value={check_2d}
                    />
                  }
                  label={
                    <S.DialogContents>
                      2D floor plan and elevations technical view
                    </S.DialogContents>
                  }
                />
                <FormControlLabel
                  style={{ margin: '6px 0px' }}
                  control={
                    <StyledMuiCheckbox
                      style={{ padding: 'unset' }}
                      checked={check_list}
                      onChange={() =>
                        this.setState({ check_list: !check_list })
                      }
                      value={check_list}
                    />
                  }
                  label={
                    <S.DialogContents>
                      List of parts with quantities and pricing
                    </S.DialogContents>
                  }
                />
              </div>
              <S.SaveBox onClick={downloadSummary}>
                <S.SelectTitle style={{ color: BG_COLOR_1 }}>
                  Save & Download Summary
                </S.SelectTitle>
              </S.SaveBox>
            </div>
            <DesignViewer>
              {check_3d && <Show3DView props={this.props} />}
              {check_2d && <Show2DView props={this.props} />}
              {check_2d && [
                <ShowElevationView
                  props={this.props}
                  mode={MODE_FRONT_ELEVATION_VIEW}
                />,
                <ShowElevationView
                  props={this.props}
                  mode={MODE_BACK_ELEVATION_VIEW}
                />,
                <ShowElevationView
                  props={this.props}
                  mode={MODE_LEFT_ELEVATION_VIEW}
                />,
                <ShowElevationView
                  props={this.props}
                  mode={MODE_RIGHT_ELEVATION_VIEW}
                />
              ]}
              {check_list && (
                <ShowCabinetInfo
                  productDATA={window.productData || null}
                  totalPrice={window.totalPrice}
                />
              )}
              <ShowWarranty />
            </DesignViewer>
          </div>
        </StyledDialog>
      </div>
    );
  }
}

export default DownloadSummaryPopup;
