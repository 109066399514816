import styled from 'styled-components';
import {
  BG_COLOR_HOVER,
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_1
} from '../../../../constants';

export const DialogTitle = styled.span`
  font-weight: bold;
  color: ${TEXT_COLOR_NEUTRAL_1};
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 26px;
  padding: 25px 30px;
`;

export const DialogContent = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 10px 30px;
`;

export const DialogAction = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 30px 25px;
`;

export const Button = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  color: ${SECONDARY_PURPLE_COLOR};
  font-weight: bold;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  border-radius: 20px;
  :hover {
    background-color: ${BG_COLOR_HOVER};
  }
`;
