import React, { useState } from 'react';
import styled from 'styled-components';

import * as S from './styles';
import * as SharedStyle from '../../../../shared-style';

const StyledSpan = styled.span`
  color: ${props =>
    props.idx === props.selOption && props.selSubOption === props.subOptionIdx
      ? SharedStyle.SECONDARY_COLOR.main
      : '#fff'};
  margin-left: 23px;
  font-size: 12px;
`;

export default function DoorStyle({
  idx,
  url,
  onClick,
  name,
  selOption,
  selSubOption,
  subOptionIdx
}) {
  name = name.charAt(0).toUpperCase() + name.substr(1);

  return (
    <S.PrimayDoorStyleLineWrapper
      style={{
        padding: '5px 5px 5px 20px',
        borderBottom: '1px solid #2f2f2f',
        height: 90
      }}
      onClick={onClick}
    >
      <div style={{ width: '100%', marginLeft: '10px', marginTop: '10px' }}>
        <h4
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '3px',
            marginBottom: '9px'
          }}
        >
          <img height={'50'} name={name} src={url} />
          <StyledSpan
            idx={idx}
            selOption={selOption}
            subOptionIdx={subOptionIdx}
            selSubOption={selSubOption}
          >
            {name}
          </StyledSpan>
        </h4>
      </div>
    </S.PrimayDoorStyleLineWrapper>
  );
}
