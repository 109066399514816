import {
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR,
  BG_COLOR_HOVER,
  SHADE_LIGHT_PURPLE_COLOR,
  SHADE_DARK_PURPLE_COLOR,
  BG_COLOR_1
} from '../../constants';
import styled from 'styled-components';

// Common Styles
export const IconButton = styled.button`
  color: #fff;
  font-size: 12px;
  border: 0px;
  border-radius: 3px;
  background: #000;
  border: 1px solid #000;
  padding: 5px 5px 2px 5px;
  cursor: pointer;
`;

export const ActiveIconButton = styled.button`
  color: #fff;
  font-size: 12px;
  border: 0px;
  border-radius: 3px;
  background: #0f8a61;
  border: 1px solid #0f8a61;
  padding: 5px 5px 2px 5px;
  cursor: pointer;
`;

// Main Styles
export const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  font-size: 12px;
`;

export const TitleBar = styled.div`
  width: 100%;
  height: 35px;
  background: #fff;
  font-size: 16px;
  color: #1b1b1b;
  padding: 0px 8px 0px 8px;
  clear: both;
`;

export const Title = styled.div`
  float: left;
  line-height: 32px;
  color: #fff;
  font-size: 12px;
`;

export const SelectTitle = styled.span`
  color: ${SECONDARY_PURPLE_COLOR};
  padding-left: 10px;
  margin: auto;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
`;

export const TitleBarIconButton = styled.div`
  float: left;
  margin-left: 10px;
  margin-top: 15px;
  cursor: pointer;
  :hover {
    font-size: 20px;
  }
`;

export const ContentWrapper = styled.div`
  text-align: left;
  clear: both;
  padding: 10px 15px;
`;

export const SearchForm = styled.div`
  position: relative;
  margin: 0px 5px;
`;

export const SearchInput = styled.input`
  background: #fff;
  border: 0px;
  border-radius: 3px;
  height: 25px;
  width: 100%;
  padding-left: 24px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px;
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 6px;
  left: 5px;
  color: #666;
  cursor: pointer;
  font-size: 15px;
`;

export const Products = styled.div``;

export const ProductsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: streatch;
`;
export const TitlesRow = styled.div`
  display: flex;
`;

export const RecentTitle = styled.div`
  color: #fff;
  margin: 14px 0px 19px;
`;

export const RecentStyles = styled.div``;

export const AddStyleButtonWrapper = styled.div`
  cursor: pointer;
  color: #fff;
  button {
    float: left;
  }
  span {
    float: left;
    margin-top: 7px;
    margin-left: 10px;
    font-size: 10px;
  }
`;

export const SubTitle = styled.div`
  color: #1b1b1b;
  cursor: pointer;
  padding: 10px 5px;
  :hover {
    background: #ababab;
  }
`;
export const ChildTitle = styled.div`
  color: #000;
  padding-bottom: 10px;
`;
export const CabinetTitle = styled.div`
  color: #000;
  font-weight: 800;
`;
export const PropteryValue = styled.div`
  color: #000;
  font-weight: 800;
  padding-bottom: 10px;
  span {
    font-weight: 100;
  }
`;
export const PositionValue = styled.div`
  color: #000;
  font-weight: 800;
  padding-top: 10px;
  span {
    font-weight: 100;
  }
`;
export const Diver = styled.div`
  border-bottom: 1px solid #888;
`;
export const FurnitureBoxTitle = styled.div`
  color: #000;
  padding-bottom: 10px;
  font-size: 11px;
`;

export const ClearFix = styled.div`
  clear: both;
  height: 0px;
`;
export const Image = styled.div`
  text-align: center;
  div {
    width: 66px;
    height: 70px;
  }
  img {
    width: 66px;
    height: 70px;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s;
  }
  :hover {
    img {
      width: 70px;
      height: 75px;
    }
  }
`;
export const SelectedWrapper = styled.div`
  position: absolute;
  left: 101px;
  width: 180px;
  background: #fff;
  font-size: 12px;
  box-shadow: 3px 1px 3px 0px rgba(0, 0, 0, 0.2),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`;

export const LogoBox = styled.div`
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

export const SelectedBox = styled.button`
  cursor: pointer;
  position: relative;
  margin: 0px 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px 20px;
  outline: 0;
  background-color: ${BG_COLOR_1};
  border: none;
  :hover {
    background-color: ${BG_COLOR_HOVER};
  }
  :active {
    background-color: ${BG_COLOR_HOVER};
  }
  // :focus{
  //   box-shadow: 0px 0px 8px 2px ${SECONDARY_PURPLE_COLOR};
  // }
`;

export const SaveBox = styled.button`
  cursor: pointer;
  position: relative;
  margin: 0px 20px;
  border: 3px solid ${SECONDARY_PURPLE_COLOR};
  border-radius: 5px;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  background-color: ${SECONDARY_PURPLE_COLOR};
  outline: 0;
  :hover {
    background-color: ${SHADE_LIGHT_PURPLE_COLOR};
    border-color: ${SHADE_LIGHT_PURPLE_COLOR};
  }
  :active {
    background-color: ${SHADE_DARK_PURPLE_COLOR};
    border-color: ${SHADE_DARK_PURPLE_COLOR};
  }
  // :focus{
  //   box-shadow: 0px 0px 8px 2px ${SECONDARY_PURPLE_COLOR};
  // }
`;

export const EditBox = styled.div`
  cursor: pointer;
  :hover {
    img {
      transition: 0.3s;
      color: rgba(0, 0, 0, 0.87);
      padding: 0;
      font-size: 1.4rem;
      box-sizing: border-box;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.39),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
      font-family: Muli, Roboto, 'Helvetica', Arial, Open Sans;
      font-weight: 600;
      line-height: 1.75;
      text-transform: uppercase;
    }
  }
  :active {
    img {
      box-shadow: none !important;
    }
  }
`;
export const InputStyle = styled.div`
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  transition: padding-left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-radius: 4px;
`;
