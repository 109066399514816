import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../shared-style';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

const STYLE = {
  // borderTop: '1px solid #222',
  // borderBottom: '2px solid #2f2f2f',
  // paddingBottom: '5px',
  // marginTop: '5px',
  userSelect: 'none',
  width: '100%'
};
const STYLE_TITLE1 = {
  fontSize: '12px',
  padding: '8px 15px',
  backgroundColor: '#3e3e3f',
  margin: '0px',
  cursor: 'pointer',
  display: 'flex',
  height: '90px',
  justifyContent: 'start',
  alignItems: 'center'
};
const STYLE_TITLE2 = {
  fontSize: '12px',
  padding: '8px 15px',
  backgroundColor: '#2f2f2f',
  margin: '0px',
  cursor: 'pointer',
  display: 'flex',
  height: '30px',
  justifyContent: 'start'
  // alignItems: 'center'
};

const STYLE_CONTENT1 = {
  fontSize: '11px',
  color: 'rgba(222, 222, 222, 1)',
  padding: '0px',
  backgroundColor: '#28292d'
};

const STYLE_CONTENT2 = {
  fontSize: '11px',
  color: 'rgba(222, 222, 222, 1)',
  padding: '0px'
  // backgroundColor: '#28292d',
};
const STYLE_ARROW = {
  float: 'right'
};

export default class ToolbarPanel extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      lastActiveName:
        props.lastActiveName === undefined ? props.lastActiveName : '',
      opened: props.hasOwnProperty('opened') ? props.opened : false,
      hover: false
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.lastActiveName !== undefined &&
      nextProps.name !== undefined
    ) {
      if (
        nextProps.lastActiveName !== '' &&
        nextProps.name !== nextProps.lastActiveName
      ) {
        nextState.opened = false;
      }
    }
  }

  toggleOpen() {
    if (this.state.opened === false) {
      this.props.onOpen();
    } else {
      this.props.onClose();
    }
    if (this.props.setLastActiveName !== undefined) {
      this.props.setLastActiveName(this.props.name);
    }
    this.setState({ opened: !this.state.opened });
  }
  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }
  render() {
    let { name, isUrl, url, headComponents, children } = this.props;
    let { opened, hover } = this.state;

    const STYLE_TITLE = isUrl ? STYLE_TITLE1 : STYLE_TITLE2;
    const STYLE_CONTENT = isUrl ? STYLE_CONTENT1 : STYLE_CONTENT2;

    name = name.charAt(0).toUpperCase() + name.substr(1);

    return (
      <div style={STYLE}>
        <h3
          style={{
            ...STYLE_TITLE,
            color:
              hover ||
              (this.props.idx != undefined &&
                this.props.idx === this.props.selSubOption)
                ? SharedStyle.SECONDARY_COLOR.main
                : '#fff'
          }}
          onMouseEnter={() => this.toggleHover()}
          onMouseLeave={() => this.toggleHover()}
          onClick={() => this.toggleOpen()}
        >
          {isUrl === true ? (
            <img name={name} src={url} width={50} height={50} />
          ) : (
            ''
          )}
          <span style={{ marginLeft: isUrl ? 23 : 0 }}> {name} </span>
          {headComponents}
        </h3>
        <div style={{ ...STYLE_CONTENT, display: opened ? 'block' : 'none' }}>
          {children}
        </div>
      </div>
    );
  }
}

ToolbarPanel.propTypes = {
  name: PropTypes.string.isRequired,
  headComponents: PropTypes.array,
  opened: PropTypes.bool
};
