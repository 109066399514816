import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, Button } from '../../components/style/export';
import PropertyStyle from './shared-property-style';

export default function PropertyToggle({
  value,
  onUpdate,
  configs,
  sourceElement,
  internalState,
  state
}) {
  let update = val => {
    if (configs.hook) {
      return configs
        .hook(val, sourceElement, internalState, state)
        .then(_val => {
          return onUpdate(_val);
        });
    }

    return onUpdate(val);
  };

  return (
    <table className="PropertyToggle" style={PropertyStyle.tableStyle}>
      <tbody>
        <tr>
          <td style={PropertyStyle.firstTdStyle}>
            <FormLabel>{configs.label}</FormLabel>
          </td>
          <td>
            <Button onClick={e => update(!value)} size="small">
              {configs.actionName}
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

PropertyToggle.propTypes = {
  value: PropTypes.any.isRequired,
  onUpdate: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired
};
