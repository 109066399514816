import React, { useState } from 'react';
import ToolbarPanel from '../../../../sidebar/toolbar-panel';
import * as S from '../../styles';

export const ApplianceCategory = ({
  setStep,
  action,
  pData,
  bFlag,
  props,
  doorExpand,
  search,
  onSearch,
  mode
}) => {
  let result = [];
  const [lastActiveName, setLastActiveName] = useState('');

  for (let i = 0; i < pData.length; i++) {
    let temp = [];
    for (let j = 0; j < pData[i].items.length; j++) {
      if (pData[i].items[j].type !== 'cabinet') {
        var title = pData[i].items[j].name;
        title = title.charAt(0).toUpperCase() + title.substr(1);

        temp.push(
          <S.ProductsRow
            key={i.toString() + '-' + j.toString()}
            style={{ width: '100%', padding: '8px 10px 8px' }}
          >
            <a
              onClick={() => {
                action(pData[i].items[j].name);
              }}
            >
              <span>{title} </span>
            </a>
          </S.ProductsRow>
        );
      }
    }
    result.push(
      <S.ItemWrapper key={i.toString()}>
        <ToolbarPanel
          lastActiveName={lastActiveName}
          setLastActiveName={setLastActiveName}
          onClose={() => {
            action(-1);
          }}
          onOpen={() => {
            action(pData[i].name);
          }}
          isUrl={true}
          key={i.toString()}
          name={pData[i].name}
          url={pData[i].thumbnail}
        >
          {temp}
        </ToolbarPanel>
      </S.ItemWrapper>
    );
  }
  let miHeight = 0;
  let nonHeight = 495 - miHeight + 'px';
  let height = window.innerHeight * 0.6 - miHeight + 'px';
  return (
    <S.CategoryContentWrapper
      style={{ height: doorExpand ? height : nonHeight }}
    >
      {result}
    </S.CategoryContentWrapper>
  );
};
