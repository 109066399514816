import React, { useEffect, useRef } from 'react';
import { ReactSVGPanZoom } from 'react-svg-pan-zoom';
import State from '../../../../viewer2d/state';
import { getSignedUrl } from '../../../../../utils/helper';

const Viewer2DDownLoad = ({ state, catalog, width, height }) => {
  const Viewer = useRef(null);

  useEffect(() => {
    // move viewer point to center
    let selectedLayer = state.scene.layers.get(state.scene.selectedLayer);
    const vertices = selectedLayer.vertices;

    if (vertices.isEmpty()) {
      return;
    }

    let bottomX = 0,
      bottomY = 100000,
      topX = 100000,
      topY = 0;

    vertices.forEach(vertex => {
      if (bottomX < vertex.x) bottomX = vertex.x;
      if (bottomY > vertex.y) bottomY = vertex.y;
      if (topX > vertex.x) topX = vertex.x;
      if (topY < vertex.y) topY = vertex.y;
    });

    let width = bottomX - topX;
    let height = topY - bottomY;
    let scale = Math.min(786 / width, 700 / height);
    let moveX = topX + width / 2;
    let moveY = 2000 - (bottomY + height / 2);

    Viewer.current.setPointOnViewerCenter(moveX, moveY, scale * 0.62);
  }, []);

  useEffect(() => {
    setTimeout(() => saveSVGScreenshotToFile(), 5000);
  });

  const saveSVGScreenshotToFile = async () => {
    // First of all I need the svg content of the viewer
    let svgElements1 = document.getElementById('viewer2d_download');

    if (svgElements1 !== null) {
      let svgElements = svgElements1.getElementsByTagName('svg');

      // I get the element with max width (which is the viewer)
      let maxWidthSVGElement = svgElements[0];
      // let maxWidthSVGElement = document.getElementById("viewer2d_download");
      for (let i = 1; i < svgElements.length; i++) {
        if (
          svgElements[i].width.baseVal.value >
          maxWidthSVGElement.width.baseVal.value
        ) {
          maxWidthSVGElement = svgElements[i];
        }
      }
      // Begin: Change Image tags' href to local data src
      let images = maxWidthSVGElement.getElementsByTagName('image');

      let readBlob = b => {
        return new Promise(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            res(reader.result);
          };
          reader.onerror = () => {
            res(b);
          };
          reader.readAsDataURL(b);
        });
      };

      try {
        for (let image of images) {
          const response = await fetch(
            getSignedUrl(image.getAttribute('href'))
          );
          const blob = await response.blob();
          const content = await readBlob(blob);
          image.setAttribute('href', content);
        }
      } catch (e) {}
      // End: Change Image tags' href to local data src

      let serializer = new XMLSerializer();

      let img = new Image();

      // I create the new canvas to draw
      let canvas42DPlain = document.createElement('canvas');
      // let canvas = document.getElementById('canvas_crop_image');
      let ctx = canvas42DPlain.getContext('2d');

      // Set width and height for the new canvas
      let heightAtt = document.createAttribute('height');
      heightAtt.value = maxWidthSVGElement.height.baseVal.value;
      canvas42DPlain.setAttributeNode(heightAtt);

      let widthAtt = document.createAttribute('width');
      widthAtt.value = maxWidthSVGElement.width.baseVal.value;
      canvas42DPlain.setAttributeNode(widthAtt);

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas42DPlain.width, canvas42DPlain.height);

      img.crossOrigin = 'anonymous';
      img.src = `data:image/svg+xml;base64,${window.btoa(
        serializer.serializeToString(maxWidthSVGElement)
      )}`;

      img.onload = () => {
        ctx.globalAlpha = 1;
        ctx.drawImage(
          img,
          0,
          0,
          maxWidthSVGElement.width.baseVal.value,
          maxWidthSVGElement.height.baseVal.value
        );

        window.pdf2DPlain = canvas42DPlain.toDataURL();
      };
    } else {
      window.pdf2DPlain = '';
    }
  };

  return (
    <div style={{}} id="viewer2d_download">
      <ReactSVGPanZoom
        width={width}
        height={height}
        miniaturePosition="none"
        toolbarPosition="none"
        ref={Viewer}
        detectWheel={false}
        detectAutoPan={false}
      >
        <svg width={width} height={height}>
          <defs>
            <pattern
              id="diagonalFill"
              patternUnits="userSpaceOnUse"
              width="4"
              height="4"
              fill="#FFF"
            >
              <rect x="0" y="0" width="4" height="4" fill="#FFF" />
              <path
                d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                style={{ stroke: '#8E9BA2', strokeWidth: 1 }}
              />
            </pattern>
          </defs>
          <g>
            <State state={state} catalog={catalog} />
          </g>
        </svg>
      </ReactSVGPanZoom>
    </div>
  );
};

export default Viewer2DDownLoad;
