import React, { useEffect, useState } from 'react';
import DownloadSummaryTemp from '../downloadSummaryTemp';
import * as constants from '../../../../../constants';
import {
  MODE_BACK_ELEVATION_VIEW,
  MODE_FRONT_ELEVATION_VIEW,
  MODE_LEFT_ELEVATION_VIEW,
  MODE_RIGHT_ELEVATION_VIEW
} from '../../../../../constants';
import { almostEqual } from '../../../../../utils/geometry';
import ViewerElevationDownLoad from './viewerElevationDownload';

const ShowElevationView = ({ props, mode }) => {
  let newState = props.state.set('mode', mode);
  const [hasWall, setHasWall] = useState(true);

  useEffect(() => {
    let layer = props.state.scene.getIn([
      'layers',
      props.state.scene.selectedLayer
    ]);
    if (!layer.lines.size) {
      // alert("You must add a least one wall");
      // return;
      setHasWall(false);
    } else {
      let directLines = [];
      layer.lines.toArray().forEach(line => {
        let vertex0 = layer.vertices.get(line.vertices.get(0));
        let vertex1 = layer.vertices.get(line.vertices.get(1));
        let { x: x1, y: y1 } = vertex0;
        let { x: x2, y: y2 } = vertex1;

        switch (newState.mode) {
          case MODE_FRONT_ELEVATION_VIEW:
            if (almostEqual(y1, y2) && x1 < x2) {
              directLines.push(line);
            }
            break;
          case MODE_BACK_ELEVATION_VIEW:
            if (almostEqual(y1, y2) && x1 > x2) {
              directLines.push(line);
            }
            break;
          case MODE_LEFT_ELEVATION_VIEW:
            if (almostEqual(x1, x2) && y1 < y2) {
              directLines.push(line);
            }
            break;
          case MODE_RIGHT_ELEVATION_VIEW:
            if (almostEqual(x1, x2) && y1 > y2) {
              directLines.push(line);
            }
            break;
          default:
            break;
        }
      });
      if (!directLines.length) {
        setHasWall(false);
      }
    }
  }, []);

  return (
    <DownloadSummaryTemp>
      <div style={{ marginBottom: '32px' }}>
        <div
          style={{
            marginTop: '20px',
            color: '#000F33',
            fontSize: '27px',
            fontFamily: 'Open Sans',
            fontWeight: '700',
            lineHeight: '45.9px',
            wordWrap: 'break-word'
          }}
        >
          {constants.ELEVATION_VIEW_TITLE[mode]}
        </div>
        <div
          style={{
            marginTop: 7,
            color: '#434D63',
            fontSize: '12px',
            fontFamily: 'Open Sans',
            fontWeight: '600',
            lineHeight: '15px',
            wordWrap: 'break-word'
          }}
        >
          {constants.TECHNICAL_VIEW}
        </div>
      </div>
      {hasWall ? (
        <div style={{ height: 412 }}>
          <ViewerElevationDownLoad state={newState} catalog={props.catalog} />
        </div>
      ) : (
        <div style={Style.noWall}>
          <h1>No {constants.ELEVATION_VIEW_KEYWORD[mode]} Wall</h1>
        </div>
      )}
    </DownloadSummaryTemp>
  );
};

const Style = {
  noWall: {
    width: '342px',
    height: '350px',
    backgroundColor: 'lightgrey',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '60px'
  }
};

export default ShowElevationView;
