import convert from 'convert-units';

export default function (
  itemID,
  type,
  long_name,
  name,
  sizeinfo,
  description,
  prototype,
  base,
  shape_svg,
  render2DItem,
  render3DItem,
  structure_json,
  layoutpos,
  is_corner,
  alti,
  obj_property,
  outlineSVGData
) {
  const _length = alti || 0;
  // const length = convert(_length).from("in").to("cm");
  const length = _length;

  let additional_props = [];
  if (type === 'cabinet' && long_name.toLowerCase().includes('filler')) {
    additional_props = {
      width: {
        label: 'Width',
        type: 'length-measure',
        defaultValue: {
          _length: sizeinfo.width,
          _unit: 'in',
          length: convert(sizeinfo.width).from('in').to('cm')
        }
      }
    };
  }
  if (
    type === 'appliance' ||
    type === 'furnishing' ||
    (type === 'cabinet' && long_name.toLowerCase().includes('panel'))
  ) {
    additional_props = {
      width: {
        label: 'Width',
        type: 'length-measure',
        defaultValue: {
          _length: sizeinfo.width,
          _unit: 'in',
          length: convert(sizeinfo.width).from('in').to('cm')
        }
      },
      depth: {
        label: 'Depth',
        type: 'length-measure',
        defaultValue: {
          _length: sizeinfo.depth,
          _unit: 'in',
          length: convert(sizeinfo.depth).from('in').to('cm')
        }
      },
      height: {
        label: 'Height',
        type: 'length-measure',
        defaultValue: {
          _length: sizeinfo.height,
          _unit: 'in',
          length: convert(sizeinfo.height).from('in').to('cm')
        }
      }
    };
  }

  let retVal = {
    itemID: itemID,
    name: name,
    prototype: prototype,
    long_name: long_name,
    type: type,
    info: {
      title: name,
      outline: outlineSVGData,
      tag: ['furnishing', 'electronics'],
      description: description,
      image: base,
      door: obj_property.door_style,
      doorcategory: obj_property.door_category,
      has_single_door: obj_property.has_single_door,
      sizeinfo,
      layoutpos,
      is_corner,
      shape_svg
    },

    properties: {
      altitude: {
        label: 'Distance from floor',
        type: 'length-measure',
        defaultValue: {
          _length: _length,
          _unit: 'in',
          length: length
        }
      },
      resize_and_scaling: {
        label: 'Resize & Scaling',
        id: 'resize_and_scaling',
        description: 'Add preferred dimensions.',
        type: 'checkbox',
        defaultValue: false,
        values: {
          none: false,
          yes: true
        }
      },
      ...additional_props,
      flip_doorhandle: {
        label: 'Flip Door Handle',
        description: 'Viewable in 3D mode.',
        type: 'checkbox',
        defaultValue: false,
        values: {
          none: false,
          yes: true
        }
      },
      open_doors: {
        label: 'Open doors',
        description: 'Viewable in 3D mode.',
        type: 'checkbox',
        defaultValue: false,
        values: {
          none: false,
          yes: true
        }
      }
    },

    img: base,

    obj: obj_property,

    render2D: function (element, layer, scene) {
      return render2DItem.call(
        this,
        element,
        layer,
        scene,
        sizeinfo,
        layoutpos,
        is_corner,
        shape_svg
      );
    },

    render3D: function (element, layer, scene) {
      return render3DItem(element, layer, scene, sizeinfo, structure_json);
    }
  };
  return retVal;
}
