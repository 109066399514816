import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layer, Grids } from './export';
import { searchForSkuValue } from './utils';

export default class Scene extends Component {
  async componentWillReceiveProps(nextProps) {
    // console.log("componentDidMount", this.props.scene.hashCode(), nextProps.scene.hashCode());
    if (this.props.scene.hashCode() !== nextProps.scene.hashCode()) {
      let { scene, catalog } = nextProps;
      let { height, layers } = scene;
      let selectedLayer = layers.get(scene.selectedLayer);

      var msg = '';
      var isNotFoundInElements = false;
      var itemType = '';
      selectedLayer.items.valueSeq().forEach(item => {
        if (!catalog.elements.hasOwnProperty(item.type)) {
          itemType = item.type;
          isNotFoundInElements = true;
        }
      });
      // if(isNotFoundInElements){
      //   const deepSearchResult = await searchForSkuValue(catalog, selectedLayer, itemType)
      //   if(!deepSearchResult.isAvailable){
      //     msg += (`Element ${itemType} does not exist in catalog.\n`);
      //   }
      // }

      // if(msg !== '') {
      //   msg += `Do you want to initialize?`;
      //   confirm(msg)
      //   ? this.context.projectActions.newProject()
      //   : null
      // }
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.scene.hashCode() !== nextProps.scene.hashCode();
  }

  render() {
    // console.log("scene", this);
    let { scene, catalog } = this.props;
    let { height, layers } = scene;
    let selectedLayer = layers.get(scene.selectedLayer);

    return (
      <g>
        <Grids scene={scene} />

        <g style={{ pointerEvents: 'none' }}>
          {layers
            .entrySeq()
            .filter(
              ([layerID, layer]) =>
                layerID !== scene.selectedLayer && layer.visible
            )
            .map(([layerID, layer]) => (
              <Layer
                key={layerID}
                layer={layer}
                scene={scene}
                catalog={catalog}
                mode={this.props.state.mode}
              />
            ))}
        </g>

        <Layer
          key={selectedLayer.id}
          layer={selectedLayer}
          scene={scene}
          catalog={catalog}
          mode={this.props.state.mode}
        />
      </g>
    );
  }
}

Scene.propTypes = {
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};

Scene.contextTypes = {
  projectActions: PropTypes.object.isRequired
};
