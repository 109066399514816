import React from 'react';
import * as Three from 'three';
import { loadGLTF, scaleObject } from '../../utils/load-obj';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import { OBJTYPE_MESH } from '../../../../../src/constants';

const hdrUrls = ['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'];
let textureCube = new HDRCubeTextureLoader()
  .setPath('/catalog/envMap/')
  .load(hdrUrls, function () {
    textureCube.magFilter = Three.LinearFilter;
    textureCube.needsUpdate = true;
  });

let cached3DWindow = null;

export default {
  name: 'Double Hung Window',
  prototype: 'holes',

  info: {
    title: 'Double Hung',
    tag: ['window'],
    description: 'Double Hung Window',
    image: '/assets/img/svg/window/Double_hung.svg',
    url: '/assets/gltf/window_double_hung.gltf'
  },

  properties: {
    width: {
      label: 'Width',
      type: 'length-measure',
      defaultValue: {
        length: 86.36
      }
    },
    height: {
      label: 'Height',
      type: 'length-measure',
      defaultValue: {
        length: 100
      }
    },
    altitude: {
      label: 'Distance from floor',
      type: 'length-measure',
      defaultValue: {
        length: 121.92
      }
    },
    thickness: {
      label: 'Thickness',
      type: 'length-measure',
      defaultValue: {
        length: 6
      }
    }
    // flip: {
    //   label: 'Flip',
    //   type: 'checkbox',
    //   defaultValue: 'false',
    // },
  },

  render2D: function (element, layer, scene) {
    const STYLE_HOLE_BASE = {
      stroke: 'rgb(73, 73, 73)',
      strokeWidth: '1px',
      strokeDasharray: '9,5',
      fill: 'rgb(73, 73, 73)'
    };
    const STYLE_HOLE_SELECTED = {
      stroke: '#0096fd',
      strokeWidth: '1px',
      strokeDasharray: '9,5',
      fill: '#0096fd',
      cursor: 'move'
    };
    const STYLE_ARC_BASE = {
      stroke: 'rgb(73, 73, 73)',
      strokeWidth: '1px',
      strokeDasharray: '9,5',
      fill: 'none'
    };
    const STYLE_ARC_SELECTED = {
      stroke: '#0096fd',
      strokeWidth: '1px',
      strokeDasharray: '9,5',
      fill: 'none',
      cursor: 'move'
    };
    const STYLE_STR0 = {
      fill: 'rgb(185, 185, 185)',
      stroke: '#494949',
      strokeWidth: '1',
      strokeMiterlimit: '2.61313'
    };
    const STYLE_STR0_S = {
      fill: 'rgb(185, 185, 185)',
      stroke: '#0096fd',
      strokeWidth: '1',
      strokeMiterlimit: '2.61313'
    };
    const STYLE_STR1 = {
      fill: 'none',
      stroke: '#494949',
      strokeWidth: '1',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: '2.61313',
      strokeDasharray: '23.860041 11.930021'
    };
    const STYLE_FILL2 = { fill: '#1183B7' };
    const STYLE_FNT0 = {
      fill: 'white',
      fontWeight: 'normal',
      fontSize: '12px',
      fontFamily: 'Proxima Nova Rg'
    };
    //let line = layer.lines.get(hole.line);
    //let epsilon = line.properties.get('thickness') / 2;

    let EPSILON = 3;
    let lineWidth = element.properties.get('thickness').get('length');
    // let flip = element.properties.get('flip');
    let holeWidth = element.properties.get('width').get('length');
    let holePath = `M${0} ${-EPSILON}  L${holeWidth} ${-EPSILON}  L${holeWidth} ${EPSILON}  L${0} ${EPSILON}  z`;
    let arcPath = `M${0},${0}  A${holeWidth},${holeWidth} 0 0,1 ${holeWidth},${holeWidth}`;
    let holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
    let arcStyle = element.selected ? STYLE_ARC_SELECTED : STYLE_ARC_BASE;
    let rectStyle = element.selected ? STYLE_STR0_S : STYLE_STR0;
    let length = element.properties.get('width').get('length');
    // if(flip == false) {
    return (
      <g transform={`translate(${-length / 2}, 0)`}>
        {/* <path d={arcPath} style={arcStyle} transform={`translate(${0},${holeWidth}) scale(${1},${-1}) rotate(${0})`}/>
          <line x1={0} y1={holeWidth - EPSILON} x2={0} y2={0 - EPSILON} style={holeStyle} transform={`scale(${-1},${1})`}/> */}
        <rect
          style={rectStyle}
          x="0"
          y={-lineWidth / 2}
          width={holeWidth}
          height={lineWidth}
        />
        {/* <rect style={STYLE_FILL2} x={holeWidth - 16} y="-8.5" width="18" height="17" rx="1.27" ry="1.27"/> */}
        {/* <text x={holeWidth - 15} y="4.5" transform={`scale(1,-1)`} style={STYLE_FNT0}>W3</text> */}
      </g>
    );
    // }
    // else{
    //   return (
    //     <g transform={`translate(${-length / 2}, 0)`}>
    //       {/* <path d={arcPath} style={arcStyle} transform={`translate(${0},${-holeWidth}) scale(${1},${1}) rotate(${0})`}/>
    //       <line x1={0} y1={-        holeWidth - EPSILON} x2={0} y2={0 - EPSILON} style={holeStyle} transform={`scale(${1},${1})`}/> */}
    //       <rect style={rectStyle} x="0" y={-lineWidth/2} width={holeWidth} height={lineWidth}/>
    //       <rect style={STYLE_FILL2} x={holeWidth - 16} y="-8.5" width="18" height="17" rx="1.27" ry="1.27"/>
    //       <text x={holeWidth - 15} y="4.5"  style={STYLE_FNT0}>W3</text>
    //     </g>
    //   )
    // }
  },

  render3D: function (element, layer, scene) {
    let onLoadItem = object => {
      let venetian = new Three.Object3D();
      let width = element.properties.get('width').get('length');
      let height = element.properties.get('height').get('length');
      let thickness = element.properties.get('thickness').get('length');

      scaleObject(object, [90, 100, 6], [width, height, thickness]);

      if (element.selected) {
        let box = new Three.BoxHelper(object, 0x99c3fb);
        box.material.linewidth = 2;
        box.material.depthTest = false;
        box.renderOrder = 1000;
        object.add(box);
      }

      //let normalMap = require('./texture.png');
      //let t = new Three.TextureLoader().load(normalMap);
      const examplecolor = new Three.Color(0xffffff);
      let mat2 = new Three.MeshStandardMaterial({
        color: examplecolor,
        metalness: 0.1,
        roughness: 0.9
      });
      //mat2.map = t;
      // mat2.envMap = textureCube;

      for (let j = 0; j < object.children.length; j++) {
        if (object.children[j].type === OBJTYPE_MESH) {
          object.children[j].material = mat2;
          object.children[j].receiveShadow = true;
        }
      }

      venetian.add(object);
      //venetian.add(createTenda());

      return object;
    };

    if (cached3DWindow) {
      return Promise.resolve(onLoadItem(cached3DWindow.clone()));
    }
    return loadGLTF(element.url).then(object => {
      cached3DWindow = object;
      return onLoadItem(cached3DWindow.clone());
    });

    function createTenda() {
      var venetian = new Three.Object3D();

      //colors
      var white = new Three.MeshLambertMaterial({
        color: 0xffffff,
        opacity: 0.5,
        transparent: true
      });
      var grey = new Three.MeshLambertMaterial({ color: 0xcccccc });

      var roundedRectShape = new Three.Shape();

      var x = 0;
      var y = 0;
      var width = 1;
      var height = 18;
      var radius = 0.25;

      roundedRectShape.moveTo(x, y + radius);
      roundedRectShape.lineTo(x, y + height - radius);
      roundedRectShape.quadraticCurveTo(x, y + height, x + radius, y + height);
      roundedRectShape.lineTo(x + width - radius, y + height);
      roundedRectShape.quadraticCurveTo(
        x + width,
        y + height,
        x + width,
        y + height - radius
      );
      roundedRectShape.lineTo(x + width, y + radius);
      roundedRectShape.quadraticCurveTo(x + width, y, x + width - radius, y);
      roundedRectShape.lineTo(x + radius, y);
      roundedRectShape.quadraticCurveTo(x, y, x, y + radius);

      var holePath1 = new Three.Path();
      holePath1.moveTo(0.5, 0.6);
      holePath1.arc(0, 0.7, 0.15, 0, Math.PI, false);
      holePath1.arc(0.15, -0.09, 0.15, Math.PI, 0, false);
      roundedRectShape.holes.push(holePath1);

      var holePath2 = new Three.Path();
      holePath2.moveTo(0.5, 4.6);
      holePath2.arc(0, 0.7, 0.15, 0, Math.PI, false);
      holePath2.arc(0.15, -0.09, 0.15, Math.PI, 0, false);
      roundedRectShape.holes.push(holePath2);

      var holePath3 = new Three.Path();
      holePath3.moveTo(0.5, 8.6);
      holePath3.arc(0, 0.7, 0.15, 0, Math.PI, false);
      holePath3.arc(0.15, -0.09, 0.15, Math.PI, 0, false);
      roundedRectShape.holes.push(holePath3);

      var holePath4 = new Three.Path();
      holePath4.moveTo(0.5, 12.6);
      holePath4.arc(0, 0.7, 0.15, 0, Math.PI, false);
      holePath4.arc(0.15, -0.09, 0.15, Math.PI, 0, false);
      roundedRectShape.holes.push(holePath4);

      var holePath5 = new Three.Path();
      holePath5.moveTo(0.5, 16.6);
      holePath5.arc(0, 0.7, 0.15, 0, Math.PI, false);
      holePath5.arc(0.15, -0.09, 0.15, Math.PI, 0, false);
      roundedRectShape.holes.push(holePath5);

      var extrudeSettings = {
        steps: 1,
        depth: 0.2,
        bevelEnabled: false,
        bevelThickness: 0.4,
        bevelSize: 0.4,
        bevelSegments: 1
      };

      for (var i = 0; i < 25; i += 0.7) {
        var geometry = new Three.ExtrudeGeometry(
          roundedRectShape,
          extrudeSettings
        );
        var mesh = new Three.Mesh(geometry, grey);
        mesh.position.set(0, i, 0.86);
        mesh.rotation.z += Math.PI / 2;
        mesh.rotation.x += -Math.PI / 2;
        venetian.add(mesh);
      }

      for (var j = -1.25; j > -19; j += -4) {
        var geometry1 = new Three.CylinderGeometry(0.105, 0.105, 26, 32);
        var tubo = new Three.Mesh(geometry1, white);
        tubo.position.set(j, 12.5, 0.35);
        venetian.add(tubo);
      }

      var roundedRectShape2 = new Three.Shape();

      var x1 = 0;
      var y1 = 0;
      var width1 = 1;
      var height1 = 18;
      var radius1 = 0.25;

      roundedRectShape2.moveTo(x1, y1 + radius1);
      roundedRectShape2.lineTo(x1, y1 + height1 - radius1);
      roundedRectShape2.quadraticCurveTo(
        x1,
        y1 + height1,
        x1 + radius1,
        y1 + height1
      );
      roundedRectShape2.lineTo(x1 + width1 - radius1, y1 + height1);
      roundedRectShape2.quadraticCurveTo(
        x1 + width1,
        y1 + height1,
        x1 + width1,
        y1 + height1 - radius1
      );
      roundedRectShape2.lineTo(x1 + width1, y1 + radius1);
      roundedRectShape2.quadraticCurveTo(
        x1 + width1,
        y1,
        x1 + width1 - radius1,
        y1
      );
      roundedRectShape2.lineTo(x1 + radius1, y1);
      roundedRectShape2.quadraticCurveTo(x1, y1, x1, y1 + radius1);

      var extrudeSettings2 = {
        steps: 1,
        depth: 0.4,
        bevelEnabled: false,
        bevelThickness: 0.4,
        bevelSize: 0.4,
        bevelSegments: 1
      };

      for (var k = -0.5; k < 27; k += 26) {
        var geometry2 = new Three.ExtrudeGeometry(
          roundedRectShape2,
          extrudeSettings2
        );
        var mesh2 = new Three.Mesh(geometry2, grey);
        mesh2.position.set(0, k, 1);
        mesh2.rotation.z += Math.PI / 2;
        mesh2.rotation.x += -Math.PI / 2;
        venetian.add(mesh2);
      }

      let valueObject = new Three.Box3().setFromObject(venetian);

      let deltaX = Math.abs(valueObject.max.x - valueObject.min.x);
      let deltaY = Math.abs(valueObject.max.y - valueObject.min.y);
      let deltaZ = Math.abs(valueObject.max.z - valueObject.min.z);

      venetian.position.x += width1 / 0.025;
      venetian.position.y += -height1 / 0.4;
      venetian.scale.set(
        (5.2 * width1) / deltaZ,
        (5.45 * height1) / deltaY,
        (2.5 * thickness) / deltaX
      );

      return venetian;
    }
  }
};
