import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Viewer3DDownLoad from './viewer3DDownLoad';
import { MODE_3D_VIEW } from '../../../../../constants';

const Show3DView = ({ props }) => {
  let newState = props.state.set('mode', MODE_3D_VIEW);

  const [hasWall, setHasWall] = useState(true);

  useEffect(() => {
    let layer = props.state.scene.getIn([
      'layers',
      props.state.scene.selectedLayer
    ]);
    !layer.lines.size && setHasWall(false);
    setTimeout(() => saveSVGScreenshotToFile(), 5000);
  });

  const saveSVGScreenshotToFile = async () => {
    let canvas2D3DPlain1 = document.getElementById('pdf3DPlain');
    let img = new Image();
    let img2 = new Image();

    if (canvas2D3DPlain1 !== null) {
      let canvas2D3DPlain = canvas2D3DPlain1.getElementsByTagName('canvas')[0];

      if (canvas2D3DPlain !== undefined) {
        window.pdf3DPlain = canvas2D3DPlain.toDataURL();
      }
    } else {
      window.pdf3DPlain = '';
    }
  };

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100%',
        backgroundColor: '#fff',
        marginBottom: '10px'
      }}
    >
      {hasWall ? (
        <div
          id="pdf3DPlain"
          style={{ transform: `scale(0.5, 0.5)`, width: 6, height: 2 }}
        >
          <Viewer3DDownLoad state={newState} width={798} height={700} />
          <div style={{ position: 'absolute', left: '670px', top: '38px' }}>
            <img
              id="logo3DPlain"
              style={{
                width: '88px',
                height: '88px',
                animation: 'spin 2s linear infinite'
              }}
              src={'/assets/img/loading/loading_1.svg'}
              alt="img"
            />
          </div>
        </div>
      ) : (
        <div style={styles.noWall}>
          <h1>No wall</h1>
        </div>
      )}
      <div
        style={{
          padding: hasWall ? '360px 30px 30px 30px' : '30px 30px 30px 30px'
        }}
      >
        <div style={{ alignItems: 'center', padding: '5px 0' }}>
          <div style={styles.headerInfo}>
            <span>
              Created for:
              <span
                style={{
                  color: '#565F77',
                  fontWeight: '700',
                  marginLeft: 5
                }}
              >
                {sessionStorage.getItem('firstName') +
                  ' ' +
                  sessionStorage.getItem('lastName')}
              </span>
            </span>
            <span>
              Date:{' '}
              <span
                style={{
                  color: '#565F77',
                  fontWeight: '700',
                  marginLeft: 5
                }}
              >
                {moment(new Date()).format('DD-MM-YYYY')}
              </span>
            </span>
            <span>
              ID:{' '}
              <span
                style={{ color: '#565F77', fontWeight: '700', marginLeft: 5 }}
              >
                #{sessionStorage.getItem('projectId')}
              </span>
            </span>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '11px' }}
          >
            <div style={{ flex: 5 }}>
              <div
                style={{
                  color: '#000F33',
                  fontSize: '27px',
                  fontFamily: 'Open Sans',
                  fontWeight: '700',
                  lineHeight: '45.9px',
                  wordWrap: 'break-word',
                  margin: '7px 0'
                }}
              >
                {sessionStorage.getItem('projectTitle')}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <span
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Open Sans',
                    fontWeight: '600',
                    lineHeight: '15px',
                    wordWrap: 'break-word',
                    fontSize: '11px',
                    color: '#434D63'
                  }}
                >
                  <b>
                    Summary ID: #{sessionStorage.getItem('projectId')}
                    {moment(new Date()).format('DD-MM-YYYY')}
                  </b>
                </span>
                <div
                  style={{ width: 1, height: 14, backgroundColor: '#8791AB' }}
                ></div>
                <span
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Open Sans',
                    fontWeight: '600',
                    lineHeight: '15px',
                    wordWrap: 'break-word',
                    fontSize: '11px',
                    color: '#434D63'
                  }}
                >
                  <b>Available: {moment(new Date()).format('DD-MM-YYYY')}</b>
                </span>
              </div>
            </div>
            <div
              style={{ flex: 2, display: 'flex', justifyContent: 'flex-end' }}
            >
              <div style={{ height: 70, width: 70 }}>
                <img
                  src="/assets/rtastore_logo.png"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: '30px 0',
            textAlign: 'justify',
            marginBottom: '40px',
            fontSize: '10px',
            color: '#565F77'
          }}
        >
          <span
            style={{
              // fontSize: '8px',
              fontFamily: 'Open Sans',
              fontWeight: '700',
              lineHeight: '13px',
              wordWrap: 'break-word'
            }}
          >
            Disclaimer:
          </span>{' '}
          <span
            style={{
              // fontSize: '8px',
              fontFamily: 'Open Sans',
              fontWeight: '400',
              lineHeight: '13px',
              wordWrap: 'break-word'
            }}
          >
            DIY Design Space not take responsibility for the accuracy of
            measurements or furniture arrangements. The prices displayed here
            apply to items in stock at the time of the offer's issuance. All
            delivery, assembly, and installation services required are billed
            are billed separately and are not included in the price. While we
            strive to ensure the accuracy of the information in this program, we
            apologize for any errors that may occur.
          </span>
        </div>
        <div style={styles.footer}>
          <span>© DIY.DS v1.0/2024</span>
          <span>DIY Design Space® 2024 All rights reserved</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  headerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '30px',
    fontFamily: 'Open Sans',
    color: '#565F77',
    borderBottom: '1px solid #EAEDFF',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '13px',
    wordWrap: 'break-word'
  },
  footer: {
    padding: '15px 0',
    borderTop: '1px solid #EAEDFF',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Inter',
    color: '#565F77',
    fontSize: '9px',
    lineHeight: '11.2px',
    wordWrap: 'break-word'
  },
  noWall: {
    width: '400px',
    height: '350px',
    backgroundColor: 'lightgrey',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default Show3DView;
