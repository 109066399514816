import React from 'react';
import DownloadSummaryTemp from './downloadSummaryTemp';
import * as constants from '../../../../constants';

const ShowCabinetInfo = ({ productDATA, totalPrice }) => {
  return (
    <DownloadSummaryTemp>
      <div style={{ marginBottom: '32px' }}>
        <div
          style={{
            marginTop: '20px',
            color: '#000F33',
            fontSize: '27px',
            fontFamily: 'Open Sans',
            fontWeight: '700',
            lineHeight: '45.9px',
            wordWrap: 'break-word'
          }}
        >
          {constants.LIST_PARTS}
        </div>
        <div
          style={{
            marginTop: 7,
            color: '#434D63',
            fontSize: '12px',
            fontFamily: 'Open Sans',
            fontWeight: '600',
            lineHeight: '15px',
            wordWrap: 'break-word'
          }}
        >
          {constants.LIST_QUANTITIES}
        </div>
      </div>
      <div style={{ marginTop: '30px', marginBottom: '30px' }}>
        <div style={styles.tHeader}>
          <div>{constants.PRODUCT}</div>
          <div>{constants.SUBTOTAL}</div>
        </div>
        <div>
          <div style={styles.tBody}>{productDATA}</div>
          <div style={styles.totalPrice}>
            <div>Total</div>
            <div>${totalPrice}</div>
          </div>
        </div>
      </div>
    </DownloadSummaryTemp>
  );
};

const styles = {
  tHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '25px',
    backgroundColor: '#FAFlAFF',
    padding: '0 4px 0 20px',
    color: '#565F77',
    fontSize: '12px',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    lineHeight: '12px',
    wordWrap: 'break-word'
  },
  tBody: {
    color: '#000F33',
    fontSize: '12px',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    lineHeight: '13px',
    wordWrap: 'break-word'
  },
  totalPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '25px',
    backgroundColor: '#FAFAFF',
    padding: '0 0 0 20px',
    color: '#000F33',
    marginTop: 12,
    fontSize: '12px',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    lineHeight: '12px',
    wordWrap: 'break-word'
  }
};

export default ShowCabinetInfo;
