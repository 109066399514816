import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '../../components/style/export';
import PropertyStyle from './shared-property-style';
import styled from 'styled-components';
import {
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_0
} from '../../constants';

const FlipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  justify-content: space-between;
`;

const FlipTitle = styled.span`
  margin-right: auto;
  color: ${SECONDARY_PURPLE_COLOR};
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`;
const FlipDescription = styled.span`
  margin-right: auto;
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;
const FlipInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlipToggle = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const FlipToggleIcon = styled.div`
  top: 0;
  position: absolute;
  ${'' /* transition-duration: .3s; */}
  img {
    font-size: 1.4rem;
    box-sizing: border-box;
    border-radius: 50%;
  }
`;

export default function PropertyCheckbox({
  value,
  onUpdate,
  configs,
  sourceElement,
  internalState,
  state
}) {
  let update = val => {
    if (configs.hook) {
      return configs
        .hook(val, sourceElement, internalState, state)
        .then(_val => {
          return onUpdate(_val);
        });
    }

    return onUpdate(val);
  };

  let activeStyle = value == 0 ? { left: 0 } : { right: 0 };

  return (
    <FlipWrapper>
      <FlipInfoWrapper>
        <FlipTitle>{configs.label}</FlipTitle>
        {configs.description && (
          <FlipDescription>{configs.description}</FlipDescription>
        )}
      </FlipInfoWrapper>
      <FlipToggle style={{ color: 'black' }} onClick={e => update(!value)}>
        <img
          src={`/assets/img/svg/bottombar/${
            value != 0 ? '2d3d_toggle_active.svg' : '2d3d_toggle.svg'
          }`}
          style={{ width: '55px', height: '30px' }}
        />
        <FlipToggleIcon style={{ ...activeStyle }}>
          <img
            src={`/assets/img/svg/bottombar/${
              value != 0 ? '2d3d_button_active.svg' : '2d3d_button.svg'
            }`}
            style={{ width: '30px', height: '30px' }}
          />
        </FlipToggleIcon>
      </FlipToggle>
    </FlipWrapper>
  );
}

PropertyCheckbox.propTypes = {
  value: PropTypes.any.isRequired,
  onUpdate: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired
};
