import React from 'react';
import PropTypes from 'prop-types';
import { Seq } from 'immutable';
import { FormLabel, FormSelect } from '../../components/style/export';
import PropertyStyle from './shared-property-style';
import styled from 'styled-components';
import { TEXT_COLOR_NEUTRAL_0, DEFAULT_FONT_FAMILY } from '../../constants';

const EnumWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
`;

const EnumTitle = styled.span`
  margin-right: auto;
  width: 110px;
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};

  @media screen and (min-width: 1024) {
    font-size: 11px;
  }
  @media screen and (max-width: 1024) {
    font-size: 11px;
  }
  @media screen and (min-width: 1366) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366) {
    font-size: 13px;
  }
  @media screen and (min-width: 1440) {
    font-size: 16px;
  }
  @media screen and (max-width: 1440) {
    font-size: 16px;
  }
  font-weight: 400;
  line-height: 15px;
  text-align: left;
`;

export default function PropertyEnum({
  value,
  onUpdate,
  configs,
  sourceElement,
  internalState,
  state
}) {
  let update = val => {
    if (configs.hook) {
      return configs
        .hook(val, sourceElement, internalState, state)
        .then(_val => {
          return onUpdate(_val);
        });
    }

    return onUpdate(val);
  };

  return (
    <EnumWrapper>
      <EnumTitle>{configs.label}</EnumTitle>
      <FormSelect value={value} onChange={event => update(event.target.value)}>
        {Seq(configs.values)
          .entrySeq()
          .map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
      </FormSelect>
    </EnumWrapper>
  );
}

PropertyEnum.propTypes = {
  value: PropTypes.any.isRequired,
  onUpdate: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired
};
