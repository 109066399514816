import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import {
  BG_COLOR_0,
  BG_COLOR_1,
  BG_COLOR_HOVER,
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR,
  SHADE_DARK_PURPLE_COLOR,
  SHADE_LIGHT_PURPLE_COLOR,
  TEXT_COLOR,
  TEXT_COLOR_NEUTRAL_0,
  TEXT_COLOR_NEUTRAL_1,
  TEXT_COLOR_NEUTRAL_3,
  TEXT_COLOR_NEUTRAL_4,
  TEXT_COLOR_NEUTRAL_5
} from '../../../constants';

// Common Styles

export const UnAvailableText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #696969;
  opacity: 0.8;
  top: 0;
  color: white;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  cursor: no-drop;
  user-select: none;
`;
export const IconButton = styled.button`
  color: #fff;
  font-size: 12px;
  border: 0px;
  border-radius: 3px;
  background: #000;
  border: 1px solid #000;
  padding: 4px 4px 1px 4px;
  cursor: pointer;
`;
export const ActiveIconButton = styled.button`
  color: #fff;
  font-size: 12px;
  border: 0px;
  border-radius: 3px;
  background: #0f8a61;
  border: 1px solid #0f8a61;
  padding: 4px 4px 1px 4px;
  cursor: pointer;
`;
// Main Styles
export const Wrapper = styled.div`
  font-size: 12px;
  box-shadow: 3px 1px 3px 0px rgba(0, 0, 0, 0.2),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`;
export const TitleBar = styled.div`
  width: 100%;
  height: 30px;
  background: #1183bb;
  padding: 0px 8px 0px 8px;
  clear: both;
  box-shadow: 3px 1px 3px 0px rgba(0, 0, 0, 0.2),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`;
export const Title = styled.div`
  float: left;
  line-height: 30px;
  color: #fff;
  font-size: 12px;
`;
export const TitleBarIconButton = styled.div`
  float: right;
  cursor: pointer;
  width: 100%;
  user-select: none;
`;
export const ContentWrapper = styled.div`
  clear: both;
`;
export const ContentObjectWrapper = styled.div`
  background: ${BG_COLOR_1};
  width: 340px;
  clear: both;
  display: flex;
  height: fit-content;
  flex-direction: column;
  border-radius: 15px;
  margin-left: 10px;
  padding: 20px;
  // padding-top: 60px;
  box-shadow: 3px 1px 3px 0px rgba(0, 0, 0, 0.2),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-height: calc(100vh - ${props => props.headerH + 80}px);
`;

export const SettingDialog = styled.div`
  right: 10px;
  display: none;
  background: ${BG_COLOR_1};
  width: 370px;
  clear: both;
  flex-direction: column;
  border-radius: 15px;
  padding: 20px;
  margin-left: 10px;
  box-shadow: 3px 1px 3px 0px rgba(0, 0, 0, 0.2),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  height: calc(100vh - ${props => props.headerH + 20}px);
`;

export const VideoModal = styled.div`
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  .modal-body {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    div {
      margin: auto;
      height: unset !important;
      border-radius: 10px;
      video {
        height: inherit !important;
      }
    }
  }
`;

export const HelpDialog = styled.div`
  right: 10px;
  display: flex;
  background: ${BG_COLOR_1};
  width: 460px;
  clear: both;
  flex-direction: column;
  border-radius: 15px;
  padding: 10px;
  padding-top: 60px;
  height: fit-content;
  user-select: none;
  font-family: ${DEFAULT_FONT_FAMILY};
`;

export const HCTitle = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-size: 26px;
  font-weight: bold;
  line-height: 36px;
  text-align: left;
  padding: 15px 10px;
  user-select: none;
`;

export const HCSubTitle = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  text-align: left;
  padding: 15px 10px 5px 15px;
  user-select: none;
`;

export const HCCardTitle = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-size: 19px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  padding: 15px 10px 5px 10px;
  user-select: none;
`;

export const Tab = styled.div`
  background-color: ${BG_COLOR_0};
  padding: 0;
  margin-left: 10px;
  height: fit-content;
  box-shadow: 3px 1px 3px 0px rgba(0, 0, 0, 0.2),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  width: fit-content;
  border-radius: 15px;
  position: relative;
`;

export const TopLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute !important;
`;

export const TabLine = styled.div`
  position: relative;
  display: flex;
  background-color: ${BG_COLOR_0};
  padding: 0 10px;
  border-radius: 15px 0;
  box-shadow: -1px 0 3px 1px rgba(0, 0, 0, 0.12) inset;
`;
export const ToolLine = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  height: fit-content;
  margin: auto;
`;

export const TabLabel = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  width: max-content;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  font-family: ${DEFAULT_FONT_FAMILY};
  line-height: 24px;
  padding: 5px 5px 7px 10px;
  margin: 3px 5px;
  justify-content: center;
  color: ${TEXT_COLOR_NEUTRAL_1};
  align-items: center;
  label {
    margin-right: 10px;
    cursor: pointer;
  }
  img {
    background-color: ${TEXT_COLOR_NEUTRAL_1};
  }
  &.selected {
    color: ${BG_COLOR_1};
    background-color: ${SECONDARY_PURPLE_COLOR};
    border: 3px solid ${SECONDARY_PURPLE_COLOR};
    border-radius: 5px;
    img {
      background-color: ${BG_COLOR_1};
    }
    :hover {
      background-color: ${SHADE_LIGHT_PURPLE_COLOR};
      border-color: ${SHADE_LIGHT_PURPLE_COLOR};
    }
    :active {
      background-color: ${SHADE_DARK_PURPLE_COLOR};
      border-color: ${SHADE_DARK_PURPLE_COLOR};
    }
  }
`;

export const TitleBarButton = styled.button`
  display: flex;
  cursor: pointor;
  align-items: center;
  color: ${SECONDARY_PURPLE_COLOR};
  outline: 0;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  padding: 5px 14px;
  border: 1px solid;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  line-height: 22px;
  text-align: center;
  background-color: ${BG_COLOR_1};
  :hover {
    background-color: ${BG_COLOR_0};
  }
  :active {
    background-color: ${BG_COLOR_0};
  }
`;

export const HCCardButton = styled.button`
  cursor: pointer;
  position: relative;
  border: 3px solid ${SECONDARY_PURPLE_COLOR};
  border-radius: 5px;
  height: 40px;
  width: 40px;
  padding: auto;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-right: 10px;
  background-color: ${SECONDARY_PURPLE_COLOR};
  color: ${BG_COLOR_1};
  font-size: 16px;
  font-weight: 400;
  outline: 0;
  :hover {
    background-color: ${SHADE_LIGHT_PURPLE_COLOR};
    border-color: ${SHADE_LIGHT_PURPLE_COLOR};
  }
  :active {
    background-color: ${SHADE_DARK_PURPLE_COLOR};
    border-color: ${SHADE_DARK_PURPLE_COLOR};
  }
`;

export const HCCollapse = styled.div`
  button {
    margin: 5px 15px;
    cursor: pointer;
    width: calc(100% - 30px);
    background-color: ${BG_COLOR_1};
    border: none;
    padding: 0;
    text-align: left;
    outline: none;
    font-size: 19px;
    img {
      transform: rotate(0deg);
      transition: transform 0.2s ease-in;
    }
  }
  .active {
    img {
      transform: rotate(180deg);
    }
  }
  span {
    font-family: ${DEFAULT_FONT_FAMILY};
  }
  .content {
    margin: 5px 15px;
    color: ${TEXT_COLOR_NEUTRAL_1};
    background-color: white;
    max-height: 0;
    overflow: hidden;
    font-size: 13px;
    transition: max-height 0.2s ease-out;
  }
`;

export const HCHr = styled.hr`
  border: 0;
  border-top: 2px solid ${BG_COLOR_HOVER};
  margin: 10px;
`;

export const HCLable = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_1};
  font-size: 13px;
  text-align: left;
  padding: 5px 10px;
  user-select: none;
`;

export const HCTextArea = styled.textarea`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  padding: 10px;
  resize: none;
  height: 140px;
  width: 100%;
  outline: 0;
  border-radius: 5px;
  :hover {
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
  :focus {
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
`;

export const HCSelect = styled.div`
  position: relative;
  border-radius: 5px;
  border: 2px solid ${TEXT_COLOR_NEUTRAL_3};
  margin-top: 20px;
  :hover {
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
  :focus {
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
  div {
    position: absolute;
    top: -8px;
    left: 10px;
    font-size: 13px;
    color: ${TEXT_COLOR_NEUTRAL_1};
    font-weigt: 400;
    padding: 0 3px;
    background-color: ${BG_COLOR_1};
  }
  select {
    width: -webkit-fill-available;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin: 0 10px;
    padding: 10px 0;
    outline: 0;
    border: none;
    option {
      color: black;
      :first-child {
        color: grey;
      }
    }
    option[disabled]:first-child {
      display: none;
    }
    .empty {
      color: grey;
    }
  }
`;

export const HCUpload = styled.div`
  margin-top: 20px;
  user-select: none;
  display: block;
  width: 100%;
  .upload {
    border: 2px dashed ${TEXT_COLOR_NEUTRAL_1};
    outline: 0;
    border-radius: 5px;
    label {
      width: 100%;
      cursor: pointer;
      text-align: center;
      p {
        margin: 10px;
        color: ${TEXT_COLOR_NEUTRAL_1};
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
      input {
        width: 0 !important;
        height: 0 !important;
        overflow: hidden !important;
        display: none;
      }
    }
  }
  .upload-label {
    text-align: center;
    margin-top: 10px;
    font-size: 13px;
    color: ${TEXT_COLOR_NEUTRAL_1};
    font-weigt: 400;
  }
`;

export const HCTag = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_1};
  font-size: 10px;
  text-align: left;
  padding: 5px 10px;
  user-select: none;
`;

export const HCCard = styled.div`
  display: block;
  margin: 0px 10px 5px 10px;
`;

export const Video = styled.div`
  height: 150px;
  margin: 15px 10px;
  margin-bottom: 5px;
  position: relative;
`;

export const VideoText = styled.div`
  position: absolute;
  left: 20px;
  top: 10px;
  color: white;
`;

export const VideoTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  user-select: none;
`;

export const VideoTag = styled.div`
  font-size: 10px;
  line-height: 16px;
  user-select: none;
`;

export const VideoButton = styled.div`
  position: absolute;
  left: calc(50% - 35px);
  top: calc(50% - 35px);
  width: 70px;
  height: 70px;
  cursor: pointer;
`;

export const SearchBox = styled.div`
  position: relative;
  margin: 15px 10px;
`;

export const SearchInput = styled.input`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding: 10px;
  padding-left: 40px;
  width: 100%;
  outline: 0;
  border-radius: 5px;
  border: 2px solid ${TEXT_COLOR_NEUTRAL_3};
  :hover {
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
  :focus {
    border: 2px solid ${SECONDARY_PURPLE_COLOR};
  }
`;

export const Support = styled.div`
  font-size: 16px;
  display: flex;
  line-height: 24px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: ${SECONDARY_PURPLE_COLOR};
  img {
    margin: 0 5px;
  }
`;

export const ObjectPropertyWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 30px;
  top: 90px;
  z-index: 10;
  bottom: 10px;
  z-index: 10;
  flexflow: column;
  width: 320px;
`;

export const ContentObjectCeilHeight = styled.div`
  width: 210px;
  background: rgba(250, 252, 251, 1);
  font-size: 12px;
  box-shadow: 3px 1px 3px 0px rgba(0, 0, 0, 0.2),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  height: 43px;
  margin-top: 13px;
  padding-top: 4px;
`;
export const SearchForm = styled.div`
  position: relative;
  margin: 0px 5px;
`;
// export const SearchInput = styled.input`
//   background: #FFF;
//   border: 0px;
//   border-radius: 3px;
//   height: 25px;
//   width: 100%;
//   padding-left: 24px;
//   &:focus {
//     outline: none;
//     box-shadow: 0px 0px 0px;
//   }
// `
export const SearchIcon = styled.div`
  position: absolute;
  top: 12px;
  left: 15px;
  color: #666;
  cursor: pointer;
  font-size: 24px;
`;
export const TitlesRow = styled.div`
  display: grid;
`;
export const RecentTitle = styled.div`
  color: #fff;
  margin: 14px 0px 19px;
`;
export const RecentStyles = styled.div``;
export const AddStyleButtonWrapper = styled.div`
  cursor: pointer;
  color: #fff;
  button {
    float: left;
  }
  span {
    float: left;
    margin-top: 7px;
    margin-left: 10px;
    font-size: 10px;
  }
`;
export const SubTitle = styled.div`
  color: #000;
  padding-top: 10px;
`;
export const ChildTitle = styled.div`
  color: #000;
  width: 100%;
`;
export const ChildTitle1 = styled.div`
  color: #000;
  padding-bottom: 10px;
  height: 26.5px;
`;
export const CabinetTitle = styled.div`
  color: #000;
  font-weight: 800;
  margin-top: 30px;
`;
export const PropteryValue = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};

  @media screen and (min-width: 1024) {
    font-size: 11px;
  }
  @media screen and (max-width: 1024) {
    font-size: 11px;
  }
  @media screen and (min-width: 1366) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366) {
    font-size: 13px;
  }
  @media screen and (min-width: 1440) {
    font-size: 16px;
  }
  @media screen and (max-width: 1440) {
    font-size: 16px;
  }
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  font-size: 16px;
`;
export const PropteryValue1 = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};

  @media screen and (min-width: 1024) {
    font-size: 11px;
  }
  @media screen and (max-width: 1024) {
    font-size: 11px;
  }
  @media screen and (min-width: 1366) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366) {
    font-size: 13px;
  }
  @media screen and (min-width: 1440) {
    font-size: 16px;
  }
  @media screen and (max-width: 1440) {
    font-size: 16px;
  }
  line-height: 22px;
  text-align: left;
  font-size: 16px;
  font-weight: 600px;
`;
export const PropteryValue2 = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};

  @media screen and (min-width: 1024) {
    font-size: 11px;
  }
  @media screen and (max-width: 1024) {
    font-size: 11px;
  }
  @media screen and (min-width: 1366) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366) {
    font-size: 13px;
  }
  @media screen and (min-width: 1440) {
    font-size: 16px;
  }
  @media screen and (max-width: 1440) {
    font-size: 16px;
  }
  line-height: 22px;
  text-align: left;
  font-size: 16px;
  font-weight: 600px;
  width: 45%;
`;
export const PositionValue = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};

  @media screen and (min-width: 1024) {
    font-size: 11px;
  }
  @media screen and (max-width: 1024) {
    font-size: 11px;
  }
  @media screen and (min-width: 1366) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366) {
    font-size: 13px;
  }
  @media screen and (min-width: 1440) {
    font-size: 16px;
  }
  @media screen and (max-width: 1440) {
    font-size: 16px;
  }
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  margin-top: 10px;
`;

export const DesignValue = styled.div`
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-size: 26px;
  font-weight: 700;
  width: 100%;
  font-family: ${DEFAULT_FONT_FAMILY};
  line-height: 36px;
  text-align: left;
  margin-top: 30px;
`;

export const Diver = styled.div`
  border-bottom: 2px solid;
`;
export const FurnitureBoxTitle = styled.div`
  color: #000;
  padding-bottom: 10px;
  font-size: 11px;
`;
export const ClearFix = styled.div`
  clear: both;
  height: 0px;
`;
export const Image = styled.div`
  text-align: center;
  div {
    width: 66px;
    height: 70px;
  }
  img {
    width: 66px;
    height: 70px;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s;
  }
  :hover {
    img {
      width: 70px;
      height: 75px;
    }
  }
`;
export const SelectedWrapper = styled.div`
  width: 210px;
  background: rgba(250, 252, 251, 1);
  font-size: 12px;
  box-shadow: 3px 1px 3px 0px rgba(0, 0, 0, 0.2),
    -1px -1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`;
export const SelectedBox = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${TEXT_COLOR_NEUTRAL_5};
  border-radius: 10px;
  width: 100%;
  height: 150px;
  margin-top: 15px;
  img {
    width: 50%;
    padding: 8px;
    cursor: pointer;
    transition: 0.3s;
  }
`;
//////
export const CategoryContentWrapper = styled.div`
  float: left;
  width: 100%;
  min-width: 185px;
  overflow-y: auto;
`;
export const ProductsContentWrapper = styled.div`
  float: left;
  border-left: 2px solid #2f2f2f;
  overflow-y: auto;
`;
export const ItemWrapper = styled.div`
  display: flex;
  border-bottom: 2px solid #2f2f2f;
`;
export const testJsonItemWrapper = styled.div`
  display: flex;
  border-bottom: 2px solid #2f2f2f;
  font-size: 12px;
  font-weight: 700;
  padding: 12px;
  :hover {
    color: rgb(28, 166, 252);
    cursor: pointer;
  }
`;
export const ProductsRow = styled.div`
  display: block;
  flex-wrap: wrap;
  align-items: streatch;
  a :hover {
    color: rgb(28, 166, 252);
    cursor: pointer;
  }
`;
export const ButtonTitle = styled.span`
  margin: auto;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  user-select: none;
`;

export const ButtonBox = styled.div`
  border-radius: 10px;
  padding: 10px;
  margin: auto;
  cursor: pointer;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  display: flex;
  :hover {
    background-color: ${TEXT_COLOR_NEUTRAL_5};
  }
`;

export const DialogCheckbox = styled(Checkbox)`
  padding: 0px !important;
  font-size: 14px !important;
  color: aliceblue !important;
  margin-bottom: 4px !important;
`;
