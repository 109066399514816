import { object } from 'prop-types';
import { LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from '../constants';

const initialState = {
  success: false,
  error: {
    username: null,
    password: null
  }
};

export default function (state, action) {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return state.merge({ login: true, userId: action.userid });
    }
    case LOGIN_ERROR: {
      return state.merge({ login: false, userId: 0 });
    }
    case LOGOUT: {
      state = state.merge({
        scene: state.scene.merge({ title: '', currentProjectID: null })
      });
      return state;
    }
    default: {
      return state;
    }
  }
}
