import React, { useEffect, useState } from 'react';
import State3DDownload from './ruler-utils-download/state3DDownload';
import { getSignedUrl } from '../../../../../utils/helper';
import Viewer3DElevationDownload from './viewer3DElevationDownload';

const ViewerElevationDownLoad = ({ state, catalog }) => {
  const width = 682,
    height = 500;

  let pdfID = `${state.mode}_pdfID`;
  let svgID = `${state.mode}_svgID`;

  useEffect(() => {
    setTimeout(() => saveSVGScreenshotToFile(), 5000);
  });

  const saveSVGScreenshotToFile = async () => {
    let maxWidthSVGElement = document.getElementById(`${svgID}`);

    if (maxWidthSVGElement !== null) {
      let images = maxWidthSVGElement.getElementsByTagName('image');

      let readBlob = b => {
        return new Promise(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            res(reader.result);
          };
          reader.onerror = () => {
            res(b);
          };
          reader.readAsDataURL(b);
        });
      };

      try {
        for (let image of images) {
          const response = await fetch(
            getSignedUrl(image.getAttribute('href'))
          );
          const blob = await response.blob();
          const content = await readBlob(blob);
          image.setAttribute('href', content);
        }
      } catch (e) {}
      // End: Change Image tags' href to local data src
    }

    let serializer = new XMLSerializer();

    let img = new Image();
    let img2 = new Image();
    // I create the new canvas to draw
    let canvas2D3DPlain1 = document.getElementById(`${pdfID}`);
    if (!window.__elevationImg) {
      window.__elevationImg = {};
    }
    if (canvas2D3DPlain1 !== null) {
      let canvas2D3DPlain = canvas2D3DPlain1.getElementsByTagName('canvas')[0];

      let canvas42DPlain = document.createElement('canvas');
      let ctx = canvas42DPlain.getContext('2d');

      // Set width and height for the new canvas
      let heightAtt = document.createAttribute('height');
      heightAtt.value = maxWidthSVGElement.height.baseVal.value;
      canvas42DPlain.setAttributeNode(heightAtt);

      let widthAtt = document.createAttribute('width');
      widthAtt.value = maxWidthSVGElement.width.baseVal.value;
      canvas42DPlain.setAttributeNode(widthAtt);

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas42DPlain.width, canvas42DPlain.height);

      img.crossOrigin = 'anonymous';
      img.src = `data:image/svg+xml;base64,${window.btoa(
        serializer.serializeToString(maxWidthSVGElement)
      )}`;
      img2.crossOrigin = 'anonymous';
      img2.src = canvas2D3DPlain.toDataURL();

      img2.onload = () => {
        ctx.globalAlpha = 1;
        ctx.drawImage(
          img2,
          0,
          0,
          maxWidthSVGElement.width.baseVal.value,
          maxWidthSVGElement.height.baseVal.value
        );
        ctx.drawImage(
          img,
          0,
          0,
          maxWidthSVGElement.width.baseVal.value,
          maxWidthSVGElement.height.baseVal.value
        );

        window.__elevationImg[state.mode] = canvas42DPlain.toDataURL();
      };
    } else {
      window.__elevationImg[state.mode] = '';
    }
  };

  return (
    <div
      id={pdfID}
      style={{
        position: 'relative',
        transform: `scale(0.5,0.5)`,
        width: 6,
        height: 0
      }}
    >
      <svg
        id={svgID}
        width={width}
        height={height}
        style={{ position: 'absolute' }}
      >
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          <State3DDownload
            state={state}
            catalog={catalog}
            width={width}
            height={height}
          />
        </g>
      </svg>
      <Viewer3DElevationDownload state={state} width={width} height={height} />
    </div>
  );
};

export default ViewerElevationDownLoad;
