import React from 'react';
import PropTypes from 'prop-types';
import { Line, Area, Vertex, Item, Group } from './export';
import { GeometryUtils } from '../../utils/export';
import { MODE_DRAWING_LINE } from '../../constants';
import { returnReplaceableDeepSearchType } from './utils';

const STYLE = {
  stroke: '#494949',
  strokeWidth: 1,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: 2.613,
  fill: '#D6F0EE'
};

const STYLE_TEXT = {
  textAnchor: 'middle',
  fontSize: 10,
  fontFamily: "'Courier New', Courier, monospace",
  fontWeight: 'bold',
  fill: '#506ad3'
};

const HINT_STYLE_TEXT = {
  textAnchor: 'middle',
  fontSize: '10px',
  fontFamily: "'Microsoft YaHei UI', Courier, monospace",
  fontWeight: 'bold',
  fill: '1a75ff'
};

export default function Layer({ layer, scene, catalog, mode }) {
  let { unit, groups } = scene;
  let { lines, areas, vertices, holes, id: layerID, items, opacity } = layer;
  // console.log("Layer", layer.items.valueSeq());

  let allLines;
  let allLineRects;

  allLines = GeometryUtils.getAllLines(layer);
  allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);

  let hintVertex = {
    x: 0,
    y: 0
  };

  let angleVertexes = [];
  vertices.valueSeq().forEach(vert => {
    let vertexes = [vert];
    lines.valueSeq().forEach(line => {
      let vertex0 = layer.vertices.get(line.vertices.get(0));
      let vertex1 = layer.vertices.get(line.vertices.get(1));
      if (vertex0 === vert) vertexes.push(vertex1);
      if (vertex1 === vert) vertexes.push(vertex0);
    });
    if (vertexes.length === 3) {
      angleVertexes.push(vertexes);
    }

    hintVertex.x += vert.x;
    hintVertex.y += vert.y;
  });
  hintVertex.x /= vertices.size;
  hintVertex.y /= vertices.size;
  const itemData = [];
  const itemWall = [];
  const itemSelected = [];
  items = items.sort(function (a, b) {
    return (
      a.getIn(['properties', 'altitude', '_length']) -
      b.getIn(['properties', 'altitude', '_length'])
    );
  });
  items
    .valueSeq()
    .filter(a => {
      return a.selected === true;
    })
    .forEach(item => {
      itemSelected.push(
        <Item
          key={item.id}
          layer={layer}
          item={item}
          scene={scene}
          catalog={catalog}
          mode={mode}
        />
      );
    });
  items.valueSeq().forEach(item => {
    if (item.layoutpos !== 'Wall') {
      if (
        catalog.elements.hasOwnProperty(item.type) ||
        (!catalog.elements[item.type] &&
          !!catalog.elements[returnReplaceableDeepSearchType(item.type)])
      )
        itemData.push(
          <Item
            key={item.id}
            layer={layer}
            item={item}
            scene={scene}
            catalog={catalog}
          />
        );
      else {
        console.log(`Element ${item.type} does not exist in catalog.`);
      }
    }
  });

  items
    .valueSeq()
    .filter(a => {
      return a.selected === false;
    })
    .forEach(item => {
      if (item.layoutpos === 'Wall') {
        if (
          catalog.elements.hasOwnProperty(item.type) ||
          (!catalog.elements[item.type] &&
            !!catalog.elements[returnReplaceableDeepSearchType(item.type)])
        )
          itemWall.push(
            <Item
              key={item.id}
              layer={layer}
              item={item}
              scene={scene}
              catalog={catalog}
            />
          );
        else {
          console.log(`Element ${item.type} does not exist in catalog.`);
        }
      }
    });

  const lineData = [];
  lines
    .sort(function compare(a, b) {
      return a.createdDateTime - b.createdDateTime;
    })
    .valueSeq()
    .forEach(line => {
      lineData.push(
        <Line
          key={line.id}
          layer={layer}
          line={line}
          scene={scene}
          catalog={catalog}
        />
      );
    });

  return (
    <g opacity={opacity}>
      {areas.valueSeq().map(area => (
        <Area
          key={area.id}
          layer={layer}
          area={area}
          unit={unit}
          catalog={catalog}
        />
      ))}
      {lineData}
      {itemData}
      {itemWall}
      {itemSelected}
      {mode !== MODE_DRAWING_LINE &&
        vertices
          .valueSeq()
          .filter(v => v.selected)
          .map(vertex => (
            <Vertex key={vertex.id} layer={layer} vertex={vertex} />
          ))}
      {groups
        .valueSeq()
        .filter(g => g.hasIn(['elements', layerID]) && g.get('selected'))
        .map(group => (
          <Group
            key={group.get('id')}
            layer={layer}
            group={group}
            scene={scene}
            catalog={catalog}
          />
        ))}
    </g>
  );
}

Layer.propTypes = {
  layer: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};
