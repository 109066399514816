import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel, FormColorInput } from '../../components/style/export';
import PropertyStyle from './shared-property-style';

export default function PropertyColor({
  value,
  onUpdate,
  configs,
  sourceElement,
  internalState,
  state
}) {
  let update = val => {
    if (configs.hook) {
      return configs
        .hook(val, sourceElement, internalState, state)
        .then(_val => {
          return onUpdate(_val);
        });
    }

    return onUpdate(val);
  };

  return (
    <table className="PropertyColor" style={PropertyStyle.tableStyle}>
      <tbody>
        <tr>
          <td style={PropertyStyle.firstTdStyle}>
            <FormLabel>{configs.label}</FormLabel>
          </td>
          <td>
            <FormColorInput
              value={value}
              onChange={event => update(event.target.value)}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

PropertyColor.propTypes = {
  value: PropTypes.any.isRequired,
  onUpdate: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired
};
