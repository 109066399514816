import { List } from 'immutable';
import { Project, Area, Line, Hole, Item, Vertex } from './export';
import { GraphInnerCycles, GeometryUtils, IDBroker } from '../utils/export';
import { Layer as LayerModel } from '../models';

const sameSet = (set1, set2) =>
  set1.size === set2.size && set1.isSuperset(set2) && set1.isSubset(set2);
const sameDirection = (list1, list2) => {
  if (!sameSet(list1, list2)) return false;
  if (list1.size < 3) return true;
  let l0 = list1.get(0);
  let l1 = list1.get(1);
  let i0 = list2.indexOf(l0);
  let i1 = list2.indexOf(l1);
  return (i1 - i0 - 1) % list1.size == 0;
};

class Layer {
  static create(state, name, altitude) {
    let layerID = IDBroker.acquireID();
    name = name || `layer ${layerID}`;
    altitude = altitude || 0;

    let layer = new LayerModel({ id: layerID, name, altitude });

    state = state.setIn(['scene', 'selectedLayer'], layerID);
    state = state.setIn(['scene', 'layers', layerID], layer);

    return { updatedState: state };
  }

  static select(state, layerID) {
    if (!state.get('alterate')) state = Project.unselectAll(state).updatedState;
    state = state.setIn(['scene', 'selectedLayer'], layerID);

    return { updatedState: state };
  }

  static selectAll(state, layerID) {
    if (!state.get('alterate')) state = Project.unselectAll(state).updatedState;
    state = state.setIn(['scene', 'selectedLayer'], layerID);
    let layer = state.scene.layers.get(layerID);
    layer.items.forEach(data => {
      state = Layer.selectElement(
        state,
        layerID,
        'items',
        data.id
      ).updatedState;
    });
    layer.lines.forEach(data => {
      state = Layer.selectElement(
        state,
        layerID,
        'lines',
        data.id
      ).updatedState;
    });
    layer.holes.forEach(data => {
      state = Layer.selectElement(
        state,
        layerID,
        'holes',
        data.id
      ).updatedState;
    });
    layer.areas.forEach(data => {
      state = Layer.selectElement(
        state,
        layerID,
        'areas',
        data.id
      ).updatedState;
    });
    layer.vertices.forEach(data => {
      state = Layer.selectElement(
        state,
        layerID,
        'vertices',
        data.id
      ).updatedState;
    });

    return { updatedState: state };
  }

  static selectElement(state, layerID, elementPrototype, elementID) {
    state = state.setIn(
      ['scene', 'layers', layerID, elementPrototype, elementID, 'selected'],
      true
    );
    state = state.updateIn(
      ['scene', 'layers', layerID, 'selected', elementPrototype],
      elems => elems.push(elementID)
    );

    return { updatedState: state };
  }

  static unselect(state, layerID, elementPrototype, elementID) {
    state = state.setIn(
      ['scene', 'layers', layerID, elementPrototype, elementID, 'selected'],
      false
    );
    state = state.updateIn(
      ['scene', 'layers', layerID, 'selected', elementPrototype],
      elems => elems.filter(el => el.id === elementID)
    );
    return { updatedState: state };
  }

  static updateMovingState(state, showflag) {
    let { scene } = state;

    state = state.merge({
      scene: scene.mergeIn(['showfg'], showflag)
    });
    return { updatedState: state };
  }

  static unselectAll(state, layerID) {
    let { lines, holes, items, areas } = state.getIn([
      'scene',
      'layers',
      layerID
    ]);

    if (lines)
      lines.forEach(line => {
        state = Line.unselect(state, layerID, line.id).updatedState;
      });
    if (holes)
      holes.forEach(hole => {
        state = Hole.unselect(state, layerID, hole.id).updatedState;
      });
    if (items)
      items.forEach(item => {
        state = Item.unselect(state, layerID, item.id).updatedState;
      });
    if (areas)
      areas.forEach(area => {
        state = Area.unselect(state, layerID, area.id).updatedState;
      });

    return { updatedState: state };
  }

  static setInitialDoorStyle(state, doorStyle, oStyle) {
    state = state.merge({
      doorStyle: doorStyle
    });
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let selectedLayer = scene.layers.get(layerID);
    selectedLayer = selectedLayer.setIn(['doorStyle'], doorStyle);
    state = state.merge({
      scene: scene.setIn(['layers', layerID], selectedLayer)
    });
    return { updatedState: state };
  }

  static setProperties(state, layerID, properties) {
    state = state.mergeIn(['scene', 'layers', layerID], properties);
    state = state.updateIn(['scene', 'layers'], layers =>
      layers.sort((a, b) =>
        a.altitude !== b.altitude ? a.altitude - b.altitude : a.order - b.order
      )
    );

    return { updatedState: state };
  }

  static remove(state, layerID) {
    state = state.removeIn(['scene', 'layers', layerID]);

    state = state.setIn(
      ['scene', 'selectedLayer'],
      state.scene.selectedLayer !== layerID
        ? state.scene.selectedLayer
        : state.scene.layers.first().id
    );

    return { updatedState: state };
  }

  static removeElement(state, layerID, elementPrototype, elementID) {
    state = state.deleteIn([
      'scene',
      'layers',
      layerID,
      elementPrototype,
      elementID
    ]);

    return { updatedState: state };
  }

  static detectAndUpdateAreas(state, layerID) {
    let verticesArray = []; //array with vertices coords
    let linesArray;
    let virtualArray = []; //array with edges

    let vertexID_to_verticesArrayIndex = {};
    let verticesArrayIndex_to_vertexID = {};

    state.getIn(['scene', 'layers', layerID, 'vertices']).forEach(vertex => {
      let verticesCount = verticesArray.push([vertex.x, vertex.y]);
      let latestVertexIndex = verticesCount - 1;
      vertexID_to_verticesArrayIndex[vertex.id] = latestVertexIndex;
      verticesArrayIndex_to_vertexID[latestVertexIndex] = vertex.id;
    });

    let vertexCount = verticesArray.length;
    let vitual = {};

    linesArray = state
      .getIn(['scene', 'layers', layerID, 'lines'])
      .map(line =>
        line.vertices
          .map(vertexID => vertexID_to_verticesArrayIndex[vertexID])
          .toArray()
      );

    //open line
    // if (vertexCount > 1 && !linesArray.some(line => (line[0]==0 && line[1]==vertexCount-1) || (line[1]==0 && line[0]==vertexCount-1))) {
    //   linesArray = linesArray.set(IDBroker.acquireID(),  [0, vertexCount-1]);
    // }
    let innerCyclesByVerticesArrayIndex = GraphInnerCycles.calculateInnerCycles(
      verticesArray,
      linesArray
    );

    let innerCyclesByVerticesID = new List(innerCyclesByVerticesArrayIndex).map(
      cycle =>
        new List(
          cycle.map(vertexIndex => verticesArrayIndex_to_vertexID[vertexIndex])
        )
    );
    // All area vertices should be ordered in counterclockwise order
    innerCyclesByVerticesID = innerCyclesByVerticesID.map(area =>
      GraphInnerCycles.isClockWiseOrder(
        area.map(vertexID =>
          state.getIn(['scene', 'layers', layerID, 'vertices', vertexID])
        )
      )
        ? area.reverse()
        : area
    );
    let areaIDs = [];

    //remove areas
    state.getIn(['scene', 'layers', layerID, 'areas']).forEach(area => {
      let areaInUse = innerCyclesByVerticesID.some(vertices =>
        sameDirection(vertices, area.vertices)
      );
      if (!areaInUse) {
        state = Area.remove(state, layerID, area.id).updatedState;
      }
    });

    //add new areas
    innerCyclesByVerticesID.forEach((cycle, ind) => {
      let areaInUse = state
        .getIn(['scene', 'layers', layerID, 'areas'])
        .find(area => sameDirection(area.vertices, cycle));

      if (areaInUse) {
        areaIDs[ind] = areaInUse.id;
        state = state.setIn(
          ['scene', 'layers', layerID, 'areas', areaIDs[ind], 'holes'],
          new List()
        );
      } else {
        let areaVerticesCoords = cycle.map(vertexID =>
          state.getIn(['scene', 'layers', layerID, 'vertices', vertexID])
        );
        let resultAdd = Area.add(
          state,
          layerID,
          'area',
          areaVerticesCoords,
          state.catalog
        );

        areaIDs[ind] = resultAdd.area.id;
        state = resultAdd.updatedState;
      }
    });

    // Build a relationship between areas and their coordinates
    let verticesCoordsForArea = areaIDs.map(id => {
      let vertices = state
        .getIn(['scene', 'layers', layerID, 'areas', id])
        .vertices.map(vertexID => {
          let { x, y } = state.getIn([
            'scene',
            'layers',
            layerID,
            'vertices',
            vertexID
          ]);
          return new List([x, y]);
        });
      return { id, vertices };
    });

    // Find all holes for an area
    let i, j;
    for (i = 0; i < verticesCoordsForArea.length; i++) {
      let holesList = new List(); // The holes for this area
      let areaVerticesList = verticesCoordsForArea[i].vertices
        .flatten()
        .toArray();
      for (j = 0; j < verticesCoordsForArea.length; j++) {
        if (i !== j) {
          let isHole = GeometryUtils.ContainsPoint(
            areaVerticesList,
            verticesCoordsForArea[j].vertices.get(0).get(0),
            verticesCoordsForArea[j].vertices.get(0).get(1)
          );
          if (isHole) {
            holesList = holesList.push(verticesCoordsForArea[j].id);
          }
        }
      }
      state = state.setIn(
        [
          'scene',
          'layers',
          layerID,
          'areas',
          verticesCoordsForArea[i].id,
          'holes'
        ],
        holesList
      );
    }

    // Remove holes which are already holes for other areas
    areaIDs.forEach(areaID => {
      let doubleHoles = new Set();
      let areaHoles = state.getIn([
        'scene',
        'layers',
        layerID,
        'areas',
        areaID,
        'holes'
      ]);
      areaHoles.forEach(areaHoleID => {
        let holesOfholes = state.getIn([
          'scene',
          'layers',
          layerID,
          'areas',
          areaHoleID,
          'holes'
        ]);
        holesOfholes.forEach(holeID => {
          if (areaHoles.indexOf(holeID) !== -1) doubleHoles.add(holeID);
        });
      });
      doubleHoles.forEach(doubleHoleID => {
        areaHoles = areaHoles.remove(areaHoles.indexOf(doubleHoleID));
      });
      state = state.setIn(
        ['scene', 'layers', layerID, 'areas', areaID, 'holes'],
        areaHoles
      );
    });

    // update direction of lines surrounding area
    let layer = state.getIn(['scene', 'layers', layerID]);
    let allAreaLines = GeometryUtils.getAllAreaLines(layer);
    layer.lines.forEach(line => {
      allAreaLines.some(l => {
        if (line.vertices.get(0) == l[0] && line.vertices.get(1) == l[1]) {
          state = state.setIn(
            ['scene', 'layers', layerID, 'lines', line.id, 'vertices', 0],
            l[1]
          );
          state = state.setIn(
            ['scene', 'layers', layerID, 'lines', line.id, 'vertices', 1],
            l[0]
          );
          return true;
        } else if (
          line.vertices.get(1) == l[0] &&
          line.vertices.get(0) == l[1]
        ) {
          return true;
        } else {
          return false;
        }
      });
    });

    //
    let separatedLine = [];
    let allLinesArray = state.getIn(['scene', 'layers', layerID, 'lines']);
    allLinesArray.forEach(line => {
      let isAreaLine = false;
      isAreaLine = allAreaLines.some(areaLine => {
        if (
          (areaLine[0] == line.vertices.get(0) &&
            areaLine[1] == line.vertices.get(1)) ||
          (areaLine[0] == line.vertices.get(1) &&
            areaLine[1] == line.vertices.get(0))
        )
          return true;
      });
      if (!isAreaLine) {
        if (
          !(
            GeometryUtils.isPointInArea(
              GeometryUtils.getAllArea(layer),
              layer.vertices.get(line.vertices.get(0))
            ) &&
            GeometryUtils.isPointInArea(
              GeometryUtils.getAllArea(layer),
              layer.vertices.get(line.vertices.get(1))
            )
          )
        ) {
          separatedLine.push(line);
        }
      }
    });
    let separatedLineCnt = separatedLine.length;

    if (separatedLineCnt == 1) {
      separatedLine.forEach(line => {
        let ptArray = [],
          pt3 = [],
          pt4 = [];
        ptArray.push(
          verticesArray[vertexID_to_verticesArrayIndex[line.vertices.get(0)]]
        );
        ptArray.push(
          verticesArray[vertexID_to_verticesArrayIndex[line.vertices.get(1)]]
        );
        pt3.push(ptArray[1][1] - ptArray[0][1] + ptArray[1][0]);
        pt3.push(ptArray[0][0] - ptArray[1][0] + ptArray[1][1]);
        ptArray.push(pt3);
        pt4.push(ptArray[0][0] + ptArray[2][0] - ptArray[1][0]);
        pt4.push(ptArray[0][1] + ptArray[2][1] - ptArray[1][1]);
        ptArray.push(pt4);
        let pointArray = [];
        ptArray.forEach(pt => {
          let point = { x: pt[0], y: pt[1] };
          pointArray.push(point);
        });
        pointArray.reverse();
        let resultAdd = Area.add(
          state,
          layerID,
          'area',
          pointArray,
          state.catalog
        );
        state = resultAdd.updatedState;
      });
    } else if (separatedLineCnt > 1) {
      let ptXArray = [],
        ptYArray = [],
        ptXMin,
        ptXMax,
        ptYMin,
        ptYMAX;
      separatedLine.forEach(line => {
        ptXArray.push(
          verticesArray[vertexID_to_verticesArrayIndex[line.vertices.get(0)]][0]
        );
        ptXArray.push(
          verticesArray[vertexID_to_verticesArrayIndex[line.vertices.get(1)]][0]
        );
        ptYArray.push(
          verticesArray[vertexID_to_verticesArrayIndex[line.vertices.get(0)]][1]
        );
        ptYArray.push(
          verticesArray[vertexID_to_verticesArrayIndex[line.vertices.get(1)]][1]
        );
      });
      ptXMin = Math.min.apply(null, ptXArray);
      ptXMax = Math.max.apply(null, ptXArray);
      ptYMin = Math.min.apply(null, ptYArray);
      ptYMAX = Math.max.apply(null, ptYArray);
      let pointArray = [];
      pointArray.push({ x: ptXMin, y: ptYMin });
      pointArray.push({ x: ptXMax, y: ptYMin });
      pointArray.push({ x: ptXMax, y: ptYMAX });
      pointArray.push({ x: ptXMin, y: ptYMAX });
      let resultAdd = Area.add(
        state,
        layerID,
        'area',
        pointArray,
        state.catalog
      );
      state = resultAdd.updatedState;
    }

    return { updatedState: state };
  }

  static removeZeroLengthLines(state, layerID) {
    let updatedState = state
      .getIn(['scene', 'layers', layerID, 'lines'])
      .reduce((newState, line) => {
        let v_id0 = line.getIn(['vertices', 0]);
        let v_id1 = line.getIn(['vertices', 1]);

        let v0 = newState.getIn([
          'scene',
          'layers',
          layerID,
          'vertices',
          v_id0
        ]);
        let v1 = newState.getIn([
          'scene',
          'layers',
          layerID,
          'vertices',
          v_id1
        ]);

        if (GeometryUtils.verticesDistance(v0, v1) === 0) {
          newState = Line.remove(newState, layerID, line.id).updatedState;
        }

        return newState;
      }, state);

    return { updatedState };
  }

  static mergeEqualsVertices(state, layerID, vertexID) {
    //1. find vertices to remove
    let vertex = state.getIn([
      'scene',
      'layers',
      layerID,
      'vertices',
      vertexID
    ]);

    let doubleVertices = state
      .getIn(['scene', 'layers', layerID, 'vertices'])
      .filter(v => {
        return (
          v.id !== vertexID && GeometryUtils.samePoints(vertex, v) // &&
          //!v.lines.contains( vertexID ) &&
          //!v.areas.contains( vertexID )
        );
      });

    if (doubleVertices.isEmpty()) return { updatedState: state };

    doubleVertices.forEach(doubleVertex => {
      let reduced = doubleVertex.lines.reduce((reducedState, lineID) => {
        reducedState = reducedState.updateIn(
          ['scene', 'layers', layerID, 'lines', lineID, 'vertices'],
          vertices => {
            if (vertices) {
              return vertices.map(v => (v === doubleVertex.id ? vertexID : v));
            }
          }
        );
        reducedState = Vertex.addElement(
          reducedState,
          layerID,
          vertexID,
          'lines',
          lineID
        ).updatedState;

        return reducedState;
      }, state);

      let biReduced = doubleVertex.areas.reduce((reducedState, areaID) => {
        reducedState = reducedState.updateIn(
          ['scene', 'layers', layerID, 'areas', areaID, 'vertices'],
          vertices => {
            if (vertices)
              return vertices.map(v => (v === doubleVertex.id ? vertexID : v));
          }
        );
        reducedState = Vertex.addElement(
          reducedState,
          layerID,
          vertexID,
          'areas',
          areaID
        ).updatedState;

        return reducedState;
      }, reduced);

      state = Vertex.remove(
        biReduced,
        layerID,
        doubleVertex.id,
        null,
        null,
        true
      ).updatedState;
    });

    return { updatedState: state };
  }

  static setPropertiesOnSelected(state, layerID, properties) {
    let selected = state.getIn(['scene', 'layers', layerID, 'selected']);

    selected.lines.forEach(
      lineID =>
        (state = Line.setProperties(
          state,
          layerID,
          lineID,
          properties
        ).updatedState)
    );
    selected.holes.forEach(
      holeID =>
        (state = Hole.setProperties(
          state,
          layerID,
          holeID,
          properties
        ).updatedState)
    );
    selected.areas.forEach(
      areaID =>
        (state = Area.setProperties(
          state,
          layerID,
          areaID,
          properties
        ).updatedState)
    );
    selected.items.forEach(
      itemID =>
        (state = Item.setProperties(
          state,
          layerID,
          itemID,
          properties
        ).updatedState)
    );

    return { updatedState: state };
  }

  static updatePropertiesOnSelected(state, layerID, properties) {
    let selected = state.getIn(['scene', 'layers', layerID, 'selected']);

    selected.lines.forEach(
      lineID =>
        (state = Line.updateProperties(
          state,
          layerID,
          lineID,
          properties
        ).updatedState)
    );
    selected.holes.forEach(
      holeID =>
        (state = Hole.updateProperties(
          state,
          layerID,
          holeID,
          properties
        ).updatedState)
    );
    selected.areas.forEach(
      areaID =>
        (state = Area.updateProperties(
          state,
          layerID,
          areaID,
          properties
        ).updatedState)
    );
    selected.items.forEach(
      itemID =>
        (state = Item.updateProperties(
          state,
          layerID,
          itemID,
          properties
        ).updatedState)
    );

    return { updatedState: state };
  }

  static setAttributesOnSelected(state, layerID, attributes) {
    let selected = state.getIn(['scene', 'layers', layerID, 'selected']);

    selected.lines.forEach(
      lineID =>
        (state = Line.setAttributes(
          state,
          layerID,
          lineID,
          attributes
        ).updatedState)
    );
    selected.holes.forEach(
      holeID =>
        (state = Hole.setAttributes(
          state,
          layerID,
          holeID,
          attributes
        ).updatedState)
    );
    selected.items.forEach(
      itemID =>
        (state = Item.setAttributes(
          state,
          layerID,
          itemID,
          attributes
        ).updatedState)
    );
    //selected.areas.forEach(areaID => state = Area.setAttributes( state, layerID, areaID, attributes ).updatedState);

    return { updatedState: state };
  }
}

export { Layer as default };
