import { Layer } from '../class/export';
import { history } from '../utils/export';
import {
  ADD_LAYER,
  SELECT_LAYER,
  SET_LAYER_PROPERTIES,
  REMOVE_LAYER,
  UPDATE_MOVING_STATE
} from '../constants';

export default function (state, action) {
  const saveHistory = () =>
    (state = state.merge({
      sceneHistory: history.historyPush(state.sceneHistory, state.scene)
    }));

  switch (action.type) {
    case ADD_LAYER:
      saveHistory();
      return Layer.create(state, action.name, action.altitude).updatedState;

    case SELECT_LAYER:
      saveHistory();
      return Layer.select(state, action.layerID).updatedState;

    case SET_LAYER_PROPERTIES:
      saveHistory();
      return Layer.setProperties(state, action.layerID, action.properties)
        .updatedState;

    case REMOVE_LAYER:
      saveHistory();
      return Layer.remove(state, action.layerID).updatedState;

    case UPDATE_MOVING_STATE:
      return Layer.updateMovingState(state, action.showflag).updatedState;

    default:
      return state;
  }
}
