import {
  LoaderUtils,
  LoadingManager,
  BufferGeometry,
  BufferAttribute,
  Box3,
  Vector3
} from 'three';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { OBJTYPE_MESH } from '../../../../src/constants';

export function loadObjWithMaterial(mtlFile, objFile, imgPath) {
  var manager = new LoadingManager();
  let mtlLoader = new MTLLoader(manager);
  // mtlLoader.setPath(imgPath);
  let url = mtlFile;
  return new Promise((resolve, reject) => {
    mtlLoader.load(url, materials => {
      materials.preload();
      let objLoader = new OBJLoader();
      objLoader.setMaterials(materials);
      objLoader.load(objFile, object => resolve(object));
    });
  });
}

export function loadGLTF(url) {
  if (url == undefined) {
    return Promise.reject('No url is passed');
  }

  const baseURL = LoaderUtils.extractUrlBase(url);
  const manager = new LoadingManager();

  // Intercept and override relative URLs.
  manager.setURLModifier((url, path) => {
    // URIs in a glTF file may be escaped, or not. Assume that assetMap is
    // from an un-escaped source, and decode all URIs before lookups.
    // See: https://github.com/donmccurdy/three-gltf-viewer/issues/146
    const normalizedURL = decodeURI(url)
      .replace(baseURL, '')
      .replace(/^(\.?\/)/, '');

    // if (assetMap.has(normalizedURL)) {
    //   const blob = assetMap.get(normalizedURL);
    //   const blobURL = URL.createObjectURL(blob);
    //   blobURLs.push(blobURL);
    //   return blobURL;
    // }
    return (path || '') + url;
  });
  let loader = new GLTFLoader(manager);
  loader.setCrossOrigin('anonymous');
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath('/assets/draco/');
  loader.setDRACOLoader(dracoLoader);
  return new Promise((resolve, reject) => {
    loader.load(
      url,
      gltf => {
        resolve(gltf.scene);
      },
      xhr => {
        // console.log("xhr", xhr);
        // called while loading is progressing
        // console.log( `${( xhr.loaded / xhr.total * 100 )}% loaded` );
      },
      error => {
        // console.log("error", error);
        // called when loading has errors
        // console.error( '2= An error happened', error );
        reject(error);
      }
    );
  });
}

export function scaleObject(object, defaultSizeArray, scaleSizeArray) {
  let boundingBox = new Box3().setFromObject(object);
  let initialSizeArray = boundingBox.getSize(new Vector3()).toArray();
  for (let i = 0; i < 3; i++) {
    scaleSizeArray[i] -= defaultSizeArray[i];
    defaultSizeArray[i] /= initialSizeArray[i];
  }

  object.children.forEach(child => {
    if (
      child.type == OBJTYPE_MESH &&
      child.geometry instanceof BufferGeometry
    ) {
      let position = child.geometry.attributes.position;
      let new_pos = position.array.map((v, i, a) => {
        let j = i % 3;
        let absv = Math.abs(v);
        v = v * defaultSizeArray[j];
        if (absv > initialSizeArray[j] / 6) {
          v += ((v > 0 ? 1 : -1) * scaleSizeArray[j]) / 2;
        }
        return v;
      });
      let new_geom = child.geometry
        .clone()
        .setAttribute('position', new BufferAttribute(new_pos, 3));
      new_geom.computeBoundingBox();
      new_geom.computeBoundingSphere();
      child.geometry.dispose();
      child.geometry = new_geom;
    }
  });
}
