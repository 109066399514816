import React from 'react';

import * as S from './styles';
import * as MS from '../styles';

const lineWrapperStyle = {
  padding: '5px 15px 8px'
};

const lineImageStyle = {
  maxWidth: '100%'
};

const msButtonTitleStyle = {
  top: '39%',
  fontSize: '12'
};

export default function Appliance({ url, title, selected, onClick, name }) {
  title = title.charAt(0).toUpperCase() + title.substr(1);
  let isUrl = true;
  if (url !== undefined && url.charAt(0) === '#') isUrl = false;
  return (
    <S.LineWrapper style={{ ...lineWrapperStyle }}>
      <S.Control onClick={onClick}>
        <S.LineImage>
          {isUrl && (
            <img
              name={name}
              src={url}
              style={{ width: 75, height: 75, ...lineImageStyle }}
            />
          )}
          {!isUrl && (
            <div style={{ width: 75, height: 75, backgroundColor: url }}></div>
          )}
        </S.LineImage>
        <div style={{ display: 'grid', marginLeft: '10px' }}>
          <h4 style={{ width: 104 }}>{title}</h4>
          <S.ControlIcon key={name}>
            <img
              width={'72px'}
              name={name}
              src={'/assets/img/svg/toolbar/use_button.svg'}
            />
            <MS.ButtonTitle style={{ ...msButtonTitleStyle }}>
              {selected ? 'APPLIED' : 'USE'}
            </MS.ButtonTitle>
          </S.ControlIcon>
        </div>
      </S.Control>
    </S.LineWrapper>
  );
}
