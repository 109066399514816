import React from 'react';
import ToolbarPanel from '../../../../sidebar/toolbar-panel';
import * as S from '../../styles';

export const FinishingCategory = ({ action, props, dataSource, isExpand }) => {
  let miHeight = 0;
  let nonHeight = 495 - miHeight + 'px';
  let height = window.innerHeight * 0.6 - miHeight + 'px';
  var result = [];
  dataSource.map((el, key) => {
    result.push(
      <S.ItemWrapper key={key.toString()}>
        <ToolbarPanel
          key={key}
          onClose={() => {
            action(el.name);
          }}
          onOpen={() => {
            action(el.name);
          }}
          isUrl={true}
          name={el.name}
          url={el.thumbnail}
        />
      </S.ItemWrapper>
    );
  });

  return (
    <S.CategoryContentWrapper style={{ height: isExpand ? height : nonHeight }}>
      {result}
    </S.CategoryContentWrapper>
  );
};
