import React from 'react';
import DownloadSummaryTemp from './downloadSummaryTemp';
import * as constants from '../../../../constants';
import moment from 'moment';

const styles = {
  viewTitle: {
    color: '#565F77',
    marginBottom: 'inherit',
    fontSize: '12px',
    fontFamily: 'Open Sans',
    fontWeight: '700',
    lineHeight: '12.6px',
    wordWrap: 'break-word'
  },
  viewWarranty: {
    color: '#565F77',
    margin: 'inherit',
    fontSize: '10px',
    fontFamily: 'Open Sans',
    fontWeight: '400',
    lineHeight: '12.6px',
    wordWrap: 'break-word',
    marginTop: '4px'
  },
  viewWarranty_1: {
    color: '#565F77',
    margin: 'inherit',
    fontSize: '10px',
    fontFamily: 'Open Sans',
    fontWeight: '400',
    lineHeight: '12.6px',
    wordWrap: 'break-word',
    marginTop: '20px'
  }
};

const ShowWarranty = () => {
  return (
    <DownloadSummaryTemp>
      <div style={{ marginBottom: '32px' }}>
        <div
          style={{
            marginTop: '20px',
            color: '#000F33',
            fontSize: '27px',
            fontFamily: 'Open Sans',
            fontWeight: '700',
            lineHeight: '45.9px',
            wordWrap: 'break-word'
          }}
        >
          {constants.WARRANTY_VIEW}
        </div>
        <div
          style={{
            marginTop: 7,
            color: '#434D63',
            fontSize: '12px',
            fontFamily: 'Open Sans',
            fontWeight: '600',
            lineHeight: '15px',
            wordWrap: 'break-word'
          }}
        >
          {constants.WARRANTY_SUPPORT}
        </div>
        <div style={{}}>
          <h5 style={styles.viewTitle}>1. Coverage Period</h5>
          <p style={styles.viewWarranty}>
            This warranty is valid for a period of{' '}
            {moment(new Date()).format('MM-YYYY')}/[XX months/years] from the
            original purchase date, as indicated on the purchase receipt.
          </p>
          <h5 style={styles.viewTitle}>2. Scope of Warranty</h5>
          <p style={styles.viewWarranty}>
            This warranty covers defects in materials and workmanship under
            normal use. It does not cover damage caused by misuse, neglect,
            unauthorized modifications, or external factors such as accidents or
            natural disasters.
          </p>
          <h5 style={styles.viewTitle}>3. Warranty Claim Process</h5>
          <p style={styles.viewWarranty}>
            <span>To file a warranty claim:</span>
            <ul style={{ paddingLeft: '15px', marginTop: '10px' }}>
              <li>
                Contact our support team at [support email or phone number].
              </li>
              <li>
                Provide proof of purchase and a detailed description of the
                issue.
              </li>
              <li>
                Follow instructions for returning the product or scheduling an
                inspection.
              </li>
            </ul>
          </p>
          <h5 style={styles.viewTitle}>4. Remedies</h5>
          <p style={styles.viewWarranty}>
            <span>
              If a defect is confirmed, DIY Design Space will, at its
              discretion:
            </span>
            <ul style={{ paddingLeft: '15px', marginTop: '10px' }}>
              <li>Repair the defective item.</li>
              <li>Replace it with an identical or equivalent product.</li>
              <li>Refund the purchase price.</li>
            </ul>
          </p>
          <h5 style={styles.viewTitle}>5. Limitations</h5>
          <p style={styles.viewWarranty}>
            This warranty is non-transferable and valid only for the original
            purchaser. It excludes liability for incidental or consequential
            damages arising from the use of the product.
          </p>
          <h5 style={styles.viewTitle}>6. Additional Terms</h5>
          <p style={styles.viewWarranty}>
            For products requiring maintenance, adherence to the recommended
            care guidelines is necessary for warranty validity. This warranty
            gives you specific legal rights, and you may also have other rights
            that vary by jurisdiction. For complete details, please visit our
            website at [website link].
          </p>
          <p style={styles.viewWarranty_1}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque in
            erat vitae purus venenatis vestibulum sed id nisi. Vestibulum sit
            amet risus in nibh tempor ullamcorper. Sed porta vestibulum dui, et
            feugiat libero vestibulum quis. Pellentesque habitant morbi
            tristique senectus et netus et malesuada fames ac turpis egestas.
            Quisque sit amet neque augue. Nam ornare nisl sed dui venenatis, a
            accumsan purus imperdiet. Etiam iaculis leo ac risus efficitur
            sollicitudin. Proin tincidunt feugiat lorem, a cursus diam.
          </p>
          <p style={styles.viewWarranty_1}>
            Proin et lobortis lacus. Donec auctor tempor commodo. Morbi
            tristique enim magna, sit amet rutrum metus facilisis lobortis.
            Nullam quam nisi, ultricies nec ultricies ut, faucibus facilisis
            nisi. Aenean eget interdum erat. Mauris efficitur lobortis sem,
            vitae pretium augue luctus consectetur. In in justo eget ante
            dapibus porttitor. Etiam vestibulum tempor mi ac aliquam.
          </p>
        </div>
      </div>
    </DownloadSummaryTemp>
  );
};

export default ShowWarranty;
