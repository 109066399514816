import {
  Shape,
  ShapeGeometry,
  Box3,
  TextureLoader,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  RepeatWrapping,
  Vector2,
  DoubleSide,
  MeshPhysicalMaterial
} from 'three';
import * as Three from 'three';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import * as SharedStyle from '../../shared-style';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';

const params = {
  envMap: 'HDR',
  roughness: 0.9,
  metalness: 0.8,
  exposure: 1.0
  // debug: false
};

const hdrUrls = ['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'];

let textureCube = new HDRCubeTextureLoader()
  .setPath('/catalog/envMap/')
  .load(hdrUrls, function () {
    textureCube.magFilter = Three.LinearFilter;
    textureCube.needsUpdate = true;
  });

/**
 * Apply a texture to a wall face
 * @param material: The material of the face
 * @param texture: The texture to load
 * @param length: The lenght of the face
 * @param height: The height of the face
 */
const applyTexture = (material, texture, length, height) => {
  let loader = new TextureLoader();
  if (texture) {
    material.map = loader.load(texture.uri);
    material.map.colorSpace = Three.SRGBColorSpace;
    material.needsUpdate = true;
    material.map.wrapS = RepeatWrapping;
    material.map.wrapT = RepeatWrapping;
    material.map.repeat.set(
      length * texture.lengthRepeatScale,
      height * texture.heightRepeatScale
    );

    if (texture.normal) {
      material.normalMap = loader.load(texture.normal.uri);
      material.normalScale = new Vector2(
        texture.normal.normalScaleX,
        texture.normal.normalScaleY
      );
      material.normalMap.wrapS = RepeatWrapping;
      material.normalMap.wrapT = RepeatWrapping;
      material.normalMap.repeat.set(
        length * texture.normal.lengthRepeatScale,
        height * texture.normal.heightRepeatScale
      );
    }
  }
};

/**
 * Function that assign UV coordinates to a geometry
 * @param geometry
 */
const assignUVs = geometry => {
  geometry.computeBoundingBox();

  let { min, max } = geometry.boundingBox;
  let offset = new Vector2(0 - min.x, 0 - min.y);
  let range = new Vector2(max.x - min.x, max.y - min.y);

  const uvArray = [];
  const position = geometry.attributes.position;

  for (let i = 0; i < position.count; i += 3) {
    const x1 = position.getX(i);
    const y1 = position.getY(i);

    const x2 = position.getX(i + 1);
    const y2 = position.getY(i + 1);

    const x3 = position.getX(i + 2);
    const y3 = position.getY(i + 2);

    uvArray.push(
      (x1 + offset.x) / range.x,
      (y1 + offset.y) / range.y,
      (x2 + offset.x) / range.x,
      (y2 + offset.y) / range.y,
      (x3 + offset.x) / range.x,
      (y3 + offset.y) / range.y
    );
  }
  geometry.setAttribute(
    'uv',
    new Three.BufferAttribute(new Float32Array(uvArray), 2)
  );
  geometry.needsUpdate = true;
};

export function createArea(element, layer, scene, textures) {
  let vertices = [];

  element.vertices.forEach(vertexID => {
    vertices.push(layer.vertices.get(vertexID));
  });

  let { texture } = element;

  texture.lengthRepeatScale = 0.01;
  texture.heightRepeatScale = 0.01;

  let color = element.properties.get('patternColor');

  if (element.selected) {
    color = SharedStyle.AREA_MESH_COLOR.selected;
  } else {
    color = SharedStyle.AREA_MESH_COLOR.unselected;
  }
  if (texture.uri === undefined || texture.uri == '') {
    texture.uri = layer.floorStyle.uri;
  }

  let shape = new Shape();

  shape.moveTo(vertices[0].x, vertices[0].y);
  for (let i = 1; i < vertices.length; i++) {
    shape.lineTo(vertices[i].x, vertices[i].y);
  }
  function loadFloorENV() {
    return new RGBELoader().load('/assets/Window.hdr', function (texture) {
      texture.mapping = Three.EquirectangularReflectionMapping;
      return texture;
    });
  }
  const floorENV = loadFloorENV();
  let loader = new TextureLoader();

  let areaMaterial = new MeshPhysicalMaterial({
    side: Three.DoubleSide,
    metalness: texture.metalness,
    roughness: 0.3,
    envMap: floorENV,
    envMapIntensity: 1.8,
    specularIntensity: 0.4,
    map: loader.load(texture.uri)
  });

  /* Create holes for the area */
  element.holes.forEach(holeID => {
    let holeCoords = [];
    layer.getIn(['areas', holeID, 'vertices']).forEach(vertexID => {
      let { x, y } = layer.getIn(['vertices', vertexID]);
      holeCoords.push([x, y]);
    });
    holeCoords = holeCoords.reverse();
    let holeShape = createShape(holeCoords);
    shape.holes.push(holeShape);
  });

  // This is extrude floor mesh

  let extrudeSettings = {
    depth: -3,
    bevelEnabled: false
  };

  let shapeGeometry = new Three.ExtrudeGeometry(shape, extrudeSettings);

  assignUVs(shapeGeometry);

  let boundingBox = new Box3().setFromObject(
    new Mesh(shapeGeometry, new MeshBasicMaterial())
  );
  let width = boundingBox.max.x - boundingBox.min.x;
  let height = boundingBox.max.y - boundingBox.min.y;
  const texture_unit = 70 * 2.54; // 70 inch

  applyTexture(
    areaMaterial,
    texture,
    (width / texture_unit) * 100,
    (height / texture_unit) * 100
  );
  let area = new Mesh(shapeGeometry, areaMaterial);
  area.rotation.x -= Math.PI / 2;
  area.receiveShadow = true;
  area.name = 'floor';
  // This mesh is use for creating ceiling mesh

  let shapeGeometry2 = new Three.ShapeGeometry(shape);
  let area2 = new Mesh(
    shapeGeometry2,
    new MeshBasicMaterial({ transparent: true, opacity: 0.0 })
  );
  area2.castShadow = true;
  area2.rotation.x -= Math.PI / 2;
  area2.receiveShadow = true;
  area2.name = 'floorSupport';

  const floorSupport = area2.clone();
  area.userData.floorSupport = floorSupport;

  return Promise.resolve(area);
}

export function updatedArea(
  element,
  layer,
  scene,
  textures,
  mesh,
  oldElement,
  differences,
  selfDestroy,
  selfBuild
) {
  let noPerf = () => {
    selfDestroy();
    return selfBuild();
  };
  let floor = mesh.getObjectByName('floor');
  floor.receiveShadow = true;
  if (differences[0] == 'selected') {
    let color = element.selected
      ? SharedStyle.AREA_MESH_COLOR.selected
      : SharedStyle.AREA_MESH_COLOR.unselected;
    floor.material.color.set(color);
  } else if (differences[0] == 'properties') {
    if (differences[1] === 'texture') {
      return noPerf();
    }
  } else return noPerf();

  return Promise.resolve(mesh);
}

/**
 * This function will create a shape given a list of coordinates
 * @param shapeCoords
 * @returns {Shape}
 */
const createShape = shapeCoords => {
  let shape = new Shape();
  shape.moveTo(shapeCoords[0][0], shapeCoords[0][1]);
  for (let i = 1; i < shapeCoords.length; i++) {
    shape.lineTo(shapeCoords[i][0], shapeCoords[i][1]);
  }
  return shape;
};
