import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../../shared-style';
import { MODE_IDLE_3D } from '../../../constants';
import * as S from './styles';

export default class ToggleButton extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    let { props } = this;
    let overlayStyle = {};
    let activeStyle =
      props.settingMode === MODE_IDLE_3D || props.active
        ? { paddingRight: '30px', paddingBottom: '2px' }
        : { paddingLeft: '30px', paddingBottom: '2px' };
    return (
      <S.Toggle style={{ ...overlayStyle }} onClick={props.onClick}>
        {[
          props.title !== undefined ? (
            <S.ControlTitle key={props.title}>{props.title}</S.ControlTitle>
          ) : null,
          props.icon !== undefined ? (
            <S.ToggleIcon style={{ ...activeStyle }} key={props.icon}>
              {props.icon}
            </S.ToggleIcon>
          ) : null
        ]}
      </S.Toggle>
    );
  }
}

ToggleButton.propTypes = {
  title: PropTypes.string,
  hover: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};
