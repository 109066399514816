import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import {
  TEXT_COLOR_NEUTRAL_1,
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR,
  BG_COLOR_HOVER
} from '../../../../constants';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    border-radius: 10px;
  }
`;

const StyledDialogContent = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 25px 30px;
  padding-bottom: 10px;
`;

const StyledDialogAction = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 10px 30px 25px;
`;

const StyledButton = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  color: ${SECONDARY_PURPLE_COLOR};
  font-weight: bold;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  border-radius: 20px;
  :hover {
    background-color: ${BG_COLOR_HOVER};
  }
`;

const DoorStyleOption = props => {
  return (
    <StyledDialog
      open={props.visible}
      onClose={() => props.setVisible(!props.visible)}
    >
      <StyledDialogContent>{props.content}</StyledDialogContent>
      <StyledDialogAction>
        <StyledButton onClick={() => props.handle(true)}>All</StyledButton>
        <StyledButton onClick={() => props.handle(false)}>Current</StyledButton>
      </StyledDialogAction>
    </StyledDialog>
    // <div style={{ width: '100%', height: '100%', userSelect: 'none' }}>
    //   <Div>
    //     <div style={{ backgroundColor: '#1183B7', height: '30px', paddingRight: '6px' }}>
    //       <div style={{ float: 'right', marginTop: '4px' }} onClick={() => props.handle(false)}>
    //         <IconButton><FaTimes /></IconButton>
    //       </div>
    //     </div>
    //     <div style={{ fontSize: 14, marginTop: 20 }}>
    //       <span style={{ margin: 20 }}>{props.content}</span>
    //     </div>
    //     <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', position: 'relative' }}>
    //     </div>
    //     <div style={{ display: 'flex', position: 'relative', marginTop: '20px', justifyContent: 'space-evenly' }}>
    //       <div style={{ width: '70px', marginTop: '15px', cursor: 'pointer' }} onClick={() => props.handle(true)}>
    //         <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/use_button.svg'} />
    //         <ButtonTitle1>
    //           {'All'}
    //         </ButtonTitle1>
    //       </div>
    //       <div style={{ width: '70px', marginTop: '15px', cursor: 'pointer' }} onClick={() => props.handle(false)}>
    //         <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/cancel_button.svg'} />
    //         <ButtonTitle2>
    //           {'Current'}
    //         </ButtonTitle2>
    //       </div>
    //     </div>
    //   </Div>
    // </div>
  );
};

export default DoorStyleOption;
