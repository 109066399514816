import React from 'react';
import * as Three from 'three';
import { loadObjWithMaterial, loadGLTF } from '../../utils/load-obj';
import path from 'path';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import { OBJTYPE_MESH } from '../../../../../src/constants';

const hdrUrls = ['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'];
let textureCube = new HDRCubeTextureLoader()
  .setPath('/catalog/envMap/')
  .load(hdrUrls, function () {
    textureCube.magFilter = Three.LinearFilter;
    textureCube.needsUpdate = true;
  });

let cached3DDoor = null;

const STYLE_HOLE_BASE = {
  stroke: 'rgb(73, 73, 73)',
  strokeWidth: '1px',
  strokeDasharray: '9,5',
  fill: 'rgb(73, 73, 73)'
};
const STYLE_HOLE_SELECTED = {
  stroke: '#0096fd',
  strokeWidth: '1px',
  strokeDasharray: '9,5',
  fill: '#0096fd',
  cursor: 'move'
};
const STYLE_ARC_BASE = {
  stroke: 'rgb(73, 73, 73)',
  strokeWidth: '1px',
  strokeDasharray: '9,5',
  fill: 'none'
};
const STYLE_ARC_SELECTED = {
  stroke: '#0096fd',
  strokeWidth: '1px',
  strokeDasharray: '9,5',
  fill: 'none',
  cursor: 'move'
};
const STYLE_STR0 = {
  fill: 'rgb(185, 185, 185)',
  stroke: '#494949',
  strokeWidth: '1',
  strokeMiterlimit: '2.61313'
};
const STYLE_STR0_S = {
  fill: 'rgb(185, 185, 185)',
  stroke: '#0096fd',
  strokeWidth: '1',
  strokeMiterlimit: '2.61313'
};
const STYLE_STR1 = {
  fill: 'none',
  stroke: '#494949',
  strokeWidth: '1',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeMiterlimit: '2.61313',
  strokeDasharray: '23.860041 11.930021'
};
const STYLE_FILL2 = { fill: '#1183B7' };
const STYLE_FNT0 = {
  fill: 'white',
  fontWeight: 'normal',
  fontSize: '13px',
  fontFamily: 'Proxima Nova Rg'
};
const EPSILON = 3;

export default {
  name: 'Exterior',
  prototype: 'holes',

  info: {
    title: 'Exterior',
    tag: ['door'],
    description: 'Exterior door',
    image: '/assets/img/svg/door/Exterior.svg',
    url: '/assets/gltf/door_exterior.gltf'
  },

  properties: {
    width: {
      label: 'Width',
      type: 'length-measure',
      defaultValue: {
        length: 91.44
      }
    },
    height: {
      label: 'Height',
      type: 'length-measure',
      defaultValue: {
        length: 215
      },
      length: 215
    },
    altitude: {
      label: 'Altitude',
      type: 'length-measure',
      defaultValue: {
        length: 0
      }
    },
    thickness: {
      label: 'Thickness',
      type: 'length-measure',
      defaultValue: {
        length: 6
      }
    },
    flip_orizzontal: {
      label: 'Flip Door',
      type: 'checkbox',
      defaultValue: false,
      values: {
        none: false,
        yes: true
      }
    }
  },

  render2D: function (element, layer, scene) {
    let flip = element.properties.get('flip_orizzontal');
    let lineWidth = 6;
    let holeWidth = element.properties.get('width').get('length');
    let holePath = `M${0} ${-EPSILON}  L${holeWidth} ${-EPSILON}  L${holeWidth} ${EPSILON}  L${0} ${EPSILON}  z`;
    let arcPath = `M${0},${0}  A${holeWidth},${holeWidth} 0 0,1 ${holeWidth},${holeWidth}`;
    let holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
    let arcStyle = element.selected ? STYLE_ARC_SELECTED : STYLE_ARC_BASE;
    let rectStyle = element.selected ? STYLE_STR0_S : STYLE_STR0;
    let length = element.properties.get('width').get('length');
    if (flip == false) {
      return (
        <g transform={`translate(${-length / 2}, 0)`}>
          <path
            d={arcPath}
            style={arcStyle}
            transform={`translate(${0},${holeWidth}) scale(${1},${-1}) rotate(${0})`}
          />
          <line
            x1={0}
            y1={holeWidth - EPSILON}
            x2={0}
            y2={0 - EPSILON}
            style={holeStyle}
            transform={`scale(${-1},${1})`}
          />
          <rect
            style={rectStyle}
            x="0"
            y={-lineWidth / 2}
            width={holeWidth}
            height={lineWidth}
          />
          {/* <rect style={STYLE_FILL2} x={holeWidth - 16} y="-8.5" width="17" height="17" rx="1.27" ry="1.27"/> */}
          {/* <text x={holeWidth - 15} y="4.5" transform={`scale(1,-1)`} style={STYLE_FNT0}>E</text> */}
        </g>
      );
    } else {
      return (
        <g transform={`translate(${-length / 2}, 0)`}>
          <path
            d={arcPath}
            style={arcStyle}
            transform={`translate(${0},${-holeWidth}) scale(${1},${1}) rotate(${0})`}
          />
          <line
            x1={0}
            y1={-holeWidth - EPSILON}
            x2={0}
            y2={0 - EPSILON}
            style={holeStyle}
            transform={`scale(${1},${1})`}
          />
          <rect
            style={rectStyle}
            x="0"
            y={-lineWidth / 2}
            width={holeWidth}
            height={lineWidth}
          />
          {/* <rect style={STYLE_FILL2} x={holeWidth - 16} y="-8.5" width="17" height="17" rx="1.27" ry="1.27"/> */}
          {/* <text x={holeWidth - 15} y="4.5"  style={STYLE_FNT0}>E</text> */}
        </g>
      );
    }
  },

  render3D: function (element, layer, scene) {
    let onLoadItem = object => {
      let boundingBox = new Three.Box3().setFromObject(object);

      let initialWidth = boundingBox.max.x - boundingBox.min.x;
      let initialHeight = boundingBox.max.y - boundingBox.min.y;
      let initialThickness = boundingBox.max.z - boundingBox.min.z;

      if (element.selected) {
        let box = new Three.BoxHelper(object, 0x99c3fb);
        box.material.linewidth = 2;
        box.material.depthTest = false;
        box.renderOrder = 1000;
        object.add(box);
      }

      const params = {
        envMap: 'HDR',
        roughness: 0.9,
        metalness: 0.1,
        exposure: 1.0
      };

      const examplecolor = new Three.Color(0xffffff);
      let mat2 = new Three.MeshStandardMaterial({
        color: examplecolor,
        metalness: params.metalness,
        roughness: params.roughness
      });

      // mat2.envMap = textureCube;
      for (let j = 0; j < object.children.length; j++) {
        if (object.children[j].type === OBJTYPE_MESH) {
          object.children[j].material = mat2;
          object.children[j].receiveShadow = true;
        }
      }

      let width = element.properties.get('width').get('length');
      let height = element.properties.get('height').get('length');
      let thickness = element.properties.get('thickness').get('length');

      object.scale.set(
        width / initialWidth,
        height / initialHeight,
        thickness / initialThickness
      );

      return object;
    };

    if (cached3DDoor) {
      return Promise.resolve(onLoadItem(cached3DDoor.clone()));
    }

    return loadGLTF(element.url).then(object => {
      cached3DDoor = object;
      return onLoadItem(cached3DDoor.clone());
    });
  }
};
