import React from 'react';
import Ruler3DDownload from './ruler3DDownload';
import convert from 'convert-units';
import { GeometryUtils } from '../../../../../../utils/export';
import { almostEqual } from '../../../../../../utils/geometry';
import { returnReplaceableDeepSearchType } from '../../../../../viewer2d/utils';
import {
  MODE_FRONT_ELEVATION_VIEW,
  MODE_BACK_ELEVATION_VIEW,
  MODE_LEFT_ELEVATION_VIEW,
  MODE_RIGHT_ELEVATION_VIEW,
  TEXT_COLOR_NEUTRAL_0
} from '../../../../../../constants';

export default function Layer3DDownload({
  layer,
  frontRect,
  lineLength,
  ceilHeight,
  catalog,
  scene,
  scale,
  minX,
  minY,
  maxX,
  maxY,
  mode,
  lines,
  viewScale
}) {
  const { width, height } = frontRect;
  const rulerSpace = 50 * viewScale;
  const lineSpace = 30 * viewScale;
  const STYLE = {
    stroke: TEXT_COLOR_NEUTRAL_0,
    strokeWidth: viewScale
  };
  let rulerLines = [],
    rulerData = [],
    baseItems = [],
    wallItems = [],
    basePosArray = [
      { x: width / 2, y: height / 2 },
      { x: -width / 2, y: height / 2 }
    ],
    wallPosArray = [
      { x: -width / 2, y: -height / 2 },
      { x: width / 2, y: -height / 2 }
    ],
    left_count = 0,
    right_count = 0,
    lineVertices = [],
    linePosArray = [
      { x: width / 2, y: height / 2 },
      { x: -width / 2, y: height / 2 }
    ],
    leftBaseItems = [],
    rightBaseItems = [],
    leftWallItems = [],
    rightWallItems = [],
    lineBoundaries = [];
  // const v0 = {x: minX, y: minY}, v1 = {x: maxX, y: minY}, v2 = {x: maxX, y: maxY}, v3 = {x: minX, y: maxY};
  let v0, v1;
  switch (mode) {
    case MODE_FRONT_ELEVATION_VIEW:
      v0 = { x: minX, y: minY };
      v1 = { x: maxX, y: minY };
      break;
    case MODE_BACK_ELEVATION_VIEW:
      v0 = { x: maxX, y: maxY };
      v1 = { x: minX, y: maxY };
      break;
    case MODE_RIGHT_ELEVATION_VIEW:
      v0 = { x: maxX, y: minY };
      v1 = { x: maxX, y: maxY };
      break;
    case MODE_LEFT_ELEVATION_VIEW:
      v0 = { x: minX, y: maxY };
      v1 = { x: minX, y: minY };
    default:
      break;
  }
  const getLength = (vertex0, vertex1) => {
    return Math.sqrt(
      Math.pow(vertex0.x - vertex1.x, 2) + Math.pow(vertex0.y - vertex1.y, 2)
    );
  };

  const isSnapped = itemRect => {
    let itemSnapped = false;
    lines.forEach(line => {
      const { l0, l1 } = line;
      const r1 = itemRect.rect[1];
      const r2 = itemRect.rect[2];
      const r3 = itemRect.rect[3];
      let delta23 =
        GeometryUtils.verticesDistance(l0, r3) +
        GeometryUtils.verticesDistance(r3, r2) +
        GeometryUtils.verticesDistance(r2, l1) -
        GeometryUtils.verticesDistance(l1, l0);
      let delta12 =
        GeometryUtils.verticesDistance(l0, r2) +
        GeometryUtils.verticesDistance(r2, r1) +
        GeometryUtils.verticesDistance(r1, l1) -
        GeometryUtils.verticesDistance(l1, l0);
      if (delta23 < 0.1 || delta12 < 0.1) {
        itemSnapped = true;
      }
    });
    return itemSnapped;
  };

  layer.items.forEach(item => {
    let val = {
      pos: { x: item.x, y: item.y },
      rotRad: (item.rotation / 180) * Math.PI
    };
    let catid = item.type;
    let cat = catalog.elements[catid];
    if (!cat) cat = catalog.elements[returnReplaceableDeepSearchType(catid)];
    let width = convert(item.properties.getIn(['width', '_length']))
      .from('in')
      .to(scene.unit);
    let height = convert(item.properties.getIn(['height', '_length']))
      .from('in')
      .to(scene.unit);
    let depth = convert(item.properties.getIn(['depth', '_length']))
      .from('in')
      .to(scene.unit);
    val.size = { width, height, depth };
    val.layoutpos = cat && cat.info.layoutpos;
    val.is_corner = cat && cat.info.is_corner;
    val.item = item.toJS();
    let calcrect = GeometryUtils.getCalcRectFromItem3D(val);
    if (isSnapped(calcrect)) {
      if (calcrect.itemInfo.properties.altitude.length) {
        wallItems.push(calcrect);
      } else {
        baseItems.push(calcrect);
      }
    }
  });

  const sortFunc = (a, b) => {
    let value = 0;
    switch (mode) {
      case MODE_FRONT_ELEVATION_VIEW:
        value = a.pos.x - b.pos.x;
        break;
      case MODE_BACK_ELEVATION_VIEW:
        value = b.pos.x - a.pos.x;
        break;
      case MODE_RIGHT_ELEVATION_VIEW:
        value = b.pos.y - a.pos.y;
        break;
      case MODE_LEFT_ELEVATION_VIEW:
        value = a.pos.y - b.pos.y;
        break;
    }
    return value;
  };

  const negSortFunc = (a, b) => -sortFunc(a, b);

  wallItems.sort(sortFunc);
  baseItems.sort(negSortFunc);

  lines.forEach(line => {
    switch (mode) {
      case MODE_FRONT_ELEVATION_VIEW:
      case MODE_BACK_ELEVATION_VIEW:
        !lineVertices.filter(it => almostEqual(it.x, line.l0.x)).length &&
          lineVertices.push({ x: line.l0.x, y: maxY });
        !lineVertices.filter(it => almostEqual(it.x, line.l1.x)).length &&
          lineVertices.push({ x: line.l1.x, y: maxY });
        break;
      case MODE_RIGHT_ELEVATION_VIEW:
      case MODE_LEFT_ELEVATION_VIEW:
        !lineVertices.filter(it => almostEqual(it.y, line.l0.y)).length &&
          lineVertices.push({ x: maxX, y: line.l0.y });
        !lineVertices.filter(it => almostEqual(it.y, line.l1.y)).length &&
          lineVertices.push({ x: maxX, y: line.l1.y });
        break;
    }
  });

  lineVertices.sort((a, b) => {
    let value = 0;
    switch (mode) {
      case MODE_FRONT_ELEVATION_VIEW:
        value = b.x - a.x;
        break;
      case MODE_BACK_ELEVATION_VIEW:
        value = a.x - b.x;
        break;
      case MODE_RIGHT_ELEVATION_VIEW:
        value = a.y - b.y;
        break;
      case MODE_LEFT_ELEVATION_VIEW:
        value = b.y - a.y;
        break;
    }
    return value;
  });

  for (let i = 1; i < lineVertices.length - 1; i++) {
    let value = 0;
    switch (mode) {
      case MODE_FRONT_ELEVATION_VIEW:
      case MODE_BACK_ELEVATION_VIEW:
        lineBoundaries.push(
          <line
            x1={
              linePosArray[linePosArray.length - 2].x -
              convert(Math.abs(lineVertices[i].x - lineVertices[i - 1].x))
                .from(scene.unit)
                .to(scene.rulerUnit) *
                scale
            }
            y1={-height / 2}
            x2={
              linePosArray[linePosArray.length - 2].x -
              convert(Math.abs(lineVertices[i].x - lineVertices[i - 1].x))
                .from(scene.unit)
                .to(scene.rulerUnit) *
                scale
            }
            y2={height / 2}
            style={STYLE}
          />
        );
        linePosArray.splice(linePosArray.length - 1, 0, {
          x:
            linePosArray[linePosArray.length - 2].x -
            convert(Math.abs(lineVertices[i].x - lineVertices[i - 1].x))
              .from(scene.unit)
              .to(scene.rulerUnit) *
              scale,
          y: height / 2
        });
        break;
      case MODE_RIGHT_ELEVATION_VIEW:
      case MODE_LEFT_ELEVATION_VIEW:
        lineBoundaries.push(
          <line
            x1={
              linePosArray[linePosArray.length - 2].x -
              convert(Math.abs(lineVertices[i].y - lineVertices[i - 1].y))
                .from(scene.unit)
                .to(scene.rulerUnit) *
                scale
            }
            y1={-height / 2}
            x2={
              linePosArray[linePosArray.length - 2].x -
              convert(Math.abs(lineVertices[i].y - lineVertices[i - 1].y))
                .from(scene.unit)
                .to(scene.rulerUnit) *
                scale
            }
            y2={height / 2}
            style={STYLE}
          />
        );
        linePosArray.splice(linePosArray.length - 1, 0, {
          x:
            linePosArray[linePosArray.length - 2].x -
            convert(Math.abs(lineVertices[i].y - lineVertices[i - 1].y))
              .from(scene.unit)
              .to(scene.rulerUnit) *
              scale,
          y: height / 2
        });
        break;
    }
  }

  if (baseItems.length) {
    baseItems.forEach((item, idx) => {
      let distance = 0;
      if (idx === 0) {
        switch (mode) {
          case MODE_FRONT_ELEVATION_VIEW:
          case MODE_BACK_ELEVATION_VIEW:
            distance = convert(Math.abs(item.rect[2].x - v1.x))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
          case MODE_RIGHT_ELEVATION_VIEW:
          case MODE_LEFT_ELEVATION_VIEW:
            distance = convert(Math.abs(item.rect[2].y - v0.y))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
        }
        basePosArray.splice(basePosArray.length - 1, 0, {
          x: basePosArray[basePosArray.length - 2].x - distance * scale,
          y: height / 2
        });
        basePosArray.splice(basePosArray.length - 1, 0, {
          x:
            basePosArray[basePosArray.length - 2].x -
            convert(item.size.width).from(scene.unit).to(scene.rulerUnit) *
              scale,
          y: height / 2
        });
      } else {
        switch (mode) {
          case MODE_FRONT_ELEVATION_VIEW:
          case MODE_BACK_ELEVATION_VIEW:
            distance = convert(
              Math.abs(item.rect[2].x - baseItems[idx - 1].rect[3].x)
            )
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
          case MODE_RIGHT_ELEVATION_VIEW:
          case MODE_LEFT_ELEVATION_VIEW:
            distance = convert(
              Math.abs(item.rect[2].y - baseItems[idx - 1].rect[3].y)
            )
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
        }
        basePosArray.splice(basePosArray.length - 1, 0, {
          x: basePosArray[basePosArray.length - 2].x - distance * scale,
          y: height / 2
        });
        basePosArray.splice(basePosArray.length - 1, 0, {
          x:
            basePosArray[basePosArray.length - 2].x -
            convert(item.size.width).from(scene.unit).to(scene.rulerUnit) *
              scale,
          y: height / 2
        });
      }
      let distLeft = 0,
        distRight = 0;
      switch (mode) {
        case MODE_FRONT_ELEVATION_VIEW:
        case MODE_BACK_ELEVATION_VIEW:
          distLeft = Math.abs(item.pos.x - v0.x);
          distRight = Math.abs(item.pos.x - v1.x);
          break;
        case MODE_RIGHT_ELEVATION_VIEW:
        case MODE_LEFT_ELEVATION_VIEW:
          distLeft = Math.abs(item.pos.y - v1.y);
          distRight = Math.abs(item.pos.y - v0.y);
          break;
      }
      if (distLeft < distRight) {
        leftBaseItems.push(item);
      } else {
        rightBaseItems.push(item);
      }
    });
  }

  if (wallItems.length) {
    wallItems.forEach((item, idx) => {
      let distance = 0;
      if (idx === 0) {
        switch (mode) {
          case MODE_FRONT_ELEVATION_VIEW:
          case MODE_BACK_ELEVATION_VIEW:
            distance = convert(Math.abs(item.rect[3].x - v0.x))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
          case MODE_RIGHT_ELEVATION_VIEW:
          case MODE_LEFT_ELEVATION_VIEW:
            distance = convert(Math.abs(item.rect[3].y - v1.y))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
        }
        wallPosArray.splice(wallPosArray.length - 1, 0, {
          x: wallPosArray[wallPosArray.length - 2].x + distance * scale,
          y: -height / 2
        });
        wallPosArray.splice(wallPosArray.length - 1, 0, {
          x:
            wallPosArray[wallPosArray.length - 2].x +
            convert(item.size.width).from(scene.unit).to(scene.rulerUnit) *
              scale,
          y: -height / 2
        });
      } else {
        switch (mode) {
          case MODE_FRONT_ELEVATION_VIEW:
          case MODE_BACK_ELEVATION_VIEW:
            distance = convert(
              Math.abs(item.rect[3].x - wallItems[idx - 1].rect[2].x)
            )
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
          case MODE_RIGHT_ELEVATION_VIEW:
          case MODE_LEFT_ELEVATION_VIEW:
            distance = convert(
              Math.abs(item.rect[3].y - wallItems[idx - 1].rect[2].y)
            )
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
        }
        wallPosArray.splice(wallPosArray.length - 1, 0, {
          x: wallPosArray[wallPosArray.length - 2].x + distance * scale,
          y: -height / 2
        });
        wallPosArray.splice(wallPosArray.length - 1, 0, {
          x:
            wallPosArray[wallPosArray.length - 2].x +
            convert(item.size.width).from(scene.unit).to(scene.rulerUnit) *
              scale,
          y: -height / 2
        });
      }
      let distLeft = 0,
        distRight = 0;
      switch (mode) {
        case MODE_FRONT_ELEVATION_VIEW:
        case MODE_BACK_ELEVATION_VIEW:
          distLeft = Math.abs(item.pos.x - v0.x);
          distRight = Math.abs(item.pos.x - v1.x);
          break;
        case MODE_RIGHT_ELEVATION_VIEW:
        case MODE_LEFT_ELEVATION_VIEW:
          distLeft = Math.abs(item.pos.y - v1.y);
          distRight = Math.abs(item.pos.y - v0.y);
          break;
      }
      if (distLeft < distRight) {
        rightWallItems.push(item);
      } else {
        leftWallItems.push(item);
      }
    });
  }

  leftBaseItems.sort(sortFunc);
  rightWallItems.sort(sortFunc);

  rightBaseItems.sort(negSortFunc);
  leftWallItems.sort(negSortFunc);

  let leftItems = leftBaseItems.concat(leftWallItems);
  let rightItems = rightBaseItems.concat(rightWallItems);

  leftItems.forEach((item, idx) => {
    let forwardItems = [];
    let distLine = 0;
    let itemHeight = convert(item.size.height)
      .from(scene.unit)
      .to(scene.rulerUnit);
    for (let i = 0; i < idx; i++) {
      forwardItems.push(leftItems[i]);
    }
    if (
      !forwardItems.filter(
        it =>
          it.itemInfo.properties.altitude._length ===
            item.itemInfo.properties.altitude._length &&
          it.itemInfo.properties.height._length ===
            item.itemInfo.properties.height._length
      ).length
    ) {
      if (item.itemInfo.properties.altitude._length) {
        switch (mode) {
          case MODE_FRONT_ELEVATION_VIEW:
          case MODE_BACK_ELEVATION_VIEW:
            distLine = convert(Math.abs(item.rect[2].x - v1.x))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
          case MODE_RIGHT_ELEVATION_VIEW:
          case MODE_LEFT_ELEVATION_VIEW:
            distLine = convert(Math.abs(item.rect[2].y - v0.y))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
        }
        rulerLines.push({
          v0: { x: width / 2, y: -height / 2 },
          v1: {
            x: width / 2,
            y:
              height / 2 -
              (itemHeight + item.itemInfo.properties.altitude._length) * scale
          },
          text: (
            ceilHeight -
            (item.itemInfo.properties.altitude._length + itemHeight)
          ).toFixed(1),
          space: rulerSpace + right_count * lineSpace
        });
        rulerLines.push({
          v0: {
            x: width / 2 - distLine * scale,
            y:
              height / 2 -
              (itemHeight + item.itemInfo.properties.altitude._length) * scale
          },
          v1: {
            x: width / 2 - distLine * scale,
            y: height / 2 - item.itemInfo.properties.altitude._length * scale
          },
          text: itemHeight.toFixed(1),
          space: rulerSpace + distLine * scale + right_count * lineSpace
        });
        rulerLines.push({
          v0: {
            x: width / 2,
            y: height / 2 - item.itemInfo.properties.altitude._length * scale
          },
          v1: { x: width / 2, y: height / 2 },
          text: item.itemInfo.properties.altitude._length.toFixed(1),
          space: rulerSpace + right_count * lineSpace
        });
        right_count++;
      } else {
        switch (mode) {
          case MODE_FRONT_ELEVATION_VIEW:
          case MODE_BACK_ELEVATION_VIEW:
            distLine = convert(Math.abs(item.rect[3].x - v0.x))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
          case MODE_RIGHT_ELEVATION_VIEW:
          case MODE_LEFT_ELEVATION_VIEW:
            distLine = convert(Math.abs(item.rect[3].y - v1.y))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
        }
        rulerLines.push({
          v0: { x: -width / 2 + distLine * scale, y: height / 2 },
          v1: {
            x: -width / 2 + distLine * scale,
            y: height / 2 - itemHeight * scale
          },
          text: itemHeight.toFixed(1),
          space: rulerSpace + distLine * scale + left_count * lineSpace
        });
        rulerLines.push({
          v0: { x: -width / 2, y: height / 2 - itemHeight * scale },
          v1: { x: -width / 2, y: -height / 2 },
          text: (ceilHeight - itemHeight).toFixed(1),
          space: rulerSpace + left_count * lineSpace
        });
        left_count++;
      }
    }
  });

  rightItems.forEach((item, idx) => {
    let forwardItems = [];
    let distLine = 0;
    let itemHeight = convert(item.size.height)
      .from(scene.unit)
      .to(scene.rulerUnit);
    for (let i = 0; i < idx; i++) {
      forwardItems.push(rightItems[i]);
    }
    if (
      !forwardItems.filter(
        it =>
          it.itemInfo.properties.altitude._length ===
            item.itemInfo.properties.altitude._length &&
          it.itemInfo.properties.height._length ===
            item.itemInfo.properties.height._length
      ).length
    ) {
      if (item.itemInfo.properties.altitude._length) {
        switch (mode) {
          case MODE_FRONT_ELEVATION_VIEW:
          case MODE_BACK_ELEVATION_VIEW:
            distLine = convert(Math.abs(item.rect[3].x - v0.x))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
          case MODE_RIGHT_ELEVATION_VIEW:
          case MODE_LEFT_ELEVATION_VIEW:
            distLine = convert(Math.abs(item.rect[3].y - v1.y))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
        }
        let itemHeight = convert(item.size.height)
          .from(scene.unit)
          .to(scene.rulerUnit);
        rulerLines.push({
          v0: { x: -width / 2, y: height / 2 },
          v1: {
            x: -width / 2,
            y: height / 2 - item.itemInfo.properties.altitude._length * scale
          },
          text: item.itemInfo.properties.altitude._length.toFixed(1),
          space: rulerSpace + left_count * lineSpace
        });
        rulerLines.push({
          v0: {
            x: -width / 2 + distLine * scale,
            y: height / 2 - item.itemInfo.properties.altitude._length * scale
          },
          v1: {
            x: -width / 2 + distLine * scale,
            y:
              height / 2 -
              (item.itemInfo.properties.altitude._length + itemHeight) * scale
          },
          text: itemHeight.toFixed(1),
          space: rulerSpace + +distLine * scale + left_count * lineSpace
        });
        rulerLines.push({
          v0: {
            x: -width / 2,
            y:
              height / 2 -
              (item.itemInfo.properties.altitude._length + itemHeight) * scale
          },
          v1: { x: -width / 2, y: -height / 2 },
          text: (
            ceilHeight -
            (item.itemInfo.properties.altitude._length + itemHeight)
          ).toFixed(1),
          space: rulerSpace + left_count * lineSpace
        });
        left_count++;
      } else {
        switch (mode) {
          case MODE_FRONT_ELEVATION_VIEW:
          case MODE_BACK_ELEVATION_VIEW:
            distLine = convert(Math.abs(item.rect[2].x - v1.x))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
          case MODE_RIGHT_ELEVATION_VIEW:
          case MODE_LEFT_ELEVATION_VIEW:
            distLine = convert(Math.abs(item.rect[2].y - v0.y))
              .from(scene.unit)
              .to(scene.rulerUnit);
            break;
        }
        rulerLines.push({
          v0: { x: width / 2, y: -height / 2 },
          v1: { x: width / 2, y: height / 2 - itemHeight * scale },
          text: (ceilHeight - itemHeight).toFixed(1),
          space: rulerSpace + right_count * lineSpace
        });
        rulerLines.push({
          v0: {
            x: width / 2 - distLine * scale,
            y: height / 2 - itemHeight * scale
          },
          v1: { x: width / 2 - distLine * scale, y: height / 2 },
          text: itemHeight.toFixed(1),
          space: rulerSpace + distLine * scale + right_count * lineSpace
        });
        right_count++;
      }
    }
  });

  rulerLines.push({
    v0: { x: width / 2, y: -height / 2 },
    v1: { x: width / 2, y: height / 2 },
    text: ceilHeight,
    space: rulerSpace + lineSpace * right_count
  });
  rulerLines.push({
    v0: { x: -width / 2, y: height / 2 },
    v1: { x: -width / 2, y: -height / 2 },
    text: ceilHeight,
    space: rulerSpace + lineSpace * left_count
  });
  rulerLines.push({
    v0: { x: width / 2, y: height / 2 },
    v1: { x: -width / 2, y: height / 2 },
    text: lineLength.toFixed(0),
    space:
      rulerSpace +
      (baseItems.length ? lineSpace : 0) +
      (lines.length !== 1 ? lineSpace : 0)
  });
  rulerLines.push({
    v0: { x: -width / 2, y: -height / 2 },
    v1: { x: width / 2, y: -height / 2 },
    text: lineLength.toFixed(0),
    space: rulerSpace + (wallItems.length ? lineSpace : 0)
  });
  for (let j = 0; j < linePosArray.length - 1; j++) {
    let dist = Math.abs(linePosArray[j].x - linePosArray[j + 1].x) / scale;
    rulerLines.push({
      v0: linePosArray[j],
      v1: linePosArray[j + 1],
      text: dist.toFixed(0),
      space: rulerSpace + (baseItems.length ? lineSpace : 0)
    });
  }

  if (wallItems.length) {
    for (let i = 0; i < wallPosArray.length - 1; i++) {
      let dist = getLength(wallPosArray[i], wallPosArray[i + 1]) / scale;
      rulerLines.push({
        v0: wallPosArray[i],
        v1: wallPosArray[i + 1],
        space: rulerSpace,
        text: dist.toFixed(0)
      });
    }
  }
  if (baseItems.length) {
    for (let j = 0; j < basePosArray.length - 1; j++) {
      let dist = getLength(basePosArray[j], basePosArray[j + 1]) / scale;
      rulerLines.push({
        v0: basePosArray[j],
        v1: basePosArray[j + 1],
        space: rulerSpace,
        text: dist.toFixed(0)
      });
    }
  }

  rulerLines.forEach(line => {
    if (line.text !== '0' && line.text !== '0.0')
      rulerData.push(
        <Ruler3DDownload line={line} layer={layer} viewScale={viewScale} />
      );
  });

  return (
    <g opacity={layer.opacity}>
      {rulerData}
      {lineBoundaries}
    </g>
  );
}
