import {
  PROJECT_ACTIONS,
  VIEWER2D_ACTIONS,
  VIEWER3D_ACTIONS,
  GROUP_ACTIONS,
  ITEMS_ACTIONS,
  HOLE_ACTIONS,
  LINE_ACTIONS,
  AREA_ACTIONS,
  SCENE_ACTIONS,
  VERTEX_ACTIONS,
  USER_ACTIONS
} from '../constants';

import {
  KitchenConfiguratorAreasReducer,
  KitchenConfiguratorHolesReducer,
  KitchenConfiguratorItemsReducer,
  KitchenConfiguratorLinesReducer,
  KitchenConfiguratorGroupsReducer,
  KitchenConfiguratorProjectReducer,
  KitchenConfiguratorSceneReducer,
  KitchenConfiguratorVerticesReducer,
  KitchenConfiguratorViewer2dReducer,
  KitchenConfiguratorViewer3dReducer,
  KitchenConfiguratorUserReducer
} from './export';

import { State } from '../models';

export const initialState = new State();

export default function appReducer(state, action) {
  // /////////
  if (PROJECT_ACTIONS[action.type])
    return KitchenConfiguratorProjectReducer(...arguments);
  if (VIEWER2D_ACTIONS[action.type])
    return KitchenConfiguratorViewer2dReducer(...arguments);
  if (VIEWER3D_ACTIONS[action.type])
    return KitchenConfiguratorViewer3dReducer(...arguments);
  if (ITEMS_ACTIONS[action.type])
    return KitchenConfiguratorItemsReducer(...arguments);
  if (HOLE_ACTIONS[action.type])
    return KitchenConfiguratorHolesReducer(...arguments);
  if (LINE_ACTIONS[action.type])
    return KitchenConfiguratorLinesReducer(...arguments);
  if (AREA_ACTIONS[action.type])
    return KitchenConfiguratorAreasReducer(...arguments);
  if (GROUP_ACTIONS[action.type])
    return KitchenConfiguratorGroupsReducer(...arguments);
  if (SCENE_ACTIONS[action.type])
    return KitchenConfiguratorSceneReducer(...arguments);
  if (VERTEX_ACTIONS[action.type])
    return KitchenConfiguratorVerticesReducer(...arguments);
  if (USER_ACTIONS[action.type])
    return KitchenConfiguratorUserReducer(...arguments);

  return state || initialState;
}
