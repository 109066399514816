// COLORS
export const COLORS = {
  white: '#FFF',
  lightgrey: '#d3d3d3',
  black: '#000'
};

export const MATERIAL_COLORS = {
  500: {
    amber: '#FFC107',
    blue_grey: '#607D8B',
    blue: '#2196F3',
    brown: '#795548',
    cyan: '#00BCD4',
    deep_orange: '#FF5722',
    deep_purple: '#673AB7',
    green: '#4CAF50',
    grey: '#9E9E9E',
    indigo: '#3F51B5',
    light_blue: '#03A9F4',
    light_green: '#8BC34A',
    lime: '#CDDC39',
    orange: '#FF9800',
    pink: '#E91E63',
    purple: '#9C27B0',
    red: '#F44336',
    teal: '#009688',
    yellow: '#FFEB3B'
  }
};

export const PRIMARY_COLOR = {
  main: '#DEDEDE',
  alt: '#DEDEDE',
  icon: '#A2A2A2',
  border: '1px solid #555',
  text_main: COLORS.black,
  text_alt: '#232323',
  input: '#55595C'
};

export const SECONDARY_COLOR = {
  main: '#1CA6FC',
  alt: '#005FAF',
  icon: '#1CA6FC',
  border: '1px solid #FFF'
};

export const TOOLBAR_COLOR = {
  normal: 'rgb(211,230,228)',
  focus: 'rgba(71, 71, 71,0.9)',
  active: 'rgba(17, 131, 187, 0.8)'
  // active: 'rgba(34,144,107, 0.8)',
};

export const MESH_SELECTED = 'rgba(71, 71, 71,0.9)';

export const AREA_MESH_COLOR = {
  selected: 'rgb(172, 184, 219)',
  unselected: 'rgb(201, 208, 231)'
};

export const LINE_MESH_COLOR = {
  selected: 'rgb(21, 74, 197)',
  unselected: 'rgb(135,145,171)'
};
