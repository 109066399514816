import { SELECT_AREA, SET_FLOOR_STYLES } from '../constants';

export function selectArea(layerID, areaID) {
  return {
    type: SELECT_AREA,
    layerID,
    areaID
  };
}
export function setFloorStyles(texture) {
  return {
    type: SET_FLOOR_STYLES,
    texture
  };
}
