import React, { useState } from 'react';
import * as S from '../../styles';
import * as MS from './styles';
import FloorProduct from '../../product/floorproduct';
import * as rectangleData from './rectangle.json';
import * as lShaped from './lShaped.json';
import * as oRectangle from './oRectangle.json';
import * as longNarrow from './longNarrow.json';
import ConfirmPopup from './confirm-popup';
import { ARRAY_3D_MODES } from '../../../../../constants';

export const FloorChooseProduct = ({
  drawAction,
  props,
  dataSource,
  isExpand,
  closeAction,
  mode
}) => {
  let [isPopupOpen, setPopupOpen] = useState(false);
  let [selectedProject, setSelectedProject] = useState();

  let miHeight = 0;
  let nonHeight = 495 - miHeight + 'px';
  let height = window.innerHeight * 0.6 - miHeight + 'px';
  let select = element => {
    switch (element.prototype) {
      case 'lines':
        props.linesActions.selectToolDrawingLine(element.name);
        break;
      case 'items':
        ARRAY_3D_MODES.includes(mode)
          ? props.itemsActions.selectToolDrawingItem3D(element.name)
          : props.itemsActions.selectToolDrawingItem(element.name);
        break;
      case 'holes':
        ARRAY_3D_MODES.includes(mode)
          ? props.holesActions.selectToolDrawingHole3D(element.name)
          : props.holesActions.selectToolDrawingHole(element.name);
        break;
    }
    props.holesActions.updatePopupOpen(4);
    props.projectActions.pushLastSelectedCatalogElementToHistory(element);
    closeAction && closeAction();
  };

  let isProjectEmpty = scene => {
    let { layers, selectedLayer } = scene;
    let layer = layers.get(selectedLayer);
    return (
      layer.areas.size +
        layer.lines.size +
        layer.holes.size +
        layer.items.size ===
      0
    );
  };

  let loadProjectFromData = data => event => {
    event.preventDefault();

    if (isProjectEmpty(props.state.scene)) {
      loadProject(data);
      closeAction && closeAction();
    } else {
      setSelectedProject(data);
      setPopupOpen(true);
    }
  };

  let loadProjectFromRetangleLineFile = loadProjectFromData(rectangleData);

  let loadProjectFromlShapedLineFile = loadProjectFromData(lShaped);

  let loadProjectFromoRectangleLineFile = loadProjectFromData(oRectangle);

  let loadProjectFromlongNarrowLineFile = loadProjectFromData(longNarrow);

  let handleConfirmLoad = value => {
    setPopupOpen(value);
  };

  let loadProject = data => {
    props.projectActions.loadProject(data || selectedProject);
    document.getElementsByClassName('popup-content')[0].style.display = 'none';
  };

  let result = <div></div>;
  //Drawer Custom Floor
  if (dataSource === -1) {
    result = (
      <section>
        <div style={{ padding: '12px 20px', fontSize: 11 }}>
          <p style={{ color: '#e2e2e2' }}>
            Using your mouse and the{' '}
            <span style={{ color: 'white' }}>
              <strong>Object Properties</strong>
            </span>{' '}
            Window you can draw your custom space to meet your exact project
            requirements.
          </p>
          <p style={{ color: '#e2e2e2' }}>
            To get professional help, click on the{' '}
            <span style={{ color: 'white' }}>
              <strong>Designer Assistance</strong>
            </span>{' '}
            button
          </p>
        </div>
        <MS.Control
          onClick={() => {
            if (document.getElementsByClassName('popup-content').length > 0)
              document.getElementsByClassName(
                'popup-content'
              )[0].style.display = 'none';
            drawAction();
          }}
        >
          <MS.ControlIcon>
            <img
              width={'160px'}
              src={'/assets/img/svg/toolbar/get_started_button.svg'}
            />
            <S.ButtonTitle style={{ fontSize: 13 }}>
              {' '}
              Get Started{' '}
            </S.ButtonTitle>
          </MS.ControlIcon>
        </MS.Control>
      </section>
    );
  }
  // Predefined Room Layout
  else if (dataSource === -2) {
    result = (
      <section>
        <ConfirmPopup
          visible={isPopupOpen}
          closeAction={closeAction && closeAction}
          handleConfirmLoad={handleConfirmLoad}
          loadProject={loadProject}
        />
        <S.ProductsRow
          style={{ width: '100%', justifyContent: 'space-between' }}
        >
          <section
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              borderBottom: '2px solid #2f2f2fb3',
              width: '100%',
              backgroundColor: '#3e3e3f'
            }}
          >
            <MS.LineWrapper
              onClick={loadProjectFromRetangleLineFile}
              style={{ padding: '12px 11px 8px' }}
            >
              <div style={{ width: '50px', display: 'grid' }}>
                <img
                  width={40}
                  src={'/assets/img/svg/toolbar/predefined_room_rectangle.svg'}
                />
                <span style={{ marginTop: '7px', marginBottom: '9px' }}>
                  Square
                </span>
              </div>
            </MS.LineWrapper>
            <MS.LineWrapper
              onClick={loadProjectFromlShapedLineFile}
              style={{ padding: '12px 11px 8px' }}
            >
              <div style={{ width: '50px', display: 'grid' }}>
                <img
                  width={40}
                  src={'/assets/img/svg/toolbar/predefined_room_l_shaped.svg'}
                />
                <span style={{ marginTop: '7px', marginBottom: '9px' }}>
                  L Shaped
                </span>
              </div>
            </MS.LineWrapper>
          </section>
          <section
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              borderBottom: '2px solid #2f2f2fb3',
              width: '100%',
              backgroundColor: '#3e3e3f'
            }}
          >
            <MS.LineWrapper
              onClick={loadProjectFromoRectangleLineFile}
              style={{ padding: '12px 11px 8px' }}
            >
              <div style={{ width: '50px', display: 'grid' }}>
                <img
                  width={40}
                  src={
                    '/assets/img/svg/toolbar/predefined_room_open_rectangle_2.svg'
                  }
                />
                <span style={{ marginTop: '7px', marginBottom: '9px' }}>
                  Open Square
                </span>
              </div>
            </MS.LineWrapper>
            <MS.LineWrapper
              onClick={loadProjectFromlongNarrowLineFile}
              style={{ padding: '12px 11px 8px' }}
            >
              <div style={{ width: '50px', display: 'grid' }}>
                <img
                  width={40}
                  height={40}
                  src={
                    '/assets/img/svg/toolbar/predefined_room_long_narrow.svg'
                  }
                />
                <span style={{ marginTop: '7px', marginBottom: '9px' }}>
                  Long & Narrow
                </span>
              </div>
            </MS.LineWrapper>
          </section>
        </S.ProductsRow>
      </section>
    );
  }
  //Room Elements
  else {
    result = [];
    dataSource.forEach((el, key) => {
      result.push(
        <S.ItemWrapper key={el.name} style={{ justifyContent: 'center' }}>
          <FloorProduct
            url={el.info.image}
            title={el.info.title}
            onClick={() => select(el)}
          />
        </S.ItemWrapper>
      );
    });
  }

  return (
    <S.ProductsContentWrapper
      style={{ width: 250, height: isExpand ? height : nonHeight }}
    >
      {result}
    </S.ProductsContentWrapper>
  );
};
