import React from 'react';
import ReactDOM from 'react-dom';
import ContainerDimensions from 'react-container-dimensions';
import Immutable, { Map } from 'immutable';
import immutableDevtools from 'immutable-devtools';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import * as Sentry from '@sentry/react';

import browserHistory from '../../src/@history';
import * as history from 'history';
import AppContext from './AppContext';
import { HashRouter, Router, Route, Switch, Redirect } from 'react-router-dom';
import {
  render2DItem,
  render3DItem,
  render3DApplianceItem,
  render3DLightingItem
} from './catalog/utils/item-loader';
import exporter from './catalog/utils/exporter';
import axios from 'axios';
import MyCatalog from './catalog/mycatalog';
import Login from './../../src/components/login/Login';
import Register from './../../src/components/login/Register';
import {
  MODE,
  API_SERVER_URL,
  NO_DATA_DATABASE,
  ERROR_DATABASE,
  HAS_LOADINGBAR
} from './../../src/constants';
// import { Progress } from 'antd';
import { Line, Circle } from 'rc-progress';
import ToolbarScreenshotButton from './ui/toolbar-screenshot-button';
import MobileDetect from 'mobile-detect';
import * as zlib from 'browserify-zlib';
import Buffer from 'buffer';

import {
  Models as PlannerModels,
  reducer as PlannerReducer,
  KitchenConfigurator,
  Plugins as PlannerPlugins
} from 'KitchenConfigurator'; //KitchenConfigurator
import { newProject } from '../../src/actions/project-actions';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';

// Axios config
axios.defaults.baseURL = API_SERVER_URL;

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile();
if (isMobile === null) {
  document.getElementById('app').style.display = 'block';
} else alert('The Kitchen Design software is only available from Desktop use');
//define state
let AppState = Map({
  KitchenConfigurator: new PlannerModels.State()
});

console.log('Version: 324.1-20250402');
ReactGA.initialize([
  {
    trackingId: 'G-YK2JCC9F9G' // https://dev.addovisuals.com
  },
  {
    trackingId: 'G-3Y44W0RY2E' // https://demo.kc.addovisuals.com/
  },
  {
    trackingId: 'G-M2VD74KP44' // https://rtastore.diydesignspace.com/
  }
]);

hotjar.initialize('3010506', '6');

Sentry.init({
  dsn: 'https://100c3453d99fdf76521b8559fd2e849d@o4509071977938944.ingest.us.sentry.io/4509077972582400'
});

//define reducer
let reducer = (state, action) => {
  state = state || AppState;
  state = state.update('KitchenConfigurator', plannerState =>
    PlannerReducer(plannerState, action)
  );
  return state;
};

let blackList =
  isProduction === true
    ? []
    : ['UPDATE_MOUSE_COORDS', 'UPDATE_ZOOM_SCALE', 'UPDATE_2D_CAMERA'];

if (!isProduction) {
  console.info(
    'Environment is in development and these actions will be blacklisted',
    blackList
  );
  console.info('Enable Chrome custom formatter for Immutable pretty print');
  immutableDevtools(Immutable);
}

//init store
let store = createStore(
  reducer,
  null,
  !isProduction && window.devToolsExtension
    ? window.devToolsExtension({
        features: {
          pause: true, // start/pause recording of dispatched actions
          lock: true, // lock/unlock dispatching actions and side effects
          persist: true, // persist states on page reloading
          export: true, // export history of actions in a file
          import: 'custom', // import history of actions from a file
          jump: true, // jump back and forth (time travelling)
          skip: true, // skip (cancel) actions
          reorder: true, // drag and drop actions in the history list
          dispatch: true, // dispatch custom actions or action creators
          test: true // generate tests for the selected actions
        },
        actionsBlacklist: blackList,
        maxAge: 999999
      })
    : f => f
);

let plugins = [PlannerPlugins.Keyboard(), PlannerPlugins.ConsoleDebugger()];

let toolbarButtons = [ToolbarScreenshotButton];

let categoryData;
if (isMobile === null) {
  axios
    .post(`${API_SERVER_URL}/api/dealer/get_catalogId`, {
      url: window.location.hostname
    })
    .then(async res => {
      const { success, id } = res.data;
      if (success === false) {
        console.log('No Catalog');
      }
      await axios
        .post(
          `${API_SERVER_URL}/api/planner/read/planner`,
          {
            type: MODE === 'staging' ? 2 : 1
          },
          {
            responseType: 'arraybuffer'
          }
        )
        .then(async response => {
          const unzip_data = JSON.parse(
            zlib.unzipSync(new Buffer.Buffer.from(response.data)).toString()
          );
          const { data, appliances, lighting, furnishing, success } =
            unzip_data;
          if (success === false) {
            console.log(NO_DATA_DATABASE);
          }
          if (success === 'error') {
            alert(ERROR_DATABASE);
          }
          await axios
            .post(
              `${API_SERVER_URL}/api/toolbar/getCategoryData`,
              {
                type: MODE === 'staging' ? 2 : 1
              },
              {
                responseType: 'arraybuffer'
              }
            )
            .then(response => {
              categoryData = JSON.parse(
                zlib.unzipSync(new Buffer.Buffer.from(response.data)).toString()
              );
              const { catalogs, colorAlias, subgroups } = categoryData.data;
              let door_color_alias = [];
              let subgroup_ids = catalogs
                .filter(item => item.id == id)[0]
                .manufacturer_subgroup_ids.split(',');
              let door_color_alias_ids = [];
              let doorStyles = [];
              subgroups.forEach(subgroup => {
                if (subgroup_ids.some(id => id == subgroup.id.toString())) {
                  subgroup.door_color_alias_ids.split(',').forEach(item => {
                    item != '' && door_color_alias_ids.push(item);
                  });
                }
              });
              colorAlias.forEach(
                color =>
                  door_color_alias_ids.some(id => id == color.id.toString()) &&
                  door_color_alias.push(color)
              );
              categoryData.data.doorStyles.items.forEach(root => {
                root.items.forEach(category => {
                  let temp = [];
                  category.items
                    .filter(
                      item =>
                        door_color_alias.some(
                          it => it.door_color_id == item.id
                        ) && item
                    )
                    .forEach(item => {
                      let element = door_color_alias.filter(
                        it => it.door_color_id == item.id
                      )[0];
                      temp.push({
                        ...item,
                        name: element.alias_name,
                        color_family_id: element.color_family_alias,
                        install: root.name
                      });
                    });
                  category.items = temp;
                });
              });
              let molding = [];
              let cabinets = categoryData.data.cabinets;
              cabinets[cabinets.length - 1].items.forEach(index => {
                if (index.name.toLowerCase().includes('molding')) {
                  index.items.forEach(item => {
                    molding.push(item);
                  });
                }
              });
              let promises = [];
              molding.forEach(child => {
                promises.push(
                  new Promise((resolve, reject) => {
                    let url = child.shape_svg;
                    const loader = new SVGLoader();
                    let point = [];
                    loader.load(
                      url,
                      function (data) {
                        child.data = {
                          paths: data.paths,
                          svg_width: data.xml.viewBox.animVal.width,
                          svg_height: data.xml.viewBox.animVal.height
                        };
                        resolve();
                      },
                      null,
                      error => {
                        console.log(error);
                        reject(error);
                      }
                    );
                  })
                );
              });
              return Promise.all(promises);
            });

          const _products = [];
          await axios
            .post(
              `${API_SERVER_URL}/api/magento/read/price`,
              {},
              { responseType: 'arraybuffer' }
            )
            .then(response => {
              const { count: count, products: products } = JSON.parse(
                zlib.unzipSync(new Buffer.Buffer.from(response.data)).toString()
              );
              if (!products) return;
              // products.forEach(data => {
              //   _products[data.sku] = {
              //     description: data.description,
              //     discounted_price:
              //       data.discounted_price === null
              //         ? parseFloat((Math.random() * 100).toFixed(2))
              //         : parseFloat(data.discounted_price),
              //     name: data.name,
              //     qty: !parseInt(data.qty)
              //       ? Math.ceil(Math.random() * 100)
              //       : parseInt(data.qty),
              //     regular_price: !parseFloat(data.regular_price)
              //       ? parseFloat((Math.random() * 100).toFixed(2))
              //       : parseFloat(data.regular_price)
              //   };
              // });
              products.forEach(data => {
                _products[data.sku] = {
                  description: data.description,
                  discounted_price:
                    data.discounted_price === null
                      ? 0
                      : parseFloat(
                          parseFloat(data.discounted_price).toFixed(2)
                        ),
                  name: data.name,
                  qty: parseInt(data.qty),
                  regular_price: parseFloat(
                    parseFloat(data.regular_price).toFixed(2)
                  ),
                  sku_r: data.sku_r
                };
              });
            })
            .catch(err => {
              console.log('failed read prices data ', err);
            });

          // Load Outline SVG Data

          let svgLoadPromises = data.map(
            item =>
              new Promise((resolve, reject) => {
                if (item.outline) {
                  let loader = new SVGLoader();
                  loader.load(item.outline, data => {
                    resolve({
                      paths: data.paths,
                      svgWidth: parseFloat(data.xml.getAttribute('width')),
                      svgHeight: parseFloat(data.xml.getAttribute('height'))
                    });
                  });
                } else {
                  resolve();
                }
              })
          );

          let outlineSVGData = await Promise.all(svgLoadPromises);

          // End : Load Outline SVG Data

          const Item = [];

          data.forEach((obj, index) => {
            const {
              itemID,
              long_name,
              name,
              sizeinfo,
              description,
              prototype,
              base,
              structure_json,
              layoutpos,
              is_corner,
              shape_svg,
              alti,
              obj_property
            } = obj;
            Item.push(
              exporter(
                itemID,
                'cabinet',
                long_name,
                name,
                sizeinfo,
                description,
                prototype,
                base,
                shape_svg,
                render2DItem,
                render3DItem,
                structure_json,
                layoutpos,
                is_corner,
                alti,
                obj_property,
                outlineSVGData[index]
              )
            );
          });
          appliances.forEach(obj => {
            const {
              itemID,
              long_name,
              name,
              sizeinfo,
              description,
              prototype,
              base,
              structure_json,
              layoutpos,
              is_corner,
              shape_svg,
              alti,
              obj_property
            } = obj;
            Item.push(
              exporter(
                itemID,
                'appliance',
                long_name,
                name,
                sizeinfo,
                description,
                prototype,
                base,
                shape_svg,
                render2DItem,
                render3DApplianceItem,
                structure_json,
                layoutpos,
                is_corner,
                alti,
                obj_property
              )
            );
          });
          lighting.forEach(obj => {
            const {
              itemID,
              long_name,
              name,
              sizeinfo,
              description,
              prototype,
              base,
              shape_svg,
              structure_json,
              layoutpos,
              is_corner,
              alti,
              obj_property
            } = obj;
            Item.push(
              exporter(
                itemID,
                'lighting',
                long_name,
                name,
                sizeinfo,
                description,
                prototype,
                base,
                shape_svg,
                render2DItem,
                render3DLightingItem,
                structure_json,
                layoutpos,
                is_corner,
                alti,
                obj_property
              )
            );
          });
          furnishing.forEach(obj => {
            const {
              itemID,
              long_name,
              name,
              sizeinfo,
              description,
              prototype,
              base,
              shape_svg,
              structure_json,
              layoutpos,
              is_corner,
              alti,
              obj_property
            } = obj;
            Item.push(
              exporter(
                itemID,
                'furnishing',
                long_name,
                name,
                sizeinfo,
                description,
                prototype,
                base,
                shape_svg,
                render2DItem,
                render3DApplianceItem,
                structure_json,
                layoutpos,
                is_corner,
                alti,
                obj_property
              )
            );
          });
          for (let x in Item) MyCatalog.registerElement(Item[x]);

          let ErrorComponent = () => (
            <div
              style={{
                color: 'rgb(232, 59, 70)',
                fontFamily: 'Open Sans',
                fontSize: '20px',
                marginTop: '12px',
                marginLeft: '12px'
              }}
            >
              Unexpected error happens. <br />
              Please contact the support with the project URL.
            </div>
          );

          setTimeout(() => {
            ReactDOM.render(
              <ErrorComponent />,
              document.getElementById('error')
            );
            ReactDOM.render(
              <AppContext.Provider
              // value={{
              //     routes
              // }}
              >
                <Provider store={store}>
                  <ContainerDimensions>
                    {({ width, height }) => (
                      <HashRouter history={history.createHashHistory()}>
                        <Router history={browserHistory}>
                          <Switch>
                            <Route
                              exact
                              path="/"
                              name="kc"
                              render={props => (
                                <KitchenConfigurator
                                  catalog={MyCatalog}
                                  {...props}
                                  width={width}
                                  height={height}
                                  plugins={plugins}
                                  toolbarButtons={toolbarButtons}
                                  stateExtractor={state =>
                                    state.get('KitchenConfigurator')
                                  }
                                  categoryData={categoryData}
                                  data={data}
                                  products={_products}
                                />
                              )}
                            />
                            <Route path="/login" render={props => <Login />} />
                            <Route
                              path="/register"
                              render={props => <Register />}
                            />
                            <Route
                              path="/project/:role/:token/:pid"
                              render={props => (
                                <KitchenConfigurator
                                  catalog={MyCatalog}
                                  {...props}
                                  width={width}
                                  height={height}
                                  plugins={plugins}
                                  toolbarButtons={toolbarButtons}
                                  stateExtractor={state =>
                                    state.get('KitchenConfigurator')
                                  }
                                  categoryData={categoryData}
                                  data={data}
                                  products={_products}
                                />
                              )}
                            />
                          </Switch>
                        </Router>
                      </HashRouter>
                    )}
                  </ContainerDimensions>
                </Provider>
              </AppContext.Provider>,
              document.getElementById('app')
            );
          }, 100);
        })
        .catch(err => {
          alert(
            'Something wrong happened. Do you want to clear the cache and restart the app?'
          );
          console.log(
            'Failed to load Category Data in demo/src/renderer.jsx',
            err
          );
          sessionStorage.clear();
          store.dispatch(newProject());

          ReactDOM.render(
            <Provider store={store}>
              <ContainerDimensions>
                {({ width, height }) => (
                  <KitchenConfigurator
                    catalog={MyCatalog}
                    // cabinetCategory={MyCabinetCategory}

                    width={width}
                    height={height}
                    plugins={plugins}
                    toolbarButtons={toolbarButtons}
                    stateExtractor={state => state.get('KitchenConfigurator')}
                    categoryData={categoryData}
                  />
                )}
              </ContainerDimensions>
            </Provider>,
            document.getElementById('app')
          );
          return;
        });
    });
}
