import { Catalog } from 'KitchenConfigurator';
let catalog = new Catalog();
import * as Areas from './areas/**/planner-element.jsx';
import * as Lines from './lines/**/planner-element.jsx';
import * as Holes from './holes/**/planner-element.jsx';
// import * as Items from './Items/**/*.jsx';
for (let x in Areas) catalog.registerElement(Areas[x]);
for (let x in Lines) catalog.registerElement(Lines[x]);
for (let x in Holes) catalog.registerElement(Holes[x]);
// for (let x in Items) catalog.registerElement(Items[x]);
catalog.registerCategory('Windows', 'Windows', [
  Holes.windowClear,
  Holes.windowCross,
  Holes.windowDoubleHung,
  Holes.windowVertical
]);
catalog.registerCategory('Doors', 'Doors', [
  Holes.doorInterior,
  Holes.doorExterior,
  Holes.doorCloset,
  Holes.doorSliding,
  Holes.doorwayFramed,
  Holes.doorwayFrameless
]);
export default catalog;
