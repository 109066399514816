import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CatalogItem from './catalog-item';
import CatalogBreadcrumb from './catalog-breadcrumb';
import CatalogPageItem from './catalog-page-item';
import CatalogTurnBackPageItem from './catalog-turn-back-page-item';
import ContentContainer from '../style/content-container';
import { API_SERVER_URL } from '../../constants';
import ContentTitle from '../style/content-title';
import * as SharedStyle from '../../shared-style';
import axios from 'axios';
import SnackBar from '../atoms/Snackbar/index';

const containerStyle = {
  position: 'fixed',
  width: 'calc( 100% - 94px)',
  height: 'calc( 100% - 71px)',
  backgroundColor: '#FFF',
  padding: '1em',
  left: 90,
  top: 70,
  overflowY: 'auto',
  overflowX: 'hidden',
  zIndex: 10
};

const itemsStyle = {
  display: 'flex',
  gridTemplateColumns: 'repeat(auto-fit, minmax(14em, 1fr))',
  gridGap: '10px',
  marginTop: '1em'
};

const searchContainer = {
  width: '100%',
  height: '3em',
  padding: '0.625em',
  background: '#f7f7f9',
  border: '1px solid #e1e1e8',
  cursor: 'pointer',
  position: 'relative',
  boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.11), 0 1px 4px 0 rgba(0, 0, 0, 0.11)',
  borderRadius: '2px',
  transition: 'all .2s ease-in-out',
  WebkitTransition: 'all .2s ease-in-out',
  marginBottom: '1em'
};

const searchText = {
  width: '8em',
  display: 'inline-block'
};

const searchInput = {
  width: 'calc( 100% - 10em )',
  height: '2em',
  margin: '0',
  padding: '0 1em',
  border: '1px solid #EEE'
};

const historyContainer = {
  ...searchContainer,
  padding: '0.2em 0.625em'
};

const historyElementStyle = {
  width: 'auto',
  height: '2em',
  lineHeight: '2em',
  textAlign: 'center',
  borderRadius: '1em',
  display: 'inline-block',
  cursor: 'pointer',
  backgroundColor: SharedStyle.PRIMARY_COLOR.alt,
  color: SharedStyle.PRIMARY_COLOR.text_main,
  textTransform: 'capitalize',
  margin: '0.25em',
  padding: '0 1em'
};

export default class CatalogList extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      elementsToDisplay: [],
      matchString: '',
      matchedElements: [],
      snackBarMessage: '',
      isSnackBarOpen: false
    };
  }

  componentDidMount() {
    const self = this;
    const user = self.props.param.params;
    axios
      .post(`${API_SERVER_URL}/api/project/loadProject`, {
        userrole: user.role,
        access_token: user.token,
        env: 'front'
      })
      .then(response => {
        const { success } = response.data;
        if (success === true) {
          const { projectElement } = response.data;
          self.setState({ elementsToDisplay: projectElement });
        } else
          this.setState({
            snackBarMessage: 'Loading project failed.',
            isSnackBarOpen: true
          });
      });
  }

  render() {
    let turnBackButton = <CatalogTurnBackPageItem key="back" />;
    return (
      <ContentContainer
        width={this.props.width}
        height={this.props.height}
        style={{ ...containerStyle, ...this.props.style }}
      >
        <SnackBar
          isOpen={this.state.isSnackBarOpen}
          message={this.state.snackBarMessage}
          handleClose={() =>
            this.setState({ isSnackBarOpen: false, snackBarMessage: '' })
          }
        />
        <ContentTitle>My Project Library</ContentTitle>
        <div style={itemsStyle}>
          {this.state.matchString === ''
            ? [
                turnBackButton,
                // categoriesToDisplay.map(cat => <CatalogPageItem key={cat.name} page={cat} oldPage={currentCategory}/>),
                this.state.elementsToDisplay.map(elem => (
                  <CatalogItem
                    key={elem.id}
                    element={elem}
                    categoryData={this.props.categoryData}
                  />
                ))
              ]
            : this.state.matchedElements.map(elem => (
                <CatalogItem
                  key={elem.id}
                  element={elem}
                  categoryData={this.props.categoryData}
                />
              ))}
        </div>
      </ContentContainer>
    );
  }
}

CatalogList.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  style: PropTypes.object
};

CatalogList.contextTypes = {
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired
};
