import {
  BG_COLOR_0,
  BG_COLOR_1,
  BG_COLOR_HOVER,
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR
} from '../../../constants';
import styled from 'styled-components';

export const MenubarWrapper = styled.div`
  background-color: white;
  z-index: 200;
  height: 100%;
  position: relative;
`;

export const LogoBox = styled.div`
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

export const InputStyle = styled.div`
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  transition: padding-left 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-radius: 4px;
`;
export const SelectedBox = styled.button`
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px 10px;
  outline: 0;
  background-color: ${BG_COLOR_1};
  border: none;
  margin: 5px 0px;
  :hover {
    background-color: ${BG_COLOR_0};
  }
  :active {
    background-color: ${BG_COLOR_0};
  }
`;

export const SelectTitle = styled.span`
  color: ${SECONDARY_PURPLE_COLOR};
  padding-left: 10px;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
`;
