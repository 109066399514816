import styled from 'styled-components';

export const Control = styled.div`
  display: flex;
`;
export const Content = styled.div`
  color: #fff;
  height: 495px;
  overflow-y: auto;
`;
export const ControlIcon = styled.div`
  padding: 5px 20px;
  cursor: pointer;
  position: relative;
  :hover {
    img {
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.39),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    }
  }
  :active {
    img {
      box-shadow: none !important;
    }
  }
`;
