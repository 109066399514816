import React from 'react';

import * as S from '../../styles';
import DoorStyleProduct from '../../product/doorstyleproduct';

export const StyleChooseProduct = ({
  dataSource,
  isExpand,
  currentTexture,
  chooseAction,
  selected
}) => {
  let miHeight = 0;
  let nonHeight = 495 - miHeight + 'px';
  // let height = (window.innerHeight * 0.6 - miHeight) + "px";

  let products = [];
  dataSource.forEach(el => {
    products.push(
      <DoorStyleProduct
        key={el.name}
        doorId={el.id}
        isExpand={isExpand}
        url={el.thumbnail}
        name={el.name}
        selected={selected}
        currentTexture={currentTexture}
        onClick={() => {
          chooseAction(el);
        }}
      />
    );
  });

  return (
    <S.ProductsContentWrapper
      style={{
        padding: 18,
        width: isExpand === 1 ? 800 : 300,
        height: nonHeight
      }}
    >
      <S.ItemWrapper
        style={{
          flexWrap: 'wrap',
          border: 'none',
          justifyContent: 'space-between'
        }}
      >
        {products.map((product, key) => {
          return (
            <section
              key={key}
              style={{ width: isExpand === 1 ? '30%' : '45%' }}
            >
              {product}
            </section>
          );
        })}
      </S.ItemWrapper>
    </S.ProductsContentWrapper>
  );
};
