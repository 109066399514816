import * as Three from 'three';
import { OBJTYPE_MESH } from '../../../../src/constants';

export function controlGeom() {
  const vertices = new Float32Array([
    0, 0.02, 0.55, -0.08, 0, 0.5, -0.05, 0, 0.5, -0.05, 0, -0.5, -0.08, 0, -0.5,
    0, 0.02, -0.55, 0.08, 0, -0.5, 0.05, 0, -0.5, 0.05, 0, 0.5, 0.08, 0, 0.5, 0,
    0.02, 0.55, -0.08, 0.04, 0.5, -0.05, 0.04, 0.5, -0.05, 0.04, -0.5, -0.08,
    0.04, -0.5, 0, 0.02, -0.55, 0.08, 0.04, -0.5, 0.05, 0.04, -0.5, 0.05, 0.04,
    0.5, 0.08, 0.04, 0.5, 0, 0.02, 0.55
  ]);

  const indices = new Uint16Array([
    0, 1, 9, 4, 5, 6, 2, 3, 8, 3, 7, 8, 10, 11, 19, 14, 15, 16, 12, 13, 18, 13,
    17, 18, 10, 1, 11, 10, 9, 19, 1, 2, 11, 2, 12, 11, 8, 9, 18, 9, 18, 19, 12,
    2, 3, 12, 3, 13, 17, 7, 8, 17, 8, 18, 5, 4, 14, 15, 6, 16
  ]);

  const geometry = new Three.BufferGeometry();
  geometry.setAttribute('position', new Three.BufferAttribute(vertices, 3));
  geometry.setIndex(new Three.BufferAttribute(indices, 1));

  return geometry;
}
export function upwardsGeom() {
  const vertices = new Float32Array([
    0.05, 0, 0.05, 0.05, 0.3, 0.05, 0.07, 0.3, 0.05, 0, 0.4, 0.05, -0.07, 0.3,
    0.05, -0.05, 0.3, 0.05, -0.05, 0, 0.05, 0.05, 0, 0.05, 0.05, 0, -0.05, 0.05,
    0.3, -0.05, 0.07, 0.3, -0.05, 0, 0.4, -0.05, -0.07, 0.3, -0.05, -0.05, 0.3,
    -0.05, -0.05, 0, -0.05, 0.05, 0, -0.05
  ]);

  const indices = new Uint16Array([
    0, 1, 5, 0, 5, 6, 2, 3, 4, 0, 1, 8, 1, 8, 9, 1, 2, 9, 2, 9, 10, 2, 3, 10, 3,
    10, 11, 3, 4, 11, 4, 11, 12, 4, 5, 12, 4, 12, 13, 5, 6, 13, 6, 13, 14, 6, 7,
    14, 7, 14, 15, 8, 9, 13, 8, 13, 14, 10, 11, 12
  ]);

  const geometry = new Three.BufferGeometry();
  geometry.setAttribute('position', new Three.BufferAttribute(vertices, 3));
  geometry.setIndex(new Three.BufferAttribute(indices, 1));

  return geometry;
}

export function createRotShape(radius) {
  let rotShape = new Three.Shape();

  rotShape.moveTo(
    Math.cos((-60 / 180) * Math.PI) * (radius - 0.05),
    Math.sin((-60 / 180) * Math.PI) * (radius - 0.05)
  );
  for (let i = -55; i <= 60; i += 5) {
    rotShape.lineTo(
      Math.cos((i / 180) * Math.PI) * (radius - 0.05),
      Math.sin((i / 180) * Math.PI) * (radius - 0.05)
    );
  }
  for (let i = 60; i >= -60; i -= 5) {
    if (i === 5) {
      rotShape.lineTo(
        Math.cos((i / 180) * Math.PI) * (radius + 0.05),
        Math.sin((i / 180) * Math.PI) * (radius + 0.05)
      );
      rotShape.lineTo(
        Math.cos((i / 180) * Math.PI) * (radius + 0.05) + 0.15,
        Math.sin((i / 180) * Math.PI) * (radius + 0.05)
      );
      rotShape.lineTo(
        Math.cos((i / 180) * Math.PI) * (radius + 0.05) + 0.15,
        Math.sin((i / 180) * Math.PI) * (radius + 0.05) + 0.1
      );
      continue;
    } else if (i === 0) {
      rotShape.lineTo(
        Math.cos((i / 180) * Math.PI) * (radius + 0.05) + 0.25,
        Math.sin((i / 180) * Math.PI) * (radius + 0.05)
      );
      continue;
    } else if (i === -5) {
      rotShape.lineTo(
        Math.cos((i / 180) * Math.PI) * (radius + 0.05) + 0.15,
        Math.sin((i / 180) * Math.PI) * (radius + 0.05) - 0.1
      );
      rotShape.lineTo(
        Math.cos((i / 180) * Math.PI) * (radius + 0.05) + 0.15,
        Math.sin((i / 180) * Math.PI) * (radius + 0.05)
      );
      rotShape.lineTo(
        Math.cos((i / 180) * Math.PI) * (radius + 0.05),
        Math.sin((i / 180) * Math.PI) * (radius + 0.05)
      );
      continue;
    }
    rotShape.lineTo(
      Math.cos((i / 180) * Math.PI) * (radius + 0.05),
      Math.sin((i / 180) * Math.PI) * (radius + 0.05)
    );
  }
  rotShape.lineTo(
    Math.cos((-60 / 180) * Math.PI) * (radius + 0.1),
    Math.sin((-60 / 180) * Math.PI) * (radius + 0.1)
  );
  rotShape.lineTo(
    Math.cos((-70 / 180) * Math.PI) * (radius + 0.0),
    Math.sin((-70 / 180) * Math.PI) * (radius + 0.0)
  );
  rotShape.lineTo(
    Math.cos((-60 / 180) * Math.PI) * (radius - 0.1),
    Math.sin((-60 / 180) * Math.PI) * (radius - 0.1)
  );
  rotShape.lineTo(
    Math.cos((-60 / 180) * Math.PI) * (radius - 0.05),
    Math.sin((-60 / 180) * Math.PI) * (radius - 0.05)
  );

  return rotShape;
}

export function rotGeoms(radius) {
  if (!Number.isFinite(radius)) radius = 1;
  let rotShape = createRotShape(radius);
  let rotFill = new Three.ShapeGeometry(rotShape);
  let rotStroke = new Three.BufferGeometry().setFromPoints(
    rotShape.getPoints()
  );
  rotFill.rotateX(Math.PI / 2);
  rotStroke.rotateX(Math.PI / 2);
  return { rotFill, rotStroke };
}

export function addShape(
  shape,
  extrudeSettings,
  color,
  x,
  y,
  z,
  rx,
  ry,
  rz,
  s
) {
  // flat shape
  let geometry = (geometry = new Three.ExtrudeGeometry(shape, extrudeSettings));
  let mesh = new Three.Mesh(
    geometry,
    new Three.MeshPhongMaterial({ color: color, side: Three.DoubleSide })
  );
  mesh.position.set(x, y, z);
  mesh.rotation.set(rx, ry, rz);
  mesh.scale.set(s, s, s);
  return mesh;
}

export function makeMBoxfromObject(props_Object, mBoxColor = 0x99c3fb) {
  let object;
  if (props_Object.children[0].type !== 'Group') {
    object = props_Object;
  } else {
    object = props_Object.children[0];
  }
  // object.children = object.children.filter(
  //   objectElement => objectElement.name !== 'TransformBox'
  // );
  // let tempobj = [];
  // for (let j = object.children.length - 1; j >= 0; j--) {
  //   if (object.children[j].name === 'TransformGizmo') {
  //     tempobj.push(object.children[j]);
  //     object.remove(object.children[j]);
  //   }
  // }
  let scalevec = new Three.Vector3(
    object.scale.x,
    object.scale.y,
    object.scale.z
  );
  let posVec = new Three.Vector3(
    object.position.x,
    object.position.y,
    object.position.z
  );
  let uVec = new Three.Vector3(
    -posVec.x / scalevec.x,
    -posVec.y / scalevec.y,
    -posVec.z / scalevec.z
  );
  // // tempobj.forEach(v => {
  // //   object.add(v);
  // // });
  let max = object.userData.max;
  let min = object.userData.min;
  let moveBox = new Three.BoxGeometry(
    max.x - min.x,
    max.y - min.y,
    max.z - min.z
  );
  let mBox = new Three.Mesh(
    moveBox,
    new Three.MeshBasicMaterial({
      color: mBoxColor,
      side: Three.DoubleSide,
      transparent: true,
      opacity: 0.4
    })
  );
  mBox.name = 'TransformBox';
  mBox.translateOnAxis(
    new Three.Vector3(uVec.x, uVec.y + (max.y - min.y) / 2, uVec.z),
    1
  );
  mBox.scale.set(1.1, 1.1, 1.1);
  mBox.translateY(posVec.y);
  mBox.renderOrder = 1;
  if (mBoxColor === 0x99c3fb) {
    mBox.visible = false;
  } else {
    mBox.visible = true;
  }
  return mBox;
}

// export function rotMesh(radius) {
//     let rotShape = rotShape(radius);

//     let rotGeom = new Three.ShapeBufferGeometry(rotShape);

//     const points = rotShape.getPoints();
//     const spacedPoints = shape.getSpacedPoints( 50 );

//     const geometryPoints = new THREE.BufferGeometry().setFromPoints( points );
//     const geometrySpacedPoints = new THREE.BufferGeometry().setFromPoints( spacedPoints );

//     // solid line

//     let line = new THREE.Line( geometryPoints, new THREE.LineBasicMaterial( { color: color } ) );
//     line.position.set( x, y, z - 25 );
//     line.rotation.set( rx, ry, rz );
//     line.scale.set( s, s, s );
//     group.add( line );

// }

export function baseBoxHelper(object, color) {
  let tempobj = [];
  for (let j = object.children.length - 1; j >= 0; j--) {
    if (object.children[j].type !== OBJTYPE_MESH) {
      tempobj.push(object.children[j]);
      object.remove(object.children[j]);
    }
  }
  let boxHelper = new Three.BoxHelper(object, color);
  tempobj.forEach(v => {
    object.add(v);
  });
  return boxHelper;
}

export function baseBox3FromObject(object) {
  let tempobj = [];
  for (let j = object.children.length - 1; j >= 0; j--) {
    if (
      object.children[j].type !== OBJTYPE_MESH ||
      object.children[j].name.includes('_countertop')
    ) {
      tempobj.push(object.children[j]);
      object.remove(object.children[j]);
    }
  }
  let box3 = new Three.Box3().setFromObject(object);
  tempobj.forEach(v => {
    object.add(v);
  });
  return box3;
}

export function emptyBoxHolder(newWidth, newHeight, newDepth) {
  const scale = 2.54 / 100;
  const geometry = new Three.BoxGeometry(
    newWidth * scale,
    newHeight * scale,
    newDepth * scale
  );
  const material = new Three.MeshBasicMaterial({
    color: 0x00ff00,
    opacity: 0.2,
    transparent: true
  });
  const cube = new Three.Mesh(geometry, material);
  return cube;
}
