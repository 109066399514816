import {
  UPDATE_CEIL_HEIGHT_UNIT,
  UPDATE_CEIL_HEIGHT,
  UPDATE_2D_CAMERA,
  SELECT_TOOL_PAN,
  SELECT_TOOL_ZOOM_IN,
  SELECT_TOOL_ZOOM_OUT,
  CHANGE_WALL_LENGTH_MEASURE,
  CHANGE_BASE_CABINET_MEASURE,
  CHANGE_WALL_CABINET_MEASURE,
  CHANGE_WINDOW_DOOR_MEASURE
} from '../constants';

export function updateCeilHeight(value) {
  return {
    type: UPDATE_CEIL_HEIGHT,
    value
  };
}

export function updateCeilHeightUnit(value) {
  return {
    type: UPDATE_CEIL_HEIGHT_UNIT,
    value
  };
}

export function updateCameraView(value) {
  return {
    type: UPDATE_2D_CAMERA,
    value
  };
}

export function changeWallLengthMeasure(value) {
  return {
    type: CHANGE_WALL_LENGTH_MEASURE,
    value
  };
}

export function changeBaseCabinetMeasure(value) {
  return {
    type: CHANGE_BASE_CABINET_MEASURE,
    value
  };
}

export function changeWallCabinetMeasure(value) {
  return {
    type: CHANGE_WALL_CABINET_MEASURE,
    value
  };
}

export function changeWindowDoorMeasure(value) {
  return {
    type: CHANGE_WINDOW_DOOR_MEASURE,
    value
  };
}

export function selectToolPan() {
  return {
    type: SELECT_TOOL_PAN
  };
}

export function selectToolZoomOut() {
  return {
    type: SELECT_TOOL_ZOOM_OUT
  };
}

export function selectToolZoomIn() {
  return {
    type: SELECT_TOOL_ZOOM_IN
  };
}
