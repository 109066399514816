import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import {
  DEFAULT_FONT_FAMILY,
  TEXT_COLOR_NEUTRAL_1,
  SECONDARY_PURPLE_COLOR,
  BG_COLOR_HOVER
} from '../../../constants';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    border-radius: 10px;
  }
`;

const StyledDialogContent = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 25px 30px;
  padding-bottom: 10px;
`;

const StyledDialogAction = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 10px 30px 25px;
`;

const StyledButton = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  color: ${SECONDARY_PURPLE_COLOR};
  font-weight: bold;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  border-radius: 20px;
  :hover {
    background-color: ${BG_COLOR_HOVER};
  }
`;

class ConfirmPopup extends React.Component {
  constructor(props) {
    super(props);
  }

  closePanel() {
    this.props.handleConfirmLoad();
  }
  render() {
    let { visible } = this.props;
    return (
      <StyledDialog open={visible} onClose={() => this.closePanel()}>
        <StyledDialogContent>
          All items will be removed from the canvas.
          <br />
          Are you sure?
        </StyledDialogContent>
        <StyledDialogAction>
          <StyledButton onClick={() => this.props.submit()}>OK</StyledButton>
          <StyledButton onClick={() => this.closePanel()}>Cancel</StyledButton>
        </StyledDialogAction>
      </StyledDialog>
      // <div style={{ width: '100%', height: '100%', display: visible ? 'block' : 'none', userSelect: 'none' }}>
      //   <S.Div>
      //     <div style={{ backgroundColor: '#1183B7', height: '30px', paddingRight: '6px' }}>
      //       <div style={{ float: 'right', marginTop: '4px' }} onClick={() => this.closePanel()}>
      //         <S.IconButton><FaTimes /></S.IconButton>
      //       </div>
      //     </div>
      //     <div style={{ fontSize: 14, marginTop: 20, marginLeft: 40 }}>
      //       All items will be removed from the canvas.<br/>
      //       Are you sure?
      //     </div>
      //     <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', position: 'relative' }}>
      //     </div>
      //     <div style={{ display: 'flex', position: 'relative'}}>
      //       <div style={{ width: '70px', marginLeft: '90px', marginTop: '15px', cursor: 'pointer' }} onClick={() => { this.props.submit()}}>
      //         <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/use_button.svg'} />
      //         <S.ButtonTitle1>
      //           {'Yes'}
      //         </S.ButtonTitle1>
      //       </div>
      //       <div style={{ width: '70px', marginLeft: '40px', marginTop: '15px', cursor: 'pointer' }} onClick={() => this.closePanel()}>
      //         <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/cancel_button.svg'} />
      //         <S.ButtonTitle2>
      //           {'No'}
      //         </S.ButtonTitle2>
      //       </div>
      //     </div>
      //   </S.Div>
      // </div>
    );
  }
}

export default ConfirmPopup;
