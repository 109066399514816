import * as Three from 'three';
import { HELVETIKER } from './libs/helvetiker_regular.typeface.js';
import gridStreak from './grids/grid-streak';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
export default function createGrid(scene) {
  let gridMesh = new Three.Object3D();

  gridMesh.name = 'grid';
  let fontLoader = new FontLoader();
  let font = fontLoader.parse(HELVETIKER); // For measures
  let { grids, width, height } = scene;

  grids.forEach(grid => {
    switch (grid.type) {
      case 'horizontal-streak':
        gridMesh.add(gridStreak(width, height, grid, font));
        break;
      default:
        break;
    }
  });

  // gridMesh.position.y = -1;
  return gridMesh;
}
