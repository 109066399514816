export const searchForSkuValue = (catalog, selectedLayer, elementType) => {
  return new Promise(resolve => {
    if (elementType) {
      Object.values(catalog.elements).forEach(value => {
        if (value.obj && value.obj.skuArray) {
          if (value.obj.skuArray.find(skItem => skItem.sku === elementType)) {
            saveDeepSearchType(elementType, value.name);
            resolve({
              isAvailable: true,
              rootType: value.name
            });
          }
        }
      });
    } else {
      selectedLayer.items.valueSeq().forEach(item => {
        Object.values(catalog.elements).forEach(value => {
          if (value.obj && value.obj.skuArray) {
            if (value.obj.skuArray.find(skItem => skItem.sku === item.type)) {
              resolve({
                isAvailable: true,
                rootType: value.name
              });
            }
          }
        });
      });
    }

    resolve({
      isAvailable: false
    });
  });
};

export const returnReplaceableDeepSearchType = type => {
  const DeepSearchTypes =
    JSON.parse(sessionStorage.getItem('deepSearchTypes')) || [];
  const replaceableType = DeepSearchTypes.find(item => item.type === type);
  return (replaceableType && replaceableType.foundType) || '';
};

const saveDeepSearchType = (type, foundType) => {
  const DeepSearchTypes =
    JSON.parse(sessionStorage.getItem('deepSearchTypes')) || [];
  const isAlreadyAvailable = DeepSearchTypes.find(item => item.type === type);
  if (!isAlreadyAvailable) {
    DeepSearchTypes.push({ type, foundType });
  }

  sessionStorage.setItem('deepSearchTypes', JSON.stringify(DeepSearchTypes));
};
