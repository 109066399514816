import {
  UPDATE_3D_CEIL_HEIGHT_UNIT,
  UPDATE_3D_CEIL_HEIGHT,
  SELECT_TOOL_3D_VIEW,
  SELECT_TOOL_3D_FIRST_PERSON
} from '../constants';

export function selectTool3DView() {
  return {
    type: SELECT_TOOL_3D_VIEW
  };
}

export function selectTool3DFirstPerson() {
  return {
    type: SELECT_TOOL_3D_FIRST_PERSON
  };
}

export function update3DCeilHeight(value) {
  return {
    type: UPDATE_3D_CEIL_HEIGHT,
    value
  };
}

export function update3DCeilHeightUnit(value) {
  return {
    type: UPDATE_3D_CEIL_HEIGHT_UNIT,
    value
  };
}
