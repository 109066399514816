import {
  MODE_3D_VIEW,
  MODE_3D_FIRST_PERSON,
  SELECT_TOOL_3D_VIEW,
  SELECT_TOOL_3D_FIRST_PERSON,
  UPDATE_3D_CEIL_HEIGHT,
  UPDATE_3D_CEIL_HEIGHT_UNIT
} from '../constants';
import { Project } from '../class/export';
import { history } from '../utils/export';
import convert from 'convert-units';

export default function (state, action) {
  state = state.merge({
    sceneHistory: history.historyPush(state.sceneHistory, state.scene)
  });
  switch (action.type) {
    case UPDATE_3D_CEIL_HEIGHT_UNIT: {
      let { scene } = state;
      let selectedLayer = scene.selectedLayer;
      let layer = scene.layers.get(selectedLayer);
      let newLayer = layer.merge({ unit: action.value });
      state = state.mergeIn(['scene', 'layers', selectedLayer], newLayer);
      return state;
    }
    case UPDATE_3D_CEIL_HEIGHT: {
      let { scene } = state;
      let selectedLayer = scene.selectedLayer;
      let layer = scene.layers.get(selectedLayer);
      let newLayer = layer.merge({ ceilHeight: action.value });
      state = state.mergeIn(['scene', 'layers', selectedLayer], newLayer);
      let items = state.scene.layers.get(state.scene.selectedLayer).items;
      let newItems = items.map(item => {
        if (item.type.includes('Light')) {
          let height = item.properties.getIn(['height', '_length']);
          let heightUnit = item.properties.getIn(['height', '_unit']);
          let newAltitude =
            action.value - convert(height).from(heightUnit).to('in');
          item = item.setIn(
            ['properties', 'altitude', '_length'],
            convert(newAltitude).from('in').to(layer.unit)
          );
        }
        return item;
      });
      state = state.mergeIn(
        ['scene', 'layers', selectedLayer, 'items'],
        newItems
      );
      return state;
    }
    case SELECT_TOOL_3D_VIEW:
      state = Project.rollback(state).updatedState;
      state = Project.setMode(state, MODE_3D_VIEW).updatedState;
      return state;

    case SELECT_TOOL_3D_FIRST_PERSON:
      state = Project.rollback(state).updatedState;
      state = Project.setMode(state, MODE_3D_FIRST_PERSON).updatedState;
      return state;

    default:
      return state;
  }
}
