import React from 'react';

import { StyleCategory } from './style-category';
import { StyleChooseProduct } from './choose-style';

import { DoubleToolbarButtonWithPopup } from '../';

const CabinetDoorStyleToolbarButton = ({
  action,
  chooseAction,
  dataSource,
  stepSource,
  subData,
  isOpen,
  currentTexture,
  recentStyle,
  selectFromRecent,
  step,
  trigger,
  categoryCollapse,
  productCollapse,
  collapseAction,
  isExpand,
  expandAction,
  mode,
  closeAction,
  selected
}) => {
  let content = (
    <StyleCategory
      recentStyle={recentStyle}
      selectFromRecent={selectFromRecent}
      step={step}
      action={action}
      stepSource={stepSource}
      dataSource={dataSource}
      isExpand={!productCollapse}
      currentTexture={currentTexture}
    ></StyleCategory>
  );
  let content1 = (
    <StyleChooseProduct
      chooseAction={chooseAction}
      dataSource={subData}
      isExpand={isExpand}
      selected={selected}
      currentTexture={currentTexture}
    ></StyleChooseProduct>
  );
  return (
    <DoubleToolbarButtonWithPopup
      isOpen={isOpen}
      closeAction={closeAction}
      mode={mode}
      isExpand={isExpand}
      expandAction={expandAction}
      style={{ minWidth: step >= 1 && isExpand == 1 ? '800px' : '600px' }}
      order={1}
      isDouble={subData.length}
      collapse1={categoryCollapse}
      collapse2={productCollapse}
      collapseAction={collapseAction}
      trigger={trigger}
      children1={content1}
    >
      {content}
    </DoubleToolbarButtonWithPopup>
  );
};

export default CabinetDoorStyleToolbarButton;
